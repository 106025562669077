import axios from 'axios'
import Cookies from 'js-cookie';
const rootUrl = process.env.REACT_APP_ROOT_HOST

export const subscribeApplePay = ({ paymentMethodId, planId, email, paidTrialPlanId }) => {
  return axios.post(`${rootUrl}/api/v3/payment/subscription/layout/create-via-wallet`, {
    paymentMethodId,
    planId,
    email,
    paidTrialPlanId
  }).then((response) => {
    return response;
  }).catch(error => {
    return error.response;
  });
};

export const googleAnaliticsStoreApi = (email, ga, source, campaign, medium) => {
  return axios.post(`${rootUrl}/api/v3/google-analytics-storage`, {
    email: email,
    clientId: ga,
    funnel: 'fasting',
    source,
    campaign,
    medium
  }).then((response) => {
    return response;
  }).catch(error => {
    return error.response;
  });
};

export const saveOnboardData = (
  result,
  authToken
) => {
  return axios.post(`${rootUrl}/api/v3/onboard`, {
    ...result
  }, {
    headers: {
      'Platform': 'ios',
      'Authorization': authToken
    }
  })
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const upSellPayment = ( planId ) => {
  const token = Cookies.get('token');
  return axios.post(`${rootUrl}/api/v3/payment/create-upsell`, {
    planId,
  }, {
    headers: {
      'Platform': 'ios',
      'Authorization': token
    },
  }).then((response) => {
    return response;
  }).catch(error => {
    return error.response;
  });
};

export const getDeepLink = (authToken) => {
  return axios.post(`${rootUrl}/api/v3/deep-link/layout/token`, {},
    {
      headers: {
        'Platform': 'ios',
        'Authorization': authToken
      }
    }).then((response) => {
    return response;
  }).catch(error => {
    return error.response;
  });
};