import { FakeLoader } from '@Components/Icons';

import './styles.scss';

export const FakeLoading = ({ isActiveFakeLoading = true, isSmallLoader = false }) => {
  return (
    <div className={`fake-loading ${isActiveFakeLoading && 'is-active-fake-loading'}`}>
      <FakeLoader isSmallLoader={isSmallLoader} />
    </div>
  );
};
