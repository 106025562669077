let config = {};

if ('production' === process.env.REACT_APP_ENVIRONMENT) {
  let envConfig = {
    prices: {
      master: {
        discountPrice: 'price_1LNcCpIlKxdbfNF5FBkFz5Iy',
        defaultPrice: 'price_1LNcCpIlKxdbfNF5gSoto7iv',
      },
      test: {
        discountPrice: 'price_1LNcGiIlKxdbfNF5INSl0rZW',
        defaultPrice: 'price_1LNcGiIlKxdbfNF5ry264Boo',
      },
      upSell: {
        easyHealthyMenu: 'price_1KyVRPIlKxdbfNF5QkB6rwjr',
        walkingChallenge: 'price_1KyVRPIlKxdbfNF5m06an9Yc',
        veggieSnackGuide: 'price_1KyVRPIlKxdbfNF5k0Tzr4gF',
        flatBellyRules: 'price_1KyVRPIlKxdbfNF5Fn3uWNS5'
      },
      paidTrial: {
        master: [
          'price_1LNcCpIlKxdbfNF50vyaZg3c',
          'price_1LNcCpIlKxdbfNF5nhoM5yvc',
          'price_1LNcCpIlKxdbfNF53PaPAhbY',
          'price_1LNcCpIlKxdbfNF59sPHGTOg'
        ],
        test: [
          'price_1LNcGiIlKxdbfNF5R0ye96iF',
          'price_1LNcGiIlKxdbfNF5y1Bf1U0N',
          'price_1LNcGiIlKxdbfNF5yy412OLE',
          'price_1LNcGiIlKxdbfNF5AhvuXXCs'
        ],
      }
    },
  };
  config = { ...envConfig };
}

if ('development' === process.env.REACT_APP_ENVIRONMENT) {
  let envConfig = {
    prices: {
      master: {
        discountPrice: 'price_1LNcZqIr0VH9QFtQMbUFevUa',
        defaultPrice: 'price_1LNcaBIr0VH9QFtQJHeDZTGz',
      },
      test: {
        discountPrice: 'price_1LNcLvIr0VH9QFtQhvUmngYp',
        defaultPrice: 'price_1LNcLvIr0VH9QFtQTgPs2Ccf',
      },
      upSell: {
        easyHealthyMenu: 'price_1KyVUeIr0VH9QFtQsq4VoXE7',
        walkingChallenge: 'price_1KyVUeIr0VH9QFtQWsTjNhKE',
        veggieSnackGuide: 'price_1KyVUeIr0VH9QFtQ3clQlRkX',
        flatBellyRules: 'price_1KyVUeIr0VH9QFtQ4Pm49fDv'
      },
      paidTrial: {
        master: [
          'price_1KyEVqIr0VH9QFtQ6aqsGPdK',
          'price_1KyEVqIr0VH9QFtQHGiTpeWY',
          'price_1KyEVqIr0VH9QFtQlW1zM5O8',
          'price_1KyEVqIr0VH9QFtQBLGbThyr',
        ],
        test: [
          'price_1KyEVqIr0VH9QFtQ6aqsGPdK',
          'price_1KyEVqIr0VH9QFtQHGiTpeWY',
          'price_1KyEVqIr0VH9QFtQlW1zM5O8',
          'price_1KyEVqIr0VH9QFtQBLGbThyr',
        ]
      }
    },
  };
  config = { ...envConfig };
}

config.gaid = 'UA-208289779-1';

export default config;
