import styled from '@emotion/styled';

export const NutritionBlockWrapper = styled.section`
  margin-top: 60px;
`;

export const NutritionBlockTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  max-width: 286px;
  margin: 0 auto 14px;
  text-align: center;
`;

export const NutritionBlockContent = styled.div`
  display: flex;
  align-items: center;
  max-width: 240px;
  min-width: 240px;
`;
export const NutritionBlockContentWrapper = styled.div``;

export const NutritionBlockContentIcon = styled.img`
  display: block;
  max-width: 55px;
  margin-right: 12px;
`;

export const NutritionBlockContentTitle = styled.p`
  font-size: 15px;
  line-height: 22px;
  color: #17181b;
`;

export const NutritionBlockContentSubTitle = styled.p`
  font-size: 11px;
  line-height: 14px;
  color: #878787;
`;

export const ContentWrapper = styled.div`
  @media (min-width: 428px) {
    max-width: 240px;
    margin: auto;
  }
`;
