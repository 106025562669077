import Cookies from 'js-cookie';

const queryString = window.location.search;
const url = new URLSearchParams(queryString);
const cookieName = 'utm_params';
export const storeUtmParams = () => {
  if (!queryString || Cookies.get(cookieName)) {
    return;
  }
  const source = url.get('utm_source') ?? 'null';
  const campaign = url.get('utm_campaign') ?? 'null' ;
  const adContent = url.get('utm_content') ?? 'null';
  const adTerm = url.get('utm_term') ?? 'null';
  const medium = url.get('utm_medium') ?? 'null';
  Cookies.set(cookieName, [source, campaign, adContent, adTerm, medium].join('|'));
};

const getNullExpression = (param) => {
  return 'null' === param ? null : param;
};

export const getUtmParams = () => {
  if (!Cookies.get(cookieName)) {
    return;
  }
  const [source, campaign, adContent, adTerm, medium] = Cookies.get(cookieName).split('|');
  return {
    source : getNullExpression(source),
    campaign: getNullExpression(campaign),
    adContent: getNullExpression(adContent),
    adTerm: getNullExpression(adTerm),
    medium: getNullExpression(medium)
  };
};