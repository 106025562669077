import { PreviousStepBtn } from '@Components/Icons';
import { Logo } from '@Components/Icons';
import './styles.scss';
import { NavLink, useHistory } from 'react-router-dom';

export const Header = ({ isShowPreviousBtn = true, isShowLogo = true, isShowNumberQuestion = true, currentStep, totalSteps }) => {
  const history = useHistory();

  const backToPreviousPage = () => {
    history.goBack();
  };

  const divClassName = `quiz-header ${!isShowPreviousBtn && !isShowNumberQuestion ? 'single-logo' : ''}`;

  return (
    <div className={divClassName}>
      {isShowPreviousBtn && (
        <div className="quiz-header-previous-step-btn">
          <PreviousStepBtn onClick={backToPreviousPage} />
        </div>
      )}
      <NavLink to="/" className="quiz-header-logo">
        {isShowLogo && <Logo />}
      </NavLink>
      {isShowNumberQuestion && <p className="quiz-header-number-question">{`Q ${currentStep}/${totalSteps}`}</p>}
    </div>
  );
};
