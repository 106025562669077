import { Like, Safe, Security } from '@Components/Icons';
import {
  InfoSafetyBlockStyle,
  InfoSafetyBlockWrapper,
  InfoSafetyBlockWrappeItem,
  InfoSafetyBlockWrappeItemText
} from './styled';


export const InfoSafetyBlock = ({ ...props }) => {
  return (
    <InfoSafetyBlockStyle {...props}>
      <InfoSafetyBlockWrapper>
        <InfoSafetyBlockWrappeItem>
          <Like />
        </InfoSafetyBlockWrappeItem>
        <InfoSafetyBlockWrappeItemText>No worries. Cancel anytime</InfoSafetyBlockWrappeItemText>
      </InfoSafetyBlockWrapper>
      <InfoSafetyBlockWrapper>
        <InfoSafetyBlockWrappeItem>
          <Safe />
        </InfoSafetyBlockWrappeItem>
        <InfoSafetyBlockWrappeItemText>14 day Money-back guarantee</InfoSafetyBlockWrappeItemText>
      </InfoSafetyBlockWrapper>
      <InfoSafetyBlockWrapper>
        <InfoSafetyBlockWrappeItem>
          <Security />
        </InfoSafetyBlockWrappeItem>
        <InfoSafetyBlockWrappeItemText>Secure checkout</InfoSafetyBlockWrappeItemText>
      </InfoSafetyBlockWrapper>
    </InfoSafetyBlockStyle>
  );
};
