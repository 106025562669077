export function getCardType(number) {
  // visa

  const visaRegex = new RegExp('^4');
  if (number.match(visaRegex) !== null) return 'visa';

  // AMEX
  const amexRegex = new RegExp('^3[47]');
  if (number.match(amexRegex) !== null) return 'amex';

  // Mastercard
  const mastercardRegEx = new RegExp('5[1-5]{1}');
  if (number.match(mastercardRegEx) !== null) {
    return 'mastercard';
  }
  // Discover
  const discoverRegex = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
  if (number.match(discoverRegex) !== null) return 'discover';

  return '';
}
