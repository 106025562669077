const buttonStyles = {
  maxWidth: '52px',
  backgroundColor: '#F4F4F4',
  color: '#4F4F4F',
};
export const quizContentData = [
  {
    title: 'Do you relate to the following statement?',
    subtitle: '“Eating is one of the ways I usually deal with my emotions”',
    styles: buttonStyles,
    id: 2,
    value: 1,
  },
  {
    title: 'Do you relate to the following statement?',
    subtitle: '“After a week or two of eating healthier or exercising, I fall back to my old routine.”',
    styles: buttonStyles,
    id: 3,
    value: 2,
  },
  {
    title: 'Do you relate to the following statement?',
    subtitle: '“People around me often make it hard for me to stick to healthier habits.”',
    styles: buttonStyles,
    id: 4,
    value: 3,
  },
  {
    title: 'Do you relate to the following statement?',
    subtitle: '“Multitasking while eating has become habitual for me (like eating while working or scrolling on my phone).”',
    styles: buttonStyles,
    id: 5,
    value: 4,
  },
  {
    title: 'Do you relate to the following statement?',
    subtitle: '“Even if I’m already full, I usually clear my plate.”',
    styles: buttonStyles,
    id: 6,
    value: 5,
  },
  {
    title: 'Do you relate to the following statement?',
    subtitle:
      '“My unhealthy decisions often lead me to make more unhealthy decisions. I feel like a failure because I can’t commit to a healthier lifestyle”',
    styles: buttonStyles,
    id: 7,
    value: 6,
  },
];
