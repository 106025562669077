import React, { useContext, useEffect } from 'react';
import { SubTitle } from '@QuizComponents/SubTitle';
import { Title } from '@QuizComponents/Title';
import { useDispatch, useSelector } from 'react-redux';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { Button } from '@Components/Button';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { EventDayWrapper } from './styled';
import { DatePick } from '@Components/DatePick';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';
import { getDate, formatDate } from '@Utilities/momentFunction';
import { sendAmplitudeData } from '@Analytics/amplitude';

export const EventDay = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const quiz = useSelector((store) => store.quiz);
  const eventDay = quiz?.['motivationEventDate'];
  const importantEvent = quiz?.['important-event'];
  const event = 'No similar events' === importantEvent?.radioButtonValue ?
    'By what date do you want to lose weight' :
    !importantEvent?.radioButtonText ?
      `When’s the ${importantEvent?.radioButtonValue} day?` :
    `When’s the ${importantEvent?.radioButtonText} day?`;
  const titleText = event;

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 9,
        totalSteps: 11,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step3');
    scrollPageToTop();
  }, []);

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      date: eventDay ? getDate(eventDay) : null,
    },
  });

  const onSubmit = (data) => {
    const dateString = formatDate(data.date);
    dispatch(
      updateQuiz({
        name: 'motivationEventDate',
        value: dateString,
      })
    );
    sendAmplitudeData('Step_3_9_event_date', {
      EventDate: dateString
    });
    history.push('craving_certain_foods');
  };

  const isDisabled = !(eventDay || isDirty);

  const rangeDateMin = () => {
    const currentYear = new Date();
    const targetYear = currentYear.setFullYear(currentYear.getFullYear()+50);
    return new Date(targetYear);
  }
  const rangeDateMax = () => {
    const currentYear = new Date();
    const targetYear = currentYear.setHours(currentYear.getHours()+194);
    return new Date(targetYear);
  }

  return (
    <EventDayWrapper>
      <Title title={titleText} />
      <SubTitle
        style={{ margin: '20px' }}
        text='We need to know this in order to determine the optimal weight loss action plan for you. We do not share your personal data with any third parties.'
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DatePick control={control} name='date' rangeDateMax={rangeDateMax} rangeDateMin={rangeDateMin} />
        <Button name='Continue' minWidth='100%' margin='340px 0px 40px 0px' type='submit' isDisabled={isDisabled} />
      </form>
    </EventDayWrapper>
  );
};
