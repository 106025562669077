export const radioButtonsData = [
  {
    text: '15 minutes and less',
    value: '15 minutes and less',
  },
  {
    text: '15-30 minutes',
    value: '15-30 minutes',
  },
  {
    text: '30-60 minutes',
    value: '30-60 minutes',
  },
  {
    text: 'More than 1 hour',
    value: 'more than 1 hour',
  }
];
