import styled from '@emotion/styled';

export const TimerBlockItem = styled.section`
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #EA5556;
  margin-bottom: ${(props) => (props.margin ? '0' : '24px')};
`;
