// Core
import { takeEvery, all, call } from 'redux-saga/effects';

// Types
import { GET_PERSONAL_SUMMARY } from '../types';

// Workers
import { worker } from './workers';

function* watchWorker() {
  yield takeEvery(GET_PERSONAL_SUMMARY, worker);
}

export function* watchPersonalSummary() {
  yield all([call(watchWorker)]);
}
