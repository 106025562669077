import './styles.scss';

import { Button } from '@Components/Button';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const Content = () => {
  const isV2 = useSelector((state) => state.appVersion);
  const link = isV2 ? '/v2' : '';

  return (
    <div className="content">
      <p className="content-subtitle">Become happier with FastingHow</p>

      <h1 className="content-title">What is your fasting goal?</h1>

      <p className="content-subtitle">By choosing your main goal, we’ll be able to offer you a Personal Fasting Plan</p>

      <div className="btn-wrapper">
        <NavLink to={`${link}/quiz/intro/step`}>
          <Button name="Lose weight" margin="30px 0 0" />
        </NavLink>

        <NavLink to={`${link}/quiz/intro/step`}>
          <Button name="Boost my health" margin="10px 0 22px" />
        </NavLink>
      </div>

      <img
        alt="background"
        className="boy-with-girl"
        src={`/images/landing/boyWithGirl.png`}
        srcSet={`/images/landing/boyWithGirl.png 320w, /images/landing/boyWithGirl@2x.png 480w`}
      />

      <img src={`/images/landing/boy.png`} srcSet={`/images/landing/boy.png 320w, /images/landing/boy@2x.png 480w`} className="boy" />

      <img src={`/images/landing/girl.png`} srcSet={`/images/landing/girl.png 320w, /images/landing/girl@2x.png 480w`} className="girl" />
      <p className="content-subtitle">
        Results may vary depending on your starting point, goal and efforts. People using our service generally tend to lose 1-2 lbs per week.
      </p>
    </div>
  );
};
