import { trimSentence } from '@Utilities/trim';

const checkInputValue = (value) => {
  const trimValue = value && trimSentence(value);
  if (trimValue?.length > 0 && trimValue?.length < 2) {
    return 'Please enter a valid option';
  } else if (trimValue?.length > 15 && 'No similar events' !== trimValue) {
    return 'The maximum number of characters has been exceeded';
  }
};

export const getInputMessageError = () => {
  return {
    validate: (value) => {
      return checkInputValue(value);
    },
  };
};
