import {
  ContentContainer,
  ContentPicture,
  ContentText,
  ContentWrapper,
  FakeLoadingWrapper,
  LoadingContentTitle,
  LoadingContentWrapper,
} from './styled';
import { Header } from '@QuizComponents/Header';
import { FakeLoading } from '@Components/FakeLoading';
import { useEffect, useState } from 'react';
import { useInterval } from '@CustomHooks/useInterval';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { useHistory } from 'react-router-dom';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';

export const LoadingContentV2 = () => {
  const getContent = (elementIndex) => {
    const PICTURES_DATA = [
      {
        name: 'picture1',
        text: 'Fasting is backed by multidisciplinary research',
      },
      {
        name: 'picture2',
        text: 'Our approach to weight loss is based on changing behavioral patterns',
      },
      {
        name: 'picture3',
        text: 'It only takes 5 minutes a day',
      },
      {
        name: 'picture4',
        text: 'We’re going to change your relationship with food',
      },
      {
        name: 'picture5',
        text: 'We focus on understanding your body, not dieting',
      },
      {
        name: 'picture6',
        text: 'There are no good and bad foods',
      },
      {
        name: 'picture7',
        text: 'Just follow your custom plan to skip dinner or breakfast',
      },
      {
        name: 'picture8',
        text: 'And the best part? You can still eat whatever you want!',
      },
      {
        name: 'picture9',
        text: 'If you mess up, that’s okay too!',
      },
      {
        name: 'picture10',
        text: 'No one is perfect, and we all fall off the wagon',
      },
      {
        name: 'picture11',
        text: 'We’ll help you get back on track',
      },
    ];
    return (
      <ContentContainer>
        {PICTURES_DATA.map(({ name, text }, index) => (
          <ContentWrapper key={index} isActive={elementIndex === index}>
            <>
              <ContentPicture
                src={`/images/loadingContent/${name}.png`}
                srcSet={`/images/loadingContent/${name}.png 320w, /images/loadingContent/${name}@2x.png 480w`}
                alt={name}
              />
              <ContentText>{text}</ContentText>
            </>
          </ContentWrapper>
        ))}
      </ContentContainer>
    );
  };

  const [count, setCount] = useState(0);
  const [delay, setDelay] = useState(3200);
  useInterval(() => {
    // Your custom logic here
    if (count === 10) {
      setCount(11);
      setDelay(null);
    }
    setCount(count + 1);
  }, delay);

  useEffect(() => {
    scrollPageToTop();
    sendAmplitudeData('f_final_loading_view');
  }, []);

  const HISTORY = useHistory();
  useEffect(() => {
    if (!delay) {
      HISTORY.push('/v2/quiz/tailoring_plan');
    }
  }, [delay]);
  return (
    <LoadingContentWrapper>
      <Header isShowPreviousBtn={false} isShowNumberQuestion={false} />
      <FakeLoadingWrapper>
        <FakeLoading isActiveFakeLoading={true} isSmallLoader={true} />
      </FakeLoadingWrapper>
      <LoadingContentTitle>
        Just a moment... <br />
        Getting things ready for you
      </LoadingContentTitle>
      {getContent(count)}
    </LoadingContentWrapper>
  );
};
