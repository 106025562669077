import { RadioButton } from '@Components/RadioButton';
import { Title } from '@QuizComponents/Title';
import { SubTitle } from '@QuizComponents//SubTitle';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/QuizV2';
import { radioButtonsData } from './radioButtonsData';

export const YourEatingStyleV2 = ({ subStepName, handleRadioButton }) => {
    const quiz = useSelector((store) => store.quiz);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            updateQuizProgressBar({
                stepName: 'step1',
                currentStep: 3,
                totalSteps: 8,
            })
        );
    }, []);

    const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

    useEffect(() => {
        setCurrentStepName('step1');
    }, []);
    return (
        <>
          <Title title='What is your eating style?' />
          <SubTitle text='Choose one option' style={{ marginBottom: '20px' }} />
          {radioButtonsData.map(({ text, value }) => {
            return (
              <RadioButton
                key={value}
                id={value}
                text={text}
                name={subStepName}
                checked={quiz['diet'] === value}
                onClick={handleRadioButton}
                value={value}
              />
            );
          })}
        </>
    );
};
