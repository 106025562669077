import styled from '@emotion/styled';

export const ModalWrapper = styled.section`
  background-color: rgba(23, 24, 27, 0.5);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 58px 16px;
  z-index: 2;
`;

export const ModalContent = styled.div`
  padding: 24px;
  background-color: #fff;
  width: 100%;
  max-width: 460px;
  max-height: 700px;
  margin: auto;
  border-radius: 12px;
`;
