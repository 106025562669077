import { UPDATE_QUIZ, UPDATE_QUIZ_PROGRESS_BAR } from '../actions/quizAction';

const initialState = {
  step1: {
    currentStep: 0,
    totalSteps: 5,
  },

  step2: {
    currentStep: 0,
    totalSteps: 5,
  },

  step3: {
    currentStep: 0,
    totalSteps: 9,
  },
  step4: {
    currentStep: 0,
    totalSteps: 6,
  },
  measure: null
};

export const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_QUIZ:
      return { ...state, [action.payload.name]: action.payload.value };
    case UPDATE_QUIZ_PROGRESS_BAR:
      return {
        ...state,
        [action.payload.stepName]: {
          currentStep: action.payload.currentStep,
          totalSteps: action.payload.totalSteps,
        },
      };
    default:
      return state;
  }
};
