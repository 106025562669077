import { Picture, PictureName, PictureWrapper, RadioButtonsWrapper, YourBodyShapeWrapper } from './styled';
import { Title } from '@QuizComponents/Title';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useContext, useEffect } from 'react';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { RadioButton } from '@Components/RadioButton';
import { radioButtonsData } from './radioButtonsData';

export const YourBodyShape = ({ handleRadioButton, subStepName }) => {
  const dispatch = useDispatch();
  const quiz = useSelector((store) => store.quiz);
  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;
  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 11,
        totalSteps: 11,
      })
    );
    dispatch(
      updateQuizProgressBar({
        stepName: 'step4',
        currentStep: 0,
        totalSteps: 8,
      })
    );

    setCurrentStepName('step3');
  }, []);


  const advanced = {
    isActive: true,
  };

  const getPictureContent = (src, srcSet, alt, value) => {
    return (
      <PictureWrapper>
        <Picture src={src} srcSet={srcSet} alt={alt} />
        <PictureName>{value}</PictureName>
      </PictureWrapper>
    );
  };

  const getRadioButtonsContent = (sectionName) => {
    return (
      <RadioButtonsWrapper>
        {radioButtonsData[sectionName].map(({ value, id, picture: { src, srcSet, alt } }, index) => (
          <Fragment key={index}>
            <RadioButton
              id={id}
              value={value}
              advanced={advanced}
              onClick={'6' === id ? null : handleRadioButton}
              name={subStepName}
              checked={quiz['your-body-shape'] === value}
              style={{ opacity: '6' === id ? 0 : 1 }}
            >
              {getPictureContent(src, srcSet, alt, value)}
            </RadioButton>
          </Fragment>
        ))}
      </RadioButtonsWrapper>
    );
  };
  return (
    <YourBodyShapeWrapper>
      <Title title='What is your body shape?' />
      {getRadioButtonsContent('top')}
      {getRadioButtonsContent('bottom')}
    </YourBodyShapeWrapper>
  );
};
