// Core
import { takeEvery, all, call } from 'redux-saga/effects';

// Types
import { CHECK_ON_EXISTING_EMAIL } from '../types';

// Workers
import { worker } from './workers';

function* watchWorker() {
  yield takeEvery(CHECK_ON_EXISTING_EMAIL, worker);
}

export function* watchCheckOnExistingEmail() {
  yield all([call(watchWorker)]);
}
