import { SubTitle } from '@QuizComponents/SubTitle';
import { RadioButton } from '@Components/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { getAgeContent } from '../getAgeContent';
import { LifeStyleWrapper } from './styled';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { getWordsContent } from '../getWordsContent';
import { radioButtonsData } from './radioButtonsData';

export const LifeStyle = ({ handleRadioButton, subStepName }) => {
  const dispatch = useDispatch();
  const quiz = useSelector((store) => store.quiz);
  const age = quiz?.['birthDate'] && quiz?.['birthDate'].birthDate;
  const currentWeight = quiz?.['your-weight']?.weight;
  const desiredWeight = quiz?.['your-desired-weight'].weight;
  const subtractWeight = currentWeight && desiredWeight ? currentWeight - desiredWeight : 'some';
  const subtitleText = `Women in their ${getAgeContent(
    age
  )}, sometimes find it difficult to reach their target weight and maintain a healthy metabolism. 
  Our personalized program will help you get to your target weight 
  of ${subtractWeight} lbs and achieve your health and well-being goals while maintaining a clear focus 
  on improving your lifestyle. Which of the following best describes your lifestyle?`;

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 7,
        totalSteps: 11,
      })
    );
    sendAmplitudeData('Step_3_7_Lifestyle_view');
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step3');
  }, []);

  return (
    <LifeStyleWrapper>
      <SubTitle style={{ margin: '20px' }} text={subtitleText} />
      {radioButtonsData.map(({ value }) => {
        return (
          <RadioButton
            key={value}
            id={value}
            text={getWordsContent(value)}
            name={subStepName}
            checked={quiz['lifestyle'] === value}
            onClick={handleRadioButton}
            value={value}
          />
        );
      })}
    </LifeStyleWrapper>
  );
};
