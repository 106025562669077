import styled from '@emotion/styled';

export const YourBodyShapeWrapper = styled.section`
  .radio-button {
    max-width: 90px;
    min-width: 90px;
    margin-right: 8px;
  }
  label {
    max-width: 90px;
    min-height: 200px;
    &::before {
      content: '';
      position: absolute;
      margin-left: 0 !important;
      bottom: 12px;
    }
  }
  .top-title {
    display: none;
  }
  .bottom-title {
    display: none;
    padding-left: 0;
  }
`;

export const RadioButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const PictureWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 12px;
  padding-left: 0 !important;
`;
export const Picture = styled.img`
  display: block;
  max-width: 52px;
  max-height: 105px;
  min-height: 105px;
  margin: auto;
`;
export const PictureName = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
`;
