import styled from '@emotion/styled';

export const FormWrapper = styled.section``;

export const InputWrapper = styled.div`
  margin: 12px 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  section {
    &:first-of-type {
      margin-right: 1px;
    }
  }
  input {
    &:nth-child(0) {
      display: none;
    }
  }
  & input:focus {
    background: #edf4e4;
    border: 1px solid #d4e4be;
    color: #6ba50d;
  }
`;

export const CardNumberWrapper = styled.div`
  position: relative;
  #stripe-content {
    position: absolute;
    right: 0;
  }
  & input:focus {
    background: #edf4e4;
    border: 1px solid #d4e4be;
    color: #6ba50d;
  }
`;

export const CardTypeWrapper = styled.div`
  max-width: 40px;
  max-height: 12px;
`;

export const CardType = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

export const CardInputIcon = styled.div`
  position: absolute;
  top: 34px;
  right: 16px;
`;
export const CvvInfo = styled.div`
  width: 20px;
  height: 20px;
  font-size: 16px;
  border-radius: 50%;
  background-color: #c5cbd2;
  color: #fff;
  text-align: center;
  line-height: 21px;
  padding-left: 1px;
`;

export const CardInputTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardInputTitle = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: #878787;
  @media (min-width: 960px) {
    font-size: 16px;
    line-height: 16px;
  }
`;

export const CardInputPowered = styled.div`
  display: flex;
  justify-content: center;
`;

export const CardInputPoweredText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-right: 4px;
  padding-top: 2px;
  color: #2e3064;
`;
export const CardInputPoweredCompanyName = styled.div`
  max-width: 44px;
  max-height: 18px;
`;
