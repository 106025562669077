import { TimerBlock } from '@QuizComponents/TimerBlock';
import {
  DiscountBlockContentWrapper,
  DiscountBlockText,
  DiscountBlockTitle,
  DiscountBlockWrapper,
  DiscountDescription,
  DiscountGiftIcon,
  DiscountGiftWrapper,
  StarText,
  StarWrapper,
  TimerBlockWrapper,
} from './styled';
import { useRef } from 'react';
import { useIsScrolledToElement } from '@CustomHooks/useIsScrolledToElement';

export const DiscountBlock = ({ ...props }) => {
  const ref = useRef();
  const getTitle = () => {
    if ((!props.paymentPlan && !props.paymentPlan?.discount) || null === props.paymentPlan ) {
      return 'Your personal plan trial is ready for you!';
    }
    if (props.isExpiredDiscount) {
      return 'Your discount has expired';
    }
    return 'Snatch your subscription discount!';
  };

  const title = getTitle();
  const discountTitleInlineStyles = {
    marginBottom: props.isExpiredDiscount ? '0' : '8px',
    color: props.isExpiredDiscount ? '#EA5556' : '#191B1F',
  };
  const isScrollToDiscountSection = useIsScrolledToElement(400);
  const timerBlockInlineStyles = {
    color: '#FA5C78',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '4px',
    marginTop: !props.isExpiredDiscount && isScrollToDiscountSection ? '10px' : '0',
  };

  return (
    <>
      {!props.isExpiredDiscount && (
        <DiscountBlockWrapper ref={ref} isScrollToDiscountSection={isScrollToDiscountSection} isExpiredDiscount={props.isExpiredDiscount}>
          {!isScrollToDiscountSection && (
            <DiscountBlockContentWrapper isScrollToDiscountSection={isScrollToDiscountSection}>
              <StarWrapper>
                <StarText>-50%</StarText>
                <img width={48} height={48} src={'/images/payment/star.svg'} alt/>
              </StarWrapper>
              <DiscountBlockTitle style={discountTitleInlineStyles}>{title}</DiscountBlockTitle>
            </DiscountBlockContentWrapper>
          )}
          {props.timer && !props.isExpiredDiscount && props.paymentPlan?.discount && (
            <DiscountGiftWrapper isScrollToDiscountSection={isScrollToDiscountSection}>
              {isScrollToDiscountSection && <DiscountGiftIcon>🎁</DiscountGiftIcon>}
              {!isScrollToDiscountSection ?
                (
                  <>
                    <DiscountDescription>Time Remaining</DiscountDescription>
                    <TimerBlockWrapper>
                      <TimerBlock expiryTimestamp={props.timer} style={timerBlockInlineStyles} />
                    </TimerBlockWrapper>
                  </>
                ) : (
                  <TimerBlock expiryTimestamp={props.timer} style={timerBlockInlineStyles} />
                )}
            </DiscountGiftWrapper>
          )}
          {!isScrollToDiscountSection  && <DiscountBlockText>Scroll down to get started!</DiscountBlockText>}
        </DiscountBlockWrapper>
      )}
    </>
  );
};
