import styled from '@emotion/styled';

export const InputBlock = styled.section``;

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputLabel = styled.p`
  font-size: 11px;
  color: #878787;
  line-height: 14px;
  margin-bottom: 1px;
  text-align: start;
`;

export const Input = styled.input`
  border: unset;
  background-color: #f4f4f4;
  border-radius: 12px;
  height: 54px;
  padding: 0 16px;
  font-size: 15px;
  line-height: 22px;
  border: 1px solid transparent;
  color: #1f2d42;
  width: 100%;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #acacac;
    font-size: 15px;
    line-height: 22px;
  }

  border: ${(props) => `${props.errorMessage ? '1px solid #FF6A6B !important' : 'unset'}`};
`;

export const InputErrorMessage = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: #fa5c78;
  margin: 8px 0;
  font-weight: 500;
`;

export const InputErrorMessageHeight = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: #fa5c78;
  font-weight: 500;
  max-width: 130px;
  width: 100%;
  margin: 5px 15px;
`;

export const InputErrorMessageWrapper = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
`;
