import styled from '@emotion/styled';

export const MeasureWrapper = styled.p`
  position: absolute;
  top: 36px;
  right: 16px;
  font-size: 15px;
  line-height: 22px;
  color: #878787;
`;
