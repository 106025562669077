import { googleAnaliticsStoreApi } from '@Utilities/api';
import Cookies from 'js-cookie';
import { getUtmParams } from '@Utilities/storeUtmParams';

export const cookieGa = () => {
  try {
    const ga = Cookies.get('_ga');
    if (null === ga) {
      return '';
    }
    return ga.slice(6)
  } catch (err) {
    return undefined;
  }
};

export const googleAnaliticsStorage = (email) => {
  const ga = cookieGa();
  const { source, campaign, medium } = getUtmParams() || {};
  googleAnaliticsStoreApi(email, ga, source, campaign, medium)
    .catch(err => console.error(err));
};