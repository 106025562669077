export const getWordsContent = (string) => {
  let words = string.split(' ');
  const DIVIDER = Math.ceil(words.length / 2);
  words = [words.slice(0, DIVIDER).join(' '), words.slice(DIVIDER, words.length).join(' ')];
  return (
    <div style={{ marginLeft: 0 }}>
      {words.map((item, index) => (
        <p key={index}>{item}</p>
      ))}
    </div>
  );
};
