import { Title } from '@QuizComponents/Title';
import { RadioButton } from '@Components/RadioButton';
import { Header } from '@QuizComponents/Header';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { useEffect } from 'react';
import { gaEventAnalytics } from '@Utilities/gaEventAnalytics';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { FBEvent } from '@Analytics/facebookPixel';

export const IntermittentFasting = () => {
  const history = useHistory();
  const quiz = useSelector((store) => store.quiz);
  const dispatch = useDispatch();

  useEffect(() => {
    FBEvent('ViewContent');
    gaEventAnalytics('fast_quiz_started');
  }, []);

  const handleRadioButton = (event) => {
    const { name, value } = event.target;

    dispatch(
      updateQuiz({
        name,
        value,
      })
    );

    sendAmplitudeData('Intro_1', {
      fasting_level: value,
    });

    setTimeout(() => {
      history.push('description');
    }, 300);
  };

  return (
    <div className='intermittent-fasting'>
      <Header isShowPreviousBtn={false} isShowNumberQuestion={false} />
      <Title title='Have you heard about intermittent fasting?' />

      <RadioButton
        id='Just the name'
        text='Just the name'
        onClick={handleRadioButton}
        checked={'Just the name' === quiz['intermittent-fasting']}
        name='intermittent-fasting'
        value='Just the name'
      />
      <RadioButton
        id='I know a few things'
        text='I know a few things'
        onClick={handleRadioButton}
        checked={'I know a few things' === quiz['intermittent-fasting']}
        name='intermittent-fasting'
        value='I know a few things'
      />
      <RadioButton
        id='I know everything about it!'
        text='I know everything about it!'
        onClick={handleRadioButton}
        checked={'I know everything about it!' === quiz['intermittent-fasting']}
        name='intermittent-fasting'
        value='I know everything about it!'
      />
    </div>
  );
};
