export const pinterestEvent = (event, price) => {
  window.pintrk(
    'track',
    `${event}`,
    price ? {
      value: price,
      currency: 'USD',
    } : {},
    function(didInit, error) {
      if (!didInit)
      {
        console.log('error pintrk', error) ;
      }
    }
  );
};