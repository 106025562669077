import { SubTitle } from '@QuizComponents/SubTitle';
import { RadioButton } from '@Components/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { QuizContext } from '@Pages/Quiz/QuizV2';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { MetabolismWrapper } from './styled';
import { getWordsContent } from '../getWordsContent';
import { radioButtonsData } from './radioButtonsData';

export const MetabolismV2 = ({ handleRadioButton, subStepName }) => {
  const dispatch = useDispatch();
  const quiz = useSelector((store) => store.quiz);
  const subtitleText = (
    <span>
      In addition to what you eat, <span style={{ fontWeight: 'bold' }}>how often</span> you eat also has an impact on your metabolism. Which of the
      following best describes your typical day?"
    </span>
  );

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 3,
        totalSteps: 11,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step3');
  }, []);

  return (
    <MetabolismWrapper>
      <SubTitle style={{ margin: '20px' }} text={subtitleText} />
      {radioButtonsData.map(({ value }) => {
        return (
          <RadioButton
            key={value}
            id={value}
            text={getWordsContent(value)}
            name={subStepName}
            checked={quiz['metabolism'] === value}
            onClick={handleRadioButton}
            value={value}
          />
        );
      })}
    </MetabolismWrapper>
  );
};
