import { Title } from '@QuizComponents/Title';
import { SubTitle } from '@QuizComponents/SubTitle';
import { RestrictChart } from '@Components/Icons';

import './styles.scss';
import { Button } from '@Components/Button';
import { useHistory } from 'react-router-dom';
import { Header } from '@QuizComponents/Header';
import { useEffect } from 'react';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';

export const RestrictiveDietFail = () => {
  const history = useHistory();

  useEffect(() => {
    scrollPageToTop();
  }, []);

  const handleClick = () => {
    history.push('health_advice');
  };
  return (
    <div className="restrictive-diet-fail">
      <Header isShowPreviousBtn={false} isShowNumberQuestion={false} />
      <Title title="Why restrictive diet fail" styleProps={{ marginBottom: '6px' }} />
      <SubTitle text="aka the “yo-yo effect”" />

      <div className="restrict-chart-wrapper">
        <RestrictChart />
      </div>

      <div className="ncbi-wrapper">
        <img src="/images/ncbi@2x.png" className="ncbi-img" />
        <p className="ncbi-wrapper__title">The National Center for Biotechnology Information</p>
        <p className="ncbi-wrapper__description">
          found that weight cycling was associated with greater weight gain, less physical activity, and a higher prevalence of binge eating.
        </p>
      </div>

      <div className="rochester-wrapper">
        <img src="/images/rochester@2x.png" className="rochester-img" />
        <p className="rochester-wrapper__title">The University of Rochester Medical Center</p>
        <p className="rochester-wrapper__description">
          reports that weight cycling may raise certain health risks, such as heart disease, high blood pressure, gallbladder disease, and high
          cholesterol.
        </p>
      </div>

      <Button name="Continue" margin="24px 0" minWidth="100%" handleClick={handleClick} />
    </div>
  );
};
