import { put } from 'redux-saga/effects';
import axios from 'axios';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';

export function* worker({ payload }) {
  const { FIRST_PRICE_ID, SECOND_PRICE_ID, THIRD_PRICE_ID } = payload;
  const rootUrl = process.env.REACT_APP_ROOT_HOST;
  const paymentPlanUrl = `${rootUrl}/api/payment/plan/details?prices[]=${FIRST_PRICE_ID}&prices[]=${SECOND_PRICE_ID}&prices[]=${THIRD_PRICE_ID}`;
  try {
    const response = yield axios.get(paymentPlanUrl);
    yield put(
      updateQuiz({
        name: 'paymentPlan',
        value: response.data.data.prices,
      })
    );
  } catch (error) {
    yield put(
      updateQuiz({
        name: 'paymentPlan',
        value: null,
      })
    );
  }
}
