import { Title } from '@QuizComponents/Title';
import { SubTitle } from '@QuizComponents/SubTitle';
import { RadioButton } from '@Components/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import './styles.scss';
import { useContext, useEffect } from 'react';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeData } from '@Analytics/amplitude';

export const YourBodyType = ({ subStepName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const quiz = useSelector((store) => store.quiz);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;
  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 11,
        totalSteps: 11,
      })
    );
    dispatch(
      updateQuizProgressBar({
        stepName: 'step4',
        currentStep: 0,
        totalSteps: 6,
      })
    );
    setCurrentStepName('step3');
  }, []);

  const handleRadioButton = (event) => {
    const { name, value } = event.target;

    dispatch(
      updateQuiz({
        name,
        value,
      })
    );
    sendAmplitudeData('Step_3_11_bType_click', {
      body_type: value,
    });

    setTimeout(() => {
      history.push('/quiz/buy_now/forecast', { id: '1' });
    }, 300);
  };

  return (
    <div className='your-body-type'>
      <Title title='What is your body type?' />
      <SubTitle text='Grip your opposite wrist using the thumb and middle finger.' />
      <RadioButton
        id='1'
        text='Thumb and middle finger overlapping with ease'
        value='Ectomorph'
        advanced={{
          isActive: true,
          topTitle: 'Ectomorph',
          bottomTitle: 'Difficulty in gaining body fat and muscle',
        }}
        onClick={handleRadioButton}
        name={subStepName}
        checked={'Ectomorph' === quiz['your-body-type']}
      />
      <RadioButton
        id='2'
        text='Thumb and middle finger touching each other'
        value='Mesomorph'
        advanced={{
          isActive: true,
          topTitle: 'Mesomorph',
          bottomTitle: 'Loses body fat easily and gains muscle quickly',
        }}
        onClick={handleRadioButton}
        name={subStepName}
        checked={'Mesomorph' === quiz['your-body-type']}
      />
      <RadioButton
        id='3'
        text='Thumb and middle finger doesn’t come into contact'
        value='Endomorph'
        advanced={{
          isActive: true,
          topTitle: 'Endomorph',
          bottomTitle: 'Gains both body fat and muscle easily',
        }}
        onClick={handleRadioButton}
        name={subStepName}
        checked={'Endomorph' === quiz['your-body-type']}
      />
    </div>
  );
};
