import Cookies from 'js-cookie';

export const useGetGaExperimentId = () => {

  const getGaExpFromCookie = () => {
    const gaexp = Cookies.get('_gaexp');
    if (!gaexp) {
      return;
    }

    const formatGaexp = gaexp?.split('!').map((value, index) => {
      if (0 === index) {
        value = value.toString().slice(7);
      }
      return value.split('.').filter((elem, elemIndex) => 1 !== elemIndex);
    });

    return getUpdateGaExperimentId(formatGaexp);
  };

  const getUpdateGaExperimentId = (gaExp) => {
    const experimentData = [];
    gaExp?.forEach(([elem, variant]) => {
      experimentData[elem] = '0' === variant ? 'Master' : 'Variation 1';
    });
    return experimentData;
  };

  return getGaExpFromCookie();
};
