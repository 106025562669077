import { SubTitle } from '@QuizComponents/SubTitle';
import { Title } from '@QuizComponents/Title';
import { RadioButton } from '@Components/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { AlLergiesWrapper } from './styled';
import { Button } from '@Components/Button';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { radioButtonsData } from './radioButtonsData';
import { filterValueObject, deleteResetValue } from  '@Utilities/filterObjectValue';

export const Allergies = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const radioButtonValue = useSelector((store) => store.quiz?.['dietaryRestrictions']);
  const [checkState, setCheckState] = useState(radioButtonValue ? radioButtonValue : null);

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step1',
        currentStep: 4,
        totalSteps: 8,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step1');
  }, []);

  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit
  } = useForm({
    mode: 'onChange'
  });

  const radioButtonData = (text, e) => {
    const filterValue = filterValueObject(checkState, text);
    const filterValueReset = deleteResetValue(checkState);

    if (text && (e.target.name === text)) {
      const result = checkState ? filterValue : checkState;
      'reset' === text ?
        setCheckState({}) :
        setCheckState({ ...result });
    } else {
      'reset' === e.target.name ?
        setCheckState({ reset: 'reset' }) :
        setCheckState({ ...filterValueReset, [e.target.name]: e.target.value });
    }
  };

  const handleClick = (e, text) => {
    getCheckedData();
    radioButtonData(text, e);
  };

  const onSubmit = () => {
    dispatch(
      updateQuiz({
        name: 'dietaryRestrictions',
        value: { ...checkState } ,
      })
    );
    sendAmplitudeData('Step_1_4_Allergies', {
      Allergies: { ...checkState }
    });
    history.push('how_many_meals_per_day');
  };
  const getCheckedData = () => {
    const value = checkState && Object.values(checkState) <= 0;
    return !((isDirty || !!(radioButtonValue)) && isValid) || value;
  };
  const disabled = getCheckedData();
  return (
    <AlLergiesWrapper>
      <Title title='Do you have any allergies?' />
      <SubTitle style={{ margin: '20px' }} text='Choose as many options as you want' />
      <form onSubmit={handleSubmit(onSubmit)}>
        {radioButtonsData.map(({ text, value }) => {
          return (
            <RadioButton
              key={text}
              id={text}
              text={text}
              name={value}
              checked={checkState ? checkState[value] : null}
              value={value}
              control={control}
              onClick={(e) => {
                handleClick(e, checkState ? checkState[value] : null)
              }}
              multiRadio
            />
          );
        })}
        <Button name='Continue' minWidth='100%' margin='30px 0px' type='submit' isDisabled={disabled} />
      </form>
    </AlLergiesWrapper>
  );
};
