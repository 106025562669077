export const radioButtonsData = [
  {
    value: 'Vitamins'
  },
  {
    value: 'Hormones'
  },
  {
    value: 'Antibiotics'
  },
  {
    value: 'Other'
  },
  {
    value: 'I am not taking any medications'
  }
];
