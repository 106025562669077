export const radioButtonsData = [
  {
    value: 'Vacation'
  },
  {
    value: 'Wedding'
  },
  {
    value: 'School reunion'
  },
  {
    value: 'Birthday party'
  },
  {
    value: 'No similar events'
  }
];
