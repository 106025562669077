import { Title } from '@QuizComponents/Title';
import { Header } from '@QuizComponents/Header';
import { FakeLoading } from '@Components/FakeLoading';
import './styles.scss';
import { ProgressBar } from '@Components/ProgressBar';
import { useEffect, useState } from 'react';
import { Trophy } from '@Components/Icons';
import { RadioButton } from '@Components/RadioButton';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { Modal } from '@Components/Modal';
import {
  NutritionHeader,
  NutritionName,
  NutritionPicture,
  NutritionPosition,
  NutritionRightSection,
  NutritionSignUp,
  NutritionText,
  NutritionWrapper,
} from './styled';
import { Button } from '@Components/Button';
import { IntercomChat } from '@QuizComponents/IntercomChat';
import { useForm } from 'react-hook-form';
import { SubTitle } from '@QuizComponents/SubTitle';

export const TailoringPlan = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const quiz = useSelector((store) => store.quiz);

  const [progressSections, setProgressSections] = useState({
    bodyComposition: {
      isActive: false,
      count: 0,
    },
    nutrionActivity: {
      isActive: false,
      count: 0,
    },
    generationPersonalPlan: {
      isActive: false,
      count: 0,
    },
    certifiedNutritionist: {
      isActive: false,
      count: 0,
    },
  });

  const [isShowModal, setIsShowModal] = useState(false);
  const hasModalShownBefore = !quiz['tailoring-plan'] || 'Question' === history.location?.state?.prev;

  const intervalUpdateCount = (name, duration) => {
    setProgressSections((section) => {
      return { ...section, [name]: { count: section[name].count, isActive: true } };
    });

    let timerId = setInterval(() => {
      setProgressSections((section) => {
        if ('generationPersonalPlan' === name && 90 === section[name].count && hasModalShownBefore) {
          setIsShowModal(true);
          clearInterval(timerId);
        }

        if (100 === section[name].count) {
          clearInterval(timerId);
          return {
            ...section,
            [name]: {
              count: section[name].count,
              isActive: false,
            },
          };
        } else {
          return {
            ...section,
            [name]: {
              count: section[name].count + 10,
              isActive: section[name].isActive,
            },
          };
        }
      });
    }, duration);
  };

  useEffect(() => {
    intervalUpdateCount('bodyComposition', 500);
  }, []);

  useEffect(() => {
    if (100 === progressSections.bodyComposition.count) {
      intervalUpdateCount('nutrionActivity', 700);
    }
  }, [progressSections.bodyComposition]);

  useEffect(() => {
    if (100 === progressSections.nutrionActivity.count) {
      intervalUpdateCount('generationPersonalPlan', 2000);
    }
  }, [progressSections.nutrionActivity]);

  useEffect(() => {
    if (100 === progressSections.generationPersonalPlan.count) {
      intervalUpdateCount('certifiedNutritionist', 3000);
    }
  }, [progressSections.generationPersonalPlan]);

  const [isShowNutritionModal, setIsShowNutritionModal] = useState(false);
  useEffect(() => {
    if (100 === progressSections.certifiedNutritionist.count && !hasModalShownBefore) {
      setIsShowNutritionModal(true);
    }
  }, [progressSections.certifiedNutritionist]);

  useEffect(() => {
    if (hasModalShownBefore) {
      sendAmplitudeData('Step_4_7_loading_view');
    } else if (isShowNutritionModal) {
      sendAmplitudeData('f_chat_let’sTalk_view');
    } else {
      sendAmplitudeData('f_buy_now_tailoring_plan_view');
    }
  }, [isShowNutritionModal]);

  const { bodyComposition, nutrionActivity, generationPersonalPlan, certifiedNutritionist } = progressSections;

  const returnProgressSectionsMarkup = (count, isActive, isMargin, nameSection, isShow = true) => {
    return (
      <>
        {isShow && (
          <div className='loading' style={{ marginTop: `${isMargin ? '20px' : '0px'}` }}>
            <div className={`loading-wrapper ${count && 'is-active'}`}>
              <p className='loading-wrapper__title'>{nameSection}</p>
              {count ? <FakeLoading isActiveFakeLoading={isActive} isSmallLoader={true} /> : ''}
              <p className='loading-wrapper__percentage-text'>{count}%</p>
            </div>
            <ProgressBar occupancyWidth={count} />
          </div>
        )}
      </>
    );
  };

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      'tailoring-plan': quiz['tailoring-plan'] ? quiz['tailoring-plan'] : null,
    },
  });

  const onSubmit = (data) => {
    const value = data['tailoring-plan'];
    sendAmplitudeData('Step_4_8_workout_click', {
      workout: value,
    });

   dispatch(
      updateQuiz({
        name: 'tailoring-plan',
        value,
      })
    );
    setTimeout(() => {
      history.push('/quiz/buy_now/forecast', { id: '2' });
    }, 300);
  };

  const getCheckedData = () => {
    return !(isDirty || quiz['tailoring-plan']);
  };
  const isDisabled = getCheckedData();

  const getProgressBarNames = (option) => {
    const progress = {};
    if (option) {
      progress.first = 'Body Composition';
      progress.second = 'Nutrion & Activity';
    } else {
      progress.first = 'Analyzing Body Parameters';
      progress.second = 'Meals and Activity';
    }
    return progress;
  };
  const { first, second } = getProgressBarNames(hasModalShownBefore);

  const getNutritionContent = () => {
    return (
      <NutritionWrapper>
        <NutritionHeader>
          <NutritionPicture
            src={`/images/loadingContent/amanda.png`}
            srcSet={`/images/loadingContent/amanda.png 320w, /images/loadingContent/amanda@2x.png 480w`}
          />
          <NutritionRightSection>
            <NutritionName>Amanda</NutritionName>
            <NutritionPosition>Health Coach</NutritionPosition>
          </NutritionRightSection>
        </NutritionHeader>
        <NutritionText>
          We've created your action plan, but to get a better grasp of the specifics, you can connect with a certified professional in real-time to
          talk about a few key questions regarding your expectations.
        </NutritionText>
        <IntercomChat />
        <NutritionSignUp
          onClick={() => {
            sendAmplitudeData('f_chat_ready_toSignUp');
            history.push('buy_now/checkout');
          }}
        >
          I’m ready to Sign Up
        </NutritionSignUp>
      </NutritionWrapper>
    );
  };

  return (
    <div className='tailoring-plan'>
      <Header isShowPreviousBtn={false} isShowNumberQuestion={false} />
      <Title title='Tailoring a plan for you' />

      {returnProgressSectionsMarkup(bodyComposition.count, bodyComposition.isActive, true, first)}
      {returnProgressSectionsMarkup(nutrionActivity.count, nutrionActivity.isActive, true, second)}
      {returnProgressSectionsMarkup(generationPersonalPlan.count, generationPersonalPlan.isActive, true, 'Generating Your Action Plan')}
      {returnProgressSectionsMarkup(
        certifiedNutritionist.count,
        certifiedNutritionist.isActive,
        true,
        'Connecting with \n' + 'Certified Nutritionist',
        !hasModalShownBefore
      )}

      <div className='tailoring-plan-info'>
        <Trophy />
        <p className='tailoring-plan-info__text'>148,838 people like you archived their goals with our support</p>
      </div>

      <Modal isShow={isShowModal}>
        <Title
          title='How many times a week do you workout?'
          style={{ fontSize: '16px', lineHeight: '24px', marginBottom: '20px', marginTop: '0px' }}
        />
        <SubTitle
            text={'Your activity level is important for weight control.'}
            style={{ fontSize: '14px', lineHeight: '24px', marginBottom: '20px', marginTop: '0px' }}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <RadioButton id='1' text='Almost never' control={control} name='tailoring-plan' checked={'1.1' === quiz['tailoring-plan']} value='1.1' />
          <RadioButton id='2' text='Only walks' control={control} name='tailoring-plan' checked={'1.187' === quiz['tailoring-plan']} value='1.187' />
          <RadioButton id='3' text='1-2 times a week' control={control} name='tailoring-plan' checked={'1.275' === quiz['tailoring-plan']} value='1.275' />
          <RadioButton id='4' text='3-4 times a week' control={control} name='tailoring-plan' checked={'1.362' === quiz['tailoring-plan']} value='1.362' />
          <RadioButton id='5' text='5+ times a week' control={control} name='tailoring-plan' checked={'1.45' === quiz['tailoring-plan']} value='1.45' />
          <Button name='Confirm' minWidth='100%' margin='30px 0px' type='submit' isDisabled={isDisabled} />
        </form>
      </Modal>

      <Modal isShow={isShowNutritionModal}>{getNutritionContent()}</Modal>
    </div>
  );
};
