import styled from '@emotion/styled';

export const ResultsWrapper = styled.section`
  background-color: #f6f6fc;
  width: 100vw;
  padding-top: 20px;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin: -20px -50vw;
  padding-bottom: 20px;
`;

export const ResultsContainer = styled.div`
  max-width: 460px;
  margin: 20px auto auto;
  padding: 0 16px; ;
`;
