import { TimerBlock } from '@QuizComponents/TimerBlock';
import { HeaderContent, HeaderContentTextTimer, HeaderFormWrapper } from './styled';

const HeaderForm = ({ timer, paymentPlan, isExpiredDiscount }) => {
  const isShowTimer = timer && !isExpiredDiscount && paymentPlan?.discount;
  return (
    <HeaderFormWrapper>
      <HeaderContent isShowTimer={isShowTimer}>
        {isShowTimer && <HeaderContentTextTimer>Time remaining</HeaderContentTextTimer>}
        {isShowTimer && <TimerBlock expiryTimestamp={timer} margin />}
      </HeaderContent>
    </HeaderFormWrapper>
  );
};

export default HeaderForm;
