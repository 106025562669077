import React from 'react';
import { CheckBoxContentCheck, CheckBoxContentCheckIcon, CheckBoxContentCheckName, CheckBoxContentDescription } from './styled';
import { CheckRoundedContent } from '@Components/Icons';
const CheckBoxContent = ({ checkboxItems, ...props }) => {
  const getAdditionalValue = (identificator, title) => {
    if (props.getDiffWeight && identificator && identificator === 'reach') {
      return (
        <div>
          <CheckBoxContentCheckName dangerouslySetInnerHTML={{ __html: title }} />{' '}
          {/*<CheckBoxContentCheckName>{props.getDiffWeight()}</CheckBoxContentCheckName>*/}
        </div>
      );
    }

    return <CheckBoxContentCheckName dangerouslySetInnerHTML={{ __html: title }} />;
  };

  return (
    <>
      {checkboxItems.map(({ title, description, link, identificator }, index) => (
        <>
          <CheckBoxContentCheck key={index}>
            <CheckBoxContentCheckIcon>
              <CheckRoundedContent width="100%" height="100%" color="#6BA50D" />
            </CheckBoxContentCheckIcon>
            {getAdditionalValue(identificator, title)}
          </CheckBoxContentCheck>
          <CheckBoxContentDescription dangerouslySetInnerHTML={{ __html: description }} />
        </>
      ))}
    </>
  );
};

export default CheckBoxContent;
