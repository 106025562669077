import { conversionWeight } from '@Utilities/conversion'
const checkRules = (value, inputName, currentWeight, measure) => {
  const weightResult = conversionWeight(currentWeight, measure)
  const errorMessage = 'Your weight difference should be from 3 to 40 kg';
  const errorMessageLbs = 'Your weight difference should be from 6 to 88 lbs';
  const equalErrorMessage = "Your desired weight can't be the same you have right now";
  const errMesage = 'Please enter a value between 40 and 250';
  const errMesageLbs = 'Please enter a value between 90 and 550';
  switch (inputName) {
    case 'weight kg':
      const weightValueToNumber = Number(value);
      const resultValue = Number(weightResult) - weightValueToNumber;
      if (value < 40  || value > 250) {
        return errMesage;
      } else if ( resultValue > 40 || resultValue < 3) {
        return errorMessage;
      }  else if (resultValue <= 0) {
        return  equalErrorMessage;
      }
      break;

    case 'weight lbs':
      const weightValue = Number(value);
      const resultValueLbs = Number(weightResult) - weightValue;
      if (value < 90 || value > 550) {
        return errMesageLbs;
      } else if (resultValueLbs > 88 || resultValueLbs < 6) {
        return errorMessageLbs;
      } else if (resultValueLbs <= 0) {
        return equalErrorMessage;
      }
      break;
  }
};

export const getMessageError = (inputName, currentWeight, measure) => {
  return {
    validate: (value) => {
      return checkRules(value, inputName, currentWeight, measure);
    },
    required: 'This field is required.',
  };
};
