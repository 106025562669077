import styled from '@emotion/styled';

export const WaterWrapper = styled.section`
  margin-top: 14px;
  border: 1px solid #9ebbf1;
  border-radius: 20px;
  padding: 18px;
  background-color: #fff;
`;

export const WaterSubtitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #999eaf;
  text-align: left;
`;

export const WaterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const WaterCountLitersWater = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #000;
  font-weight: 500;
`;

export const WaterCupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
`;

export const WaterCupContainer = styled.div`
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const WaterCupItem = styled.div`
  flex-basis: calc(100% / 14);
`;
