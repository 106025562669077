import React from 'react';
import { useTimer } from 'react-timer-hook';
import { useDispatch } from 'react-redux';

import { addZero } from '@Utilities/addZero';
import { setTimer } from '@Store/components/buyNow/actions';

const Timer = ({ expiryTimestamp }) => {
  const dispatch = useDispatch();
  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      dispatch(setTimer({ timer: 'end' }));
    },
  });

  return (
    <span>
      {addZero(minutes)}:{addZero(seconds)}
    </span>
  );
};

export default Timer;
