export const getAgeContent = (age) => {
  age = Number(age);
  if (age >= 18 && age < 30) {
    return 'twenties';
  } else if (age >= 30 && age < 40) {
    return 'thirties';
  } else if (age >= 40 && age < 50) {
    return 'forties';
  } else if (age >= 50 && age < 60) {
    return 'fifties';
  } else if (age >= 60 && age < 70) {
    return 'sixties';
  } else if (age >= 70 && age < 80) {
    return 'seventies';
  } else {
    return 'age';
  }
};
