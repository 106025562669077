import styled from '@emotion/styled';

export const HeaderFormWrapper = styled.section`
  margin-bottom: 30px;
`;

export const HeaderContent = styled.section`
  display: flex;
  justify-content: ${(props) => (props.isShowTimer ? props.justifyContent ? 'space-between' : 'center' : 'flex-end')};
  align-items: center;
`;

export const HeaderContentText = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #444;
`;

export const HeaderContentTextTimer = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #EA5556;
  margin-right: 10px;
`;
