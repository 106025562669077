import { Title } from '@QuizComponents/Title';
import { RadioButton } from '@Components/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { SubTitle } from '@QuizComponents/SubTitle';
import { radioButtonsData } from './radioButtonsData';

export const YourGender = ({ subStepName, handleRadioButton }) => {
  const quiz = useSelector((store) => store.quiz);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step2',
        currentStep: 4,
        totalSteps: 5,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step2');
  }, []);

  return (
    <div className='your-gender'>
      <Title title={'What\'s your gender?'} />
      <SubTitle text={'We need to know your biological sex in order to give you the best advice for your body type'} style={{ marginBottom: '20px' }} />
      {radioButtonsData.map(({ text, value }) => {
        return (
          <RadioButton
            key={value}
            id={value}
            text={text}
            name={subStepName}
            checked={quiz['gender'] === value}
            onClick={handleRadioButton}
            value={value}
          />
        );
      })}
    </div>
  );
};
