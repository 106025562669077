import amplitude from 'amplitude-js';

export const initAmplitude = () => {
  amplitude.getInstance().init(
    process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
    saveParamsReferrerOncePerSession: false,
  });
};

export const setAmplitudeUserDevice = (installationToken) => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};
