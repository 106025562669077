import { SECURITY_PAYMENT, SET_GOAL_GRAPH } from './types';
export const securityPayment = (payload) => {
  return {
    type: SECURITY_PAYMENT,
    payload,
  };
};

export const setGoalGraph = (payload) => {
  return {
    type: SET_GOAL_GRAPH,
    payload,
  };
};
