import { Logo } from '@Components/Icons';
import { LogoBlockIcon, LogoBlockWrapper } from './styled';

export const LogoBlock = ({ ...props }) => {
  return (
    <LogoBlockWrapper {...props}>
      <LogoBlockIcon>
        <Logo width={86} height={66} viewBox="0 0 50 40" />
      </LogoBlockIcon>
    </LogoBlockWrapper>
  );
};
