import './styles.scss';
import { NavLink } from 'react-router-dom';

const currentYear = new Date(Date.now()).getFullYear();

export const Footer = () => {
  return (
    <div className='footer'>
      <div className='container'>
        <div className='container__left-section'>
          <p className='container__left-section-item'>Need help?</p>
          <p className='container__left-section-item'>Contact out support if you have any questions. </p>
          <a href='mailto:support@lasta.app' className='container__left-section-item' target='_blank' without='true' rel='noreferrer'>
            support@lasta.app
          </a>
        </div>
        <div className='container__right-section'>
          <div className='container__right-section-item items-wrapper'>
            <NavLink className='items-wrapper-text' to='/terms-or-use'>
              Terms of Use
            </NavLink>

            <NavLink className='items-wrapper-text' to='/privacy-policy'>
              Privacy Policy
            </NavLink>
          </div>
          <p className='container__right-section-item'>© All Rights Reserved {currentYear}</p>
        </div>
      </div>
    </div>
  );
};
