import { put } from 'redux-saga/effects';
import axios from 'axios';
import { clearError } from '@Store/ui/actions';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { gaEventAnalytics } from '@Utilities/gaEventAnalytics';

export function* worker({ payload }) {
  const { data, setPaymentPlanInfo, setEmail, setResponseParams, history, prices } = payload;
  const rootUrl = process.env.REACT_APP_ROOT_HOST;
  const url = `${rootUrl}/api/email/check`;
  const params = {
    ...data,
  };

  const { discountPrice, defaultPrice } = prices;
  const paymentPlanUrl = `${rootUrl}/api/payment/plan/details?prices[]=${discountPrice}&prices[]=${defaultPrice}`;
  yield put(clearError());
  setResponseParams({
    isFetching: true,
  });

  try {
    const response = yield axios.post(url, params);
    const { data } = response;
    const { status } = data;
    const emailFromResponse = params.email;
    setEmail && setEmail(emailFromResponse);
    sendAmplitudeData('F_email_entered', {
      email_address: emailFromResponse,
    });
    gaEventAnalytics('fast_email_entered');
    if (status === 'success') {
      const response = yield axios.get(paymentPlanUrl);
      const { data } = response.data;
      const { prices } = data;
      setPaymentPlanInfo &&
        setPaymentPlanInfo({
          prices,
        });
      history.push('results');
    }
  } catch (error) {
    const errorMessage = error.response?.data.message;
    const status = error.response?.data.status;
    if (errorMessage) {
      setResponseParams({
        error: errorMessage,
      });
    } else if (status === 'error') {
      setResponseParams({
        error: 'Your email address is incorrect. Please try again!',
      });
    } else {
      setResponseParams({
        error: 'Error: network',
      });
    }
  } finally {
    setResponseParams({
      isFetching: false,
    });
  }
}
