export const ReviewersCardV2 = ({ title, text, src, src2, medium }) => {
  return (
     <div className="reviewers-cardV2">
       <div className="reviewers-cardV2-wrapper">
         <div className="reviewers-cardV2__item">
           <div className="item-text">Before</div>
           <div className="item-text">After</div>
         </div>
         <div className="reviewers-img-wrapper">
           <img src={src} srcSet={`${medium} width="768px`}  alt="reviewers" width={260}  />
         </div>
         <div className="reviewers-cardV2__item">
           <div className={"item-subtext"}>Eun</div>
           <div className={"item-subtext"}>- 14 lbs</div>
         </div>
       </div>
       <div className="reviewers-cardV2__text">{text}</div>
     </div>
  );
};