import { SafeCheck } from '@Components/Icons';
import './styles.scss';

export const InfoBlock = ({ text, isSafeCheck = true, fontSize = '11px', lineHeight = '14px', iconType = 'gift', ...props }) => {
  const getIcon = () => {
    if (iconType === 'safeCheck') {
      return <SafeCheck />;
    } else if (iconType === 'gift') {
      return <img src="/images/gift.png" width="24px" height="24px" alt="gift" />;
    } else if (iconType === 'hand') {
      return '☝️';
    }
  };
  return (
    <div className="info-block" {...props}>
      <div className="info-block-icon">{getIcon()}</div>
      <p className="info-block-text" style={{ fontSize, lineHeight }}>
        {text}
      </p>
    </div>
  );
};
