import { Route, Switch } from 'react-router-dom';
import { upSellData } from './upSellData';
import { UpSellComponent } from './UpSellComponent';
import { useSelector } from 'react-redux';

export const UpSell = () => {
  const { appVersion: isV2 } = useSelector((state) => state);

  return (
    <Switch>
      {upSellData.map((data) => {
        return (
          <Route path={`${isV2}/quiz/buy_now/upsell/${data.id}`} key={data.id}>
            <UpSellComponent data={data} isV2={isV2}/>
          </Route>
        )
      })}
    </Switch>
  );
};
