import styled from '@emotion/styled';

export const FooterWrapper = styled.section`
  background-color: #f6f6fc;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 22px 10px;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  img {
    max-width: 40px;
    max-height: 25px;
    width: 100%;
    margin-right: 3px;
  }
`;

export const FooterContent = styled.div`
  max-width: 460px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;

  img {
    width: 48px;
    min-width: 48px;
    height: auto;
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
  }
`;
