export const radioButtonsData = [
  {
    text: '1 meal',
    value: '1',
  },
  {
    text: '2 meals',
    value: '2',
  },
  {
    text: '3 meals',
    value: '3',
  },
  {
    text: '4 meals',
    value: '4',
  },
  {
    text: '5+ meals',
    value: '5+',
  }
];
