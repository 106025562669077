import {
  GetExcitedContent,
  GetExcitedLeftBlock,
  GetExcitedPhoto,
  GetExcitedPhotoDescription,
  GetExcitedRightBlock,
  GetExcitedSubTitle,
  GetExcitedWrapper,
} from './styled';
import { Title } from '@QuizComponents/Title';

export const GetExcited = () => {
  return (
    <GetExcitedWrapper>
      <Title title="Get excited!" styleProps={{ padding: 0, margin: 0, textAlign: 'left' }} />
      <GetExcitedSubTitle>Here’s how we predict your body will change.</GetExcitedSubTitle>
      <GetExcitedContent>
        <GetExcitedLeftBlock>
          <GetExcitedPhoto
            src={`/images/results/beforeGirl.png`}
            srcSet={`/images/results/beforeGirl.png 320w, /images/results/beforeGirl@2x.png 480w`}
            alt="score"
          />
          <GetExcitedPhotoDescription style={{ paddingLeft: '20px' }}>Before</GetExcitedPhotoDescription>
        </GetExcitedLeftBlock>
        <GetExcitedRightBlock>
          <GetExcitedPhoto
            src={`/images/results/afterGirl.png`}
            srcSet={`/images/results/afterGirl.png 320w, /images/results/afterGirl@2x.png 480w`}
            alt="score"
          />
          <GetExcitedPhotoDescription style={{ paddingLeft: '60px' }}>After</GetExcitedPhotoDescription>
        </GetExcitedRightBlock>
      </GetExcitedContent>
    </GetExcitedWrapper>
  );
};
