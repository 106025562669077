import React, { useContext, useEffect, useState } from 'react';
import { SubTitle } from '@QuizComponents/SubTitle';
import { Title } from '@QuizComponents/Title';
import { useDispatch, useSelector } from 'react-redux';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { Button } from '@Components/Button';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { ErrorMessage, EventDayWrapper } from './styled';
import { DatePick } from '@Components/DatePick';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';
import { getDate, dateDiff, formatDate } from '@Utilities/momentFunction';

export const BirthDate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const quiz = useSelector((store) => store.quiz);
  const eventDay = quiz?.['birthDate']?.birthDateString;
  const titleText = `When were you born?`;
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 1,
        totalSteps: 11,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step3');
    scrollPageToTop();
  }, []);

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      date: eventDay ? getDate(eventDay) : null,
    },
  });

  const onSubmit = (data) => {
    const dateNumber = dateDiff(data.date, 'years');
    const dateString = formatDate(data.date);

    dispatch(
      updateQuiz({
        name: 'birthDate',
        value: {
          birthDate: dateNumber,
          birthDateString :dateString
        },
      })
    );
    sendAmplitudeData('Step_3_1_age', {
      age: dateNumber,
    });
    history.push('typical_lunch');
  };

  const isDisabled =  !(eventDay || isDirty) || disabled;

  const rangeDateMin = () => {
    const date = new Date();
    const year = date.setFullYear(date.getFullYear());
    return new Date(year);
  }
  const rangeDateMax = () => {
    const date = new Date();
    const year = date.setFullYear(date.getFullYear()-99);
    return new Date(year);
  }

  const disabledState = () => {
      setDisabled(true);
  };

  const disabledStateFalse = () => {
      setDisabled(false);
  };

  return (
    <EventDayWrapper>
      <Title title={titleText} />
      <SubTitle
        style={{ margin: '20px' }}
        text='Enter your date of birth to make the calculation more accurate'
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DatePick
          control={control}
          name='date'
          rangeDateMin={rangeDateMin}
          rangeDateMax={rangeDateMax}
          disabledState={() => disabledState()}  
          disabledStateFalse={() => disabledStateFalse()}
          disabledFunc
        />
        {disabled ? <ErrorMessage>Your age should be between 18 and 99</ErrorMessage> : null}
        <Button name='Continue' minWidth='100%' margin='340px 0px 40px 0px' type='submit' isDisabled={isDisabled} />
      </form>
    </EventDayWrapper>
  );
};
