import { Title } from '@QuizComponents/Title';
import { InfoSafetyBlock } from '@QuizComponents/InfoSafetyBlock';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormContainer,
  FormContentWrapper,
  FormWrapper,
  GoalGraphSubText,
  GoalGraphSubTitle,
  GoalGraphTitle,
  PaymentDescription,
  SecureWrapper,
  ImportantSalesTerms
} from './styled';
import BenefitsPlan from './components/BenefitsPlan';
import { HeaderFormV2 } from './components/HeaderForm';
import Form from './components/Form';
import { Secure } from '@Components/Icons';
import StripeContent from './components/StripeContent';
import TrustContent from './components/TrustContent';
import Footer from './components/Footer';
import React, { useEffect } from 'react';
import { setTimer } from '@Store/components/buyNow/actions';
import { createTime } from '@Utilities/createTime';
import { DiscountBlock } from './components/DiscountBlock';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';
import { LogoBlock } from '@QuizComponents/LogoBlock';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { gaEventAnalytics } from '@Utilities/gaEventAnalytics';
import  PaymentMethodsContent from '@PaymentMethodsContent';
import { FBEvent } from '@Analytics/facebookPixel';
import { AnaliticsGoalGraph } from '@QuizComponents/AnaliticsGoalGraph';
import { pinterestEvent } from '@Analytics/pinterest';
import { NutritionBlock } from './components/NutritionBlock';

export const SecurePaymentV2 = () => {
  const { progress, quiz } = useSelector((store) => store);
  const { timer } = progress || {};
  const { paymentPlan, paidTrial } = quiz || {};

  const isExpiredDiscount = 'end' === timer;
  const discountPrice = paymentPlan?.discount?.price ?? '';
  const defaultPrice = paymentPlan?.default?.price ?? '';
  const price = !isExpiredDiscount ? discountPrice : defaultPrice;
  const paidTrialPrice = paidTrial?.price ?? '';

  const current = quiz?.['your-weight']?.measure ?? '';
  const weightResult =  quiz?.['your-desired-weight']?.weight ?? '';
  const measure = 'imperial' === current? 'lbs' : 'kg';

  const paymentDescription = `By clicking button above you agree to the <a href="https://lasta.app/terms-of-use/" target="_blank">terms and conditions</a> and <a href="https://lasta.app/privacy-policy/" target="_blank">privacy policy</a> of this offer. Automatic renewal after 7 days at $${price} every 30 days until cancellation.`;
  const salesTermsDescription = `IMPORTANT SALES TERMS<br><br>Our Continuous Service program is designed to avoid interruption in service by guaranteeing that subscribers do not miss any content. Continuous Service Program: I understand that unless I tell you otherwise, I will receive uninterrupted service and access; my subscription will be automatically renewed at the end of each subscription term, at the rate then in effect. I authorize you to fulfill my subscription and charge the credit/debit card if provided, or send me a bill if not. I won't be bothered with any renewal notices. I may opt out of the automatic renewal at any time by contacting customer service who’s contact information is as follows: <a href="mailto:billing@lasta.app" target="_blank">Billing@lasta.app.</a> I understand and acknowledge that in addition to receiving access to my digital “Fasting plan” for which I agree to pay the fees of $${paidTrialPrice}, I am additionally consenting to be signed up for a subscription to <a href="https://apps.apple.com/ua/app/lasta-healthy-weight-loss/id1581590686" target="_blank">Lasta: Healthy Weight Loss</a>. The first seven days of the trial cost $${paidTrialPrice}. Unless I cancel my subscription within the first seven days of my subscription, I agree and consent to be billed at the end of the promo trial in the amount of $${price} for the initial period of access as well as every 30 days thereafter on the credit card provided, in order to continue having access, unless I cancel the service prior to the next billing.`;

  const dispatch = useDispatch();

  useEffect(() => {
    if (null === timer && 'end' !== timer) {
      dispatch(setTimer({ timer: +createTime(600) }));
    }
  }, [timer]);

  useEffect(() => {
    gaEventAnalytics('fast_add2cart_checkout');
    sendAmplitudeData('Buy_checkout');
    scrollPageToTop();
    FBEvent('InitiateCheckout');
    pinterestEvent('AddToCart');
  }, []);

  return (
    <SecureWrapper>
      <LogoBlock />
      <GoalGraphTitle>Your plan is ready 🎉</GoalGraphTitle>
      <GoalGraphSubTitle>
        We’re here to help you reach your goal of <GoalGraphSubText>{weightResult} {measure}</GoalGraphSubText>
      </GoalGraphSubTitle>
      <AnaliticsGoalGraph
        isForecast={true}
        isShowBottomValue={false}
        valueTop={'10px'}
        valueLeft={'0px'}
      />
      <DiscountBlock isExpiredDiscount={isExpiredDiscount} timer={timer} paymentPlan={paymentPlan} />
      <BenefitsPlan />
      <FormContainer id={'payment'}>
        <Title title='Payment information' styleProps={{ minWidth: '100%', marginTop: '00 24px', fontSize: '20px' }} />
        <PaymentMethodsContent/>
        <FormWrapper>
          <HeaderFormV2 timer={timer} paymentPlan={paymentPlan} isExpiredDiscount={isExpiredDiscount} />
        </FormWrapper>
        <FormContentWrapper>
          <Form/>
          <PaymentDescription dangerouslySetInnerHTML={{ __html: paymentDescription }}/>
          <Secure />
          <StripeContent stripeLogoSize='big' />
        </FormContentWrapper>
      </FormContainer>
      <InfoSafetyBlock styleProps={{ margin: '60px 0' }} />
      <NutritionBlock />
      <TrustContent />
      <ImportantSalesTerms dangerouslySetInnerHTML={{ __html: salesTermsDescription }}/>
      <Footer />
    </SecureWrapper>
  );
};
