import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { YourWeightAndHeight } from './components/YourWeightAndHeight';
import { YourDesiredWeight } from './components/YourDesiredWeight';
import './styles.scss';
import { CravingCertainFoods } from './components/CravingCertainFoods';
import { YourBodyType } from './components/YourBodyType';
import { getEventProperty } from '@Utilities/getEventProperty';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { TypicalLunch } from './components/TypicalLunch';
import { LifeStyle } from './components/LifeStyle';
import { Metabolism } from './components/Metabolism';
import { ImportantEvent } from './components/ImportantEvent';
import { YourBodyShape } from './components/YourBodyShape';
import { BirthDate } from './components/BirthDate';
import { Height } from './components/Height';
import { EventDay } from './components/EventDay';

export const Step3 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { gender } = useSelector(state => state.quiz) || {};

  const checkAndReturnNextPath = (name) => {
    let path = '';
    let eventUrl = '';

    switch (name) {
      case 'typical-lunch' :
        path = 'metabolism';
        eventUrl = 'typical_lunch';
        break;
      case 'metabolism' :
        eventUrl = 'metabolism';
        path = 'your_weight';
        break;
      case 'lifestyle' :
        eventUrl = 'lifestyle';
        path = 'important_event';
        break;
      case 'craving-certain-foods' :
        eventUrl = 'craving_certain_foods';
        path = 'male' === gender ? 'your_body_type' : 'your_body_shape';
        break;
      case 'your-body-shape' :
        eventUrl = 'your_body_shape';
        path = '/quiz/buy_now/forecast';
        break;
    }
    return { path, eventUrl };
  };

  const sendEventWithProperty = (path, value) => {
    let eventProperty = {};
    switch (path) {
      case 'typical_lunch':
        eventProperty = getEventProperty('Step_3_2_typical_lunch', 'lunch_type', value);
        break;
      case 'metabolism':
        eventProperty = getEventProperty('Step_3_3_Metabolism','How_many_meals', value);
        break;
      case 'lifestyle':
        eventProperty = getEventProperty('Step_3_7_Lifestyle_click', 'LifeStyle', value);
        break;
      case 'craving_certain_foods':
        eventProperty = getEventProperty('Step_3_10_crave', 'food_type', value);
        break;
      case 'your_body_shape':
        eventProperty = getEventProperty('Step_3_11_bType_click', 'Body_shape', value);
        break;
    }
    sendAmplitudeData(eventProperty.eventName, {
      [eventProperty.name]: eventProperty.value,
    });
  };

  const handleRadioButton = (event) => {
    const { name, value } = event.target;

    dispatch(
      updateQuiz({
        name,
        value,
      })
    );

    const path = checkAndReturnNextPath(name);
    sendEventWithProperty(path.eventUrl, value);

    if (path) {
      setTimeout(() => {
        history.push(path.path);
      }, 300);
    }
  };

  const handleInput = (data) => {
    const { name, value } = data;

    dispatch(
      updateQuiz({
        name,
        value,
      })
    );
  };

  return (
    <div className='quiz-step3'>
      <Switch>
        <Route exact path='/quiz/steps/birthDate'>
          <BirthDate subStepName='birthDate' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/quiz/steps/typical_lunch'>
          <TypicalLunch subStepName='typical-lunch' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/quiz/steps/metabolism'>
          <Metabolism subStepName='metabolism' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/quiz/steps/your_weight'>
          <YourWeightAndHeight subStepName='your-weight' handleInput={handleInput} />
        </Route>
        <Route exact path='/quiz/steps/your_desired_weight'>
          <YourDesiredWeight subStepName='your-desired-weight' handleInput={handleInput} />
        </Route>
        <Route exact path='/quiz/steps/your_height'>
          <Height subStepName='your-height' handleInput={handleInput} />
        </Route>
        <Route exact path='/quiz/steps/lifestyle'>
          <LifeStyle subStepName='lifestyle' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/quiz/steps/important_event'>
          <ImportantEvent subStepName='important-event' handleInput={handleInput} />
        </Route>
        <Route exact path='/quiz/steps/event_day'>
          <EventDay subStepName='event-day' handleInput={handleInput} />
        </Route>
        <Route exact path='/quiz/steps/craving_certain_foods'>
          <CravingCertainFoods subStepName='craving-certain-foods' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/quiz/steps/your_body_type'>
          <YourBodyType subStepName='your-body-type' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/quiz/steps/your_body_shape'>
          <YourBodyShape subStepName='your-body-shape' handleRadioButton={handleRadioButton} />
        </Route>
      </Switch>
    </div>
  );
};
