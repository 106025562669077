import { RadioButton } from '@Components/RadioButton';
import { Title } from '@QuizComponents/Title';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/QuizV2';
import { SubTitle } from '@QuizComponents//SubTitle';
import { radioButtonsData } from './radioButtonsData';

export const CookingV2 = ({ subStepName, handleRadioButton }) => {
  const quiz = useSelector((store) => store.quiz);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step1',
        currentStep: 6,
        totalSteps: 8,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step1');
  }, []);
  return (
    <>
      <Title title="How much time can you spend cooking?" />
      <SubTitle text="Select a response that relates to one  meal." style={{ marginBottom: '20px' }} />
      {radioButtonsData.map(({ text, value }) => {
        return (
          <RadioButton
            key={value}
            id={value}
            text={text}
            name={subStepName}
            checked={quiz['timeForCooking'] === value}
            onClick={handleRadioButton}
            value={value}
          />
        );
      })}
    </>
  );
};
