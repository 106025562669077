const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const getMonthAndDay = (date, month, day, id) => {
  if ('1' === id) {
    const monthDate = month;
    const dayDate = day;

    return {
      dayDate,
      monthDate,
    };
  } else {
    const lastDayOfPrevMonth = new Date(date.getFullYear(), month, 0).getDate();
    const monthDate = day - 10 <= 0 ? month - 1 : month;
    const dayDate = day - 10 <= 0 ? lastDayOfPrevMonth + day - 10 : day - 10;
    return { dayDate, monthDate };
  }
};

export const getMonthForProgram = (current_weight, desired_weight, id) => {
  const neededMonth = (Number(current_weight) - Number(desired_weight)) / 10;

  const needMonth = neededMonth > 1 ? neededMonth : 1
  const date = new Date();
  const { monthDate, dayDate } = getMonthAndDay(date, Math.floor(date.getMonth() + needMonth), date.getDate(), id);

  if (monthDate > 11) {
    const resultMonth = monthDate - (date.getMonth() + 1 ) > 12 ? monthDate - (date.getMonth() + 1) - 12 : monthDate - (date.getMonth() + 1);
    return {
      month: monthNames[resultMonth],
      year: date.getFullYear() + 1,
      day: dayDate,
    };
  }

  return {
    month: monthNames[monthDate],
    year: date.getFullYear(),
    day: dayDate,
  };
};
