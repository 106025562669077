import { Title } from '@QuizComponents/Title';
import { ButtonWrapper, HeaderResultsContent, HeaderResultsImage, HeaderResultsWrapper } from './styled';
import { Button } from '@Components/Button';
import { useIsScrolledToElement } from '@CustomHooks/useIsScrolledToElement';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { useSelector } from 'react-redux';

export const HeaderResults = () => {
  const HISTORY = useHistory();
  const isScrollToButton = useIsScrolledToElement(505);

  useEffect(() => {
    scrollPageToTop();
  }, []);

  const isV2 = useSelector((state) => state.appVersion);
  const link = isV2 ? '/v2' : '';

  const onButtonClick = () => {
    sendAmplitudeData('f_quiz_results_geMyPlan_button_click');
    HISTORY.push(`${link}/quiz/buy_now/checkout`);
  };
  return (
    <HeaderResultsWrapper>
      <HeaderResultsContent>
        <Title title="Claim your personalized plan" styleProps={{ fontSize: '34px', lineHeight: '38px', textAlign: 'left' }} />
        <HeaderResultsImage src={`/images/results/plate.png`} srcSet={`/images/results/plate.png 320w, /images/results/plate@2x.png 480w`} />
      </HeaderResultsContent>
      <ButtonWrapper isScrollToButton={isScrollToButton}>
        <Button
          name="Get my plan"
          style={{
            borderRadius: isScrollToButton ? 0 : '12px',
            display: 'block',
            maxWidth: isScrollToButton ? '100%' : '460px',
            margin: 'auto',
          }}
          handleClick={onButtonClick}
        />
      </ButtonWrapper>
    </HeaderResultsWrapper>
  );
};
