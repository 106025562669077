import { Route, Switch } from 'react-router-dom';
import { RestrictiveDietFail } from './components/RestrictiveDietFail';
import { HealthAdvice } from './components/HealthAdvice';
import { YourPersonalPlan } from './components/YourPersonalPlan';
import { SecurePayment } from './components/SecurePayment';
import { useEffect, useRef, useState } from 'react';
import { ThankYouPage } from './components/ThankYouPage';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { WhatsYourEmail } from './components/SecurePayment/components/WhatsYourEmail';
import { SubTitle } from '@QuizComponents/SubTitle';
import { Title } from '@QuizComponents/Title';
import { InfoBlock } from '@QuizComponents/InfoBlock';
import { Header } from '@QuizComponents/Header';
import { Results } from './components/Results';
import { LoadingContent } from './components/LoadingContent';
import { Forecast } from './components/Forecast';
import { UpSell } from './components/UpSell';

export const BuyNow = () => {
  const [paymentPlanInfo, setPaymentPlanInfo] = useState(null);
  const [email, setEmail] = useState('');
  const { quiz } = useSelector((store) => store);
  const { paidTrial } = quiz ?? {};

  const dispatch = useDispatch();
  useEffect(() => {
    if (paymentPlanInfo) {
      dispatch(
        updateQuiz({
          name: 'paymentPlan',
          value: {
            discount: paymentPlanInfo.prices[0],
            default: paymentPlanInfo.prices[1],
          },
        })
      );
    }
  }, [paymentPlanInfo]);

  useEffect(() => {
    if (email) {
      dispatch(
        updateQuiz({
          name: 'your-email',
          value: {
            email,
          },
        })
      );
    }
  }, [email]);

  useEffect(() => {
    if (!paidTrial) {
      dispatch(
        updateQuiz({
          name: 'paidTrial',
          value: {
            price: '16.41',
            id: '3'
          },
        })
      );
    }
  }, [paidTrial]);

  return (
    <div className="buy-now">
      <Switch>
        <Route path="/quiz/buy_now/forecast">
          <Forecast />
        </Route>
        <Route path="/quiz/buy_now/restricted_diet_fail">
          <RestrictiveDietFail />
        </Route>
        <Route path="/quiz/buy_now/health_advice">
          <HealthAdvice />
        </Route>
        <Route path="/quiz/buy_now/your_email">
          <Header isShowPreviousBtn={false} isShowNumberQuestion={false} />
          <Title title="What’s your email?" styleProps={{ minWidth: '100%', marginBottom: '8px' }} />
          <SubTitle text="We’ll send your plan for safety fasting and to save your progress." style={{ marginBottom: '24px' }} />
          <WhatsYourEmail setPaymentPlanInfo={setPaymentPlanInfo} setEmail={setEmail} />
          <InfoBlock
            text="We take our customer's privacy seriously and never share any personal information with third parties."
            iconType="safeCheck"
            fontSize="11px"
            lineHeight="14px"
            style={{ margin: '60px 0 20px' }}
          />
        </Route>
        <Route path="/quiz/buy_now/results">
          <Results />
        </Route>
        <Route path="/quiz/buy_now/loading">
          <LoadingContent />
        </Route>
        <Route path="/quiz/buy_now/checkout">
          <SecurePayment />
        </Route>
        <Route path="/quiz/buy_now/thank_you_page">
          <ThankYouPage />
        </Route>
        <Route exact path="/quiz/buy_now/upsell/:id">
          <UpSell />
        </Route>
      </Switch>
    </div>
  );
};
