// Core
import { takeEvery, all, call } from 'redux-saga/effects';

// Types
import { GET_PAYMENT_PLAN } from '../types';

// Workers
import { worker } from './workers';

function* watchWorker() {
  yield takeEvery(GET_PAYMENT_PLAN, worker);
}

export function* watchPaymentPlan() {
  yield all([call(watchWorker)]);
}
