import { Title } from '@QuizComponents/Title';
import './styles.scss';
import { Button } from '@Components/Button';
import { useForm } from 'react-hook-form';
import { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { InputComponent } from '@Components/InputComponent';
import { getMessageError } from './inputValidate';
import { MeasureWrapper } from '../../styled';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';

export const YourWeightAndHeight = ({ handleInput }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const quiz = useSelector((store) => store.quiz);
  const measure = quiz['your-weight']  && quiz['your-weight'].measure;
  const [formMeasure, setFormMeasure] = useState(measure || 'imperial');
  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;
  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 4,
        totalSteps: 11,
      })
    );

    setCurrentStepName('step3');
  }, []);

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      weight: quiz['your-weight'] ? quiz['your-weight'].weight : null,
    },
  });

  const onSubmit = (event) => {
    const { weight } = event;
    let result;
    if (weight) {
     result = '0' === weight.split('')[0] ? weight.split('').slice(1).join('') : weight;
    }
    const data = {
      name: 'your-weight',
      value: {
        weight: result,
        measure: formMeasure
      },
    };

    sendAmplitudeData('Step_3_4_weight.current', {
      current_weight: result,
    });
    handleInput(data);

    dispatch(
      updateQuiz({
        name: 'measure',
        value: formMeasure
      })
    );

    if (isValid) {
      setTimeout(() => {
        history.push('your_desired_weight');
      }, 300);
    }
  };

  const onChangeWeight = (field, value) => {
    const resultValue = value.replace(/[^0-9]/g, '').substr(0, 3);
    field.onChange(resultValue);
  };

  const getMeasureMarkup = (measure) => {
    return <MeasureWrapper>{measure}</MeasureWrapper>;
  };

  return (
    <div className='your-weight-and-height'>
      <Title title={'What\'s your current weight?'} />
      <div className='your-weight'>
        <div
          className={'imperial' === formMeasure ? 'your-weight__item-active' : 'your-weight__item'}
          onClick={() => setFormMeasure('imperial')}
        >
          lbs
        </div>
        <div
          className={'metric' === formMeasure ? 'your-weight__item-active' : 'your-weight__item'}
          onClick={() => setFormMeasure('metric')}
        >
          kg
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '20px' }}>
        <InputComponent
          name='weight'
          control={control}
          label='Your weight'
          type='text'
          placeholder='Enter your current weight'
          rules={getMessageError('metric' === formMeasure ? 'weight kg' : 'weight lbs')}
          padding='0 45px 0 16px'
          onChange={onChangeWeight}
          errorMessage={errors['weight']}
          icon={getMeasureMarkup('metric' === formMeasure ? 'kg' : 'lbs')}
        />
        <Button name='Continue' minWidth='100%' margin='30px 0 0' type='submit' isDisabled={!isValid} />
      </form>
    </div>
  );
};
