import './styles.scss';

export const ProgressBar = ({ occupancyWidth }) => {
  return (
    <div className="progress-bar">
      <p
        className="occupancy-progress"
        style={{
          height: 5,
          backgroundColor: '#93C130',
          position: 'absolute',
          maxWidth: `${occupancyWidth}%`,
          width: '100%',
          transition: 'all .6s ease-out',
        }}
      />
    </div>
  );
};
