import { START_FETCHING, STOP_FETCHING, SET_ERROR, CLEAR_ERROR } from './types';

export const startFetching = () => {
  return {
    type: START_FETCHING,
  };
};

export const stopFetching = () => {
  return {
    type: STOP_FETCHING,
  };
};

export const setError = (payload) => {
  return {
    type: SET_ERROR,
    payload,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};
