import { Title } from '@QuizComponents/Title';
import { SubTitle } from '@QuizComponents/SubTitle';
import { RadioButton } from '@Components/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import { useContext, useEffect } from 'react';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { gaEventAnalytics } from '@Utilities/gaEventAnalytics';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';
import { radioButtonsData } from './radioButtonsData';

export const CravingCertainFoods = ({ handleRadioButton, subStepName }) => {
  const dispatch = useDispatch();
  const quiz = useSelector((store) => store.quiz);

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 10,
        totalSteps: 11,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step3');
    gaEventAnalytics('fast_measures_complete');
    scrollPageToTop();
  }, []);

  return (
    <div className='craving-certain-foods'>
      <Title title='Do you get cravings for certain types of foods?' />
      <SubTitle text='Cravings play a critical role in our approach to matching your metabolic type and understanding what you’re body is craving.' />
      {radioButtonsData.map(({ id, value }) => {
        return (
          <RadioButton
            key={id}
            id={id}
            text={value}
            name={subStepName}
            checked={quiz['craving-certain-foods'] === value}
            onClick={handleRadioButton}
            value={value}
          />
        );
      })}
    </div>
  );
};
