export const quizProgressBarData = [
  {
    stepName: 'step4',
    currentStep: 1,
    totalSteps: 6,
  },
  {
    stepName: 'step3',
    currentStep: 9,
    totalSteps: 9,
  },
  {
    stepName: 'step2',
    currentStep: 5,
    totalSteps: 5,
  },
  {
    stepName: 'step1',
    currentStep: 5,
    totalSteps: 5,
  },
];
