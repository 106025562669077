// Core
import { takeEvery, all, call } from 'redux-saga/effects';

// Types
import { SET_GOAL_GRAPH, SECURITY_PAYMENT } from '../types';

// Workers
import { goalGraphWorker, worker } from './workers';

function* watchWorker() {
  yield takeEvery(SECURITY_PAYMENT, worker);
}

function* watchGoalGraphWorker() {
  yield takeEvery(SET_GOAL_GRAPH, goalGraphWorker);
}

export function* watchSecurityPayment() {
  yield all([call(watchWorker), call(watchGoalGraphWorker)]);
}
