import { StripeContentLogo, StripeContentText, StripeContentWrapper } from './styled';
import { StripeLogoBig, StripeLogoSmall } from '@Components/Icons';

const StripeContent = ({ stripeLogoSize = 'small' }) => {
  return (
    <StripeContentWrapper id="stripe-content">
      <StripeContentText>Powered by</StripeContentText>
      <StripeContentLogo stripeLogoSize={stripeLogoSize}>{stripeLogoSize === 'small' ? <StripeLogoSmall /> : <StripeLogoBig />}</StripeContentLogo>
    </StripeContentWrapper>
  );
};

export default StripeContent;
