import React from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { Button } from '@Components/Button';
import { sendAmplitudeData } from '@Analytics/amplitude';

export const IntercomChat = () => {
  const INTERCOM_APP_ID = 'dnsu175l';
  return (
    <>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <ButtonPage />
      </IntercomProvider>
    </>
  );
};

const ButtonPage = () => {
  const { showNewMessages } = useIntercom();
  return (
    <>
      <Button
        name="Let's ask a Nutritionist"
        minWidth="228px"
        onClick={() => {
          sendAmplitudeData('f_chat_ask_specialist_click');
          showNewMessages();
        }}
      />
    </>
  );
};
