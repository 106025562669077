export const radioButtonsData = [
  {
    value: 'Anorexia'
  },
  {
    value: 'Bulimia'
  },
  {
    value: 'Diabetes'
  },
  {
    value: 'High blood pressure'
  },
  {
    value: 'High cholesterol'
  },
  {
    value: 'Mental health issues'
  },
  {
    value: 'Other'
  },
  {
    value: 'I don’t have any medical conditions'
  }
];
