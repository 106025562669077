export const radioButtonsData = [
  {
    value: 'I almost always eat three or fewer meals per day'
  },
  {
    value: 'I almost always eat at least three meals, plus several snacks a day'
  },
  {
    value: 'It depends — sometimes less than three meals, sometimes more'
  }
];
