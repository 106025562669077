export const radioButtonsData = [
  {
    text: 'Vegetarian',
    value: 'vegetarian'
  },
  {
    text: 'Vegan',
    value: 'vegan'
  },
  {
    text: 'Keto',
    value: 'keto'
  },
  {
    text: 'Paleo',
    value: 'paleo'
  },
  {
    text: 'Dash',
    value: 'dash'
  },
  {
    text: 'None of above',
    value: 'none'
  }
];
