export const radioButtonsData = {
  top: [
    {
      id: '1',
      value: `Inverted triangle`,
      picture: {
        src: '/images/quiz/yourBodyShape/shape1.png',
        srcSet: '/images/quiz/yourBodyShape/shape1.png 320w, /images/quiz/yourBodyShape/shape1@2x.png 480w',
        alt: 'body shape picture',
      },
    },
    {
      id: '2',
      value: `Hourglass`,
      picture: {
        src: '/images/quiz/yourBodyShape/shape2.png',
        srcSet: '/images/quiz/yourBodyShape/shape2.png 320w, /images/quiz/yourBodyShape/shape2@2x.png 480w',
        alt: 'body shape picture',
      },
    },
    {
      id: '3',
      value: `Rounded`,
      picture: {
        src: '/images/quiz/yourBodyShape/shape3.png',
        srcSet: '/images/quiz/yourBodyShape/shape3.png 320w, /images/quiz/yourBodyShape/shape3@2x.png 480w',
        alt: 'body shape picture',
      },
    },
  ],
  bottom: [
    {
      id: '4',
      value: `Rectangle`,
      picture: {
        src: '/images/quiz/yourBodyShape/shape4.png',
        srcSet: '/images/quiz/yourBodyShape/shape4.png 320w, /images/quiz/yourBodyShape/shape4@2x.png 480w',
        alt: 'body shape picture',
      },
    },
    {
      id: '5',
      value: `Triangle`,
      picture: {
        src: '/images/quiz/yourBodyShape/shape5.png',
        srcSet: '/images/quiz/yourBodyShape/shape5.png 320w, /images/quiz/yourBodyShape/shape5@2x.png 480w',
        alt: 'body shape picture',
      },
    },
    {
      id: '6',
      value: `empty`,
      picture: {
        src: 'empty',
        srcSet: 'empty',
        alt: 'empty',
      },
    },
  ],
};
