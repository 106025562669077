import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const EventDayWrapper = styled.div``;

export const StyledLink = styled(Link)`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  text-decoration: none;
  z-index: 9999;
`;

export const ErrorMessage = styled.p`
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  color: #FF6A6B;
  margin-top: 15px;
`;
