import { Main, MainV2 } from '@Pages/Main';
import { TermsOrPrivacy } from '@Pages/TermsOrPrivacy';
import { Quiz, QuizV2 } from '@Pages/Quiz';
import { Post } from '@Pages/Post';

const TermsHOC = (Component) => (props) => <Component {...props} isTerms={true} />;

const TermsOrUse = TermsHOC(TermsOrPrivacy);

const PrivacyHOC = (Component) => (props) => <Component {...props} isTerms={false} />;

const PrivacyPolicy = PrivacyHOC(TermsOrPrivacy);

const routesV2 = [
  {
    exact: true,
    path: '/v2',
    component: MainV2,
  },
  {
    exact: false,
    path: '/v2/quiz',
    component: QuizV2,
  },
];

export const routes = [
  ...routesV2,
  {
    exact: true,
    path: '/',
    component: Main,
  },
  {
    exact: true,
    path: '/terms-or-use',
    component: TermsOrUse,
  },
  {
    exact: true,
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    exact: false,
    path: '/quiz',
    component: Quiz,
  },
  {
    exact: false,
    path: '/post',
    component: Post,
  },
];
