import { RadioButton } from '@Components/RadioButton';
import { Title } from '@QuizComponents/Title';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { SubTitle } from '@QuizComponents//SubTitle';

export const HighCarb = ({ subStepName, handleRadioButton }) => {
  const quiz = useSelector((store) => store.quiz);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step1',
        currentStep: 2,
        totalSteps: 8,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step1');
  }, []);
  return (
    <>
      <Title title="High-carb food" />
      <SubTitle text="How often do you eat high-carb foods, like bread, dairy, pasta, etc.?" style={{ marginBottom: '20px' }} />
      <RadioButton
        id="Very often"
        text="Very often"
        onClick={handleRadioButton}
        name={subStepName}
        checked={quiz['high-carb'] === 'Very often'}
        value="Very often"
      />
      <RadioButton
        id="Sometimes"
        text="Sometimes"
        onClick={handleRadioButton}
        name={subStepName}
        checked={quiz['high-carb'] === 'Sometimes'}
        value="Sometimes"
      />
      <RadioButton id="Rarely" text="Rarely" onClick={handleRadioButton} name={subStepName} checked={quiz['high-carb'] === 'Rarely'} value="Rarely" />
      <RadioButton
        id="Not at all"
        text="Not at all"
        onClick={handleRadioButton}
        name={subStepName}
        checked={quiz['high-carb'] === 'Not at all'}
        value="Not at all"
      />
    </>
  );
};
