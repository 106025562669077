const checkRules = (value, inputName) => {
  const errorMassege = 'Please enter a value between 50 and 250';
  const errorMassegeLbs = 'Please enter a value between 110 and 550';
  const weight = Number(value);
  switch (inputName) {
    case 'weight lbs':
      if (weight < 110 || weight > 550) {
        return errorMassegeLbs;
      }
      break;
    case 'weight kg':
      if (weight < 50 || weight > 250) {
        return errorMassege;
      }
      break;
  }
};

export const getMessageError = (inputName) => {
  return {
    validate: (value) => {
      return checkRules(value, inputName);
    },
    required: 'This field is required.',
  };
};
