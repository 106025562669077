import { ModalContent, ModalWrapper } from './styled';

export const Modal = ({ children, ...props }) => {
  const IS_SHOW = props.isShow;

  if (!IS_SHOW) {
    return null;
  }
  return (
    <ModalWrapper>
      <ModalContent {...props}>{children}</ModalContent>
    </ModalWrapper>
  );
};
