export const radioButtonsData = [
  {
    value: 'Pretty good',
  },
  {
    value: 'Restless',
  },
  {
    value: 'Difficulty falling asleep'
  },
];
