import { select, put } from 'redux-saga/effects';
import { setGoalGraphData } from '@Store/components/buyNow/actions';

const getWeight = (current, desired, kg, step) => {
  const value = (current - desired) / 5;
  const valueKg = value + kg;
  return current - valueKg * 2;
};

let data = [
  {
    name: 'a',
    uv: 0,
    dotColor: '#EA5556',
    bottom: 0,
  },
  {
    name: 'b',
    uv: 0,
    dotColor: '#CAAC1E',
    bottom: 0,
  },
  {
    name: 'c',
    uv: 0,
    dotColor: '#C6C427',
    bottom: 0,
  },
  {
    name: 'd',
    uv: 0,
    dotColor: '#B4C958',
    bottom: 0,
  },
  {
    name: 'e',
    uv: 0,
    dotColor: '#8CBF38',
    bottom: 0,
  },
];

export function* goalGraphWorker() {
  const state = yield select();
  const weightResultDesired = Number(state.quiz['your-desired-weight'].weight)
  const weightResultCurrent = Number(state.quiz['your-weight'].weight)

  data = data.map((el, index) => {
    const { name } = el;
    if (name === 'a') {
      return { ...el, uv: weightResultCurrent, bottom: weightResultCurrent };
    } else if (name === 'b') {
      return { ...el, uv: getWeight(weightResultCurrent, weightResultDesired, 0, 2) - 50, bottom: getWeight(weightResultCurrent, weightResultDesired, 0, 2) - 50 };
    } else if (name === 'c') {
      return { ...el, uv: getWeight(weightResultCurrent, weightResultDesired, 2.5, 3) - 50, bottom: getWeight(weightResultCurrent, weightResultDesired, 2.5, 3) - 50 };
    } else if (name === 'd') {
      return { ...el, uv: weightResultDesired - 50, bottom: weightResultDesired - 50 };
    } else if (name === 'e') {
      return { ...el, uv: weightResultDesired - 50, bottom: weightResultDesired - 50 };
    }
    {
      return el;
    }
  });
  yield put(setGoalGraphData(data));
}
