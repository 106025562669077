import styled from '@emotion/styled';

export const PersonalPlanResultWrapper = styled.section`
  margin-bottom: 70px;
`;

export const PersonalPlanResultContainer = styled.section`
  background-color: #fff;
  border: 1px solid #ebeef1;
  border-radius: 20px;
  padding: 36px 18px;
  position: relative;
`;

export const PersonalPlanResultHeader = styled.img`
  max-width: 50px;
  display: block;
  margin: auto;
`;

export const PersonalPlanResultContent = styled.div``;

export const PersonalPlanResultCount = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const PersonalPlanResultCountValue = styled.p`
  font-size: 96px;
  line-height: 96px;
  color: #ea5556;
`;

export const PersonalPlanResultCountPercentage = styled.p`
  color: #ea5556;
  font-size: 24px;
  line-height: 24px;
`;

export const PersonalPlanResultText = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: 17px;
`;

export const PersonalPlanResultTextStrong = styled.span`
  font-weight: 700;
`;

export const PersonalPlanResultInfo = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ebeef1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 13px;
  color: #999eaf;
  font-weight: 700;
  position: absolute;
  top: 36px;
  right: 18px;
`;
