import {
  WaterCountLitersWater,
  WaterCupContainer,
  WaterCupItem,
  WaterCupWrapper,
  WaterHeader,
  WaterSubtitle,
  WaterWrapper
} from './styled';
import { Title } from '@QuizComponents/Title';
import { Cup } from '@Components/Icons';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const Water = () => {
  const personalSummery = useSelector((store) => store.quiz['personalSummary']);
  const measure = useSelector((store) => store.quiz['measure']);
  const waterInOunce = personalSummery?.water_minimum;
  const [firstPart, setFirstPart] = useState(null);
  const [secondPart, setSecondPart] = useState(null);

  const getCupsContent = () => {
    return (
      <WaterCupWrapper>
        <WaterCupContainer>
          {firstPart?.map(({ id, fill, stroke }) => (
            <WaterCupItem key={id}>
              <Cup fill={fill} stroke={stroke} />
            </WaterCupItem>
          ))}
        </WaterCupContainer>
        <WaterCupContainer>
          {secondPart?.map(({ id, fill, stroke }) => (
            <WaterCupItem key={id}>
              <Cup fill={fill} stroke={stroke} />
            </WaterCupItem>
          ))}
        </WaterCupContainer>
      </WaterCupWrapper>
    );
  };

  const ounce = 33.814;
  const water = Number(waterInOunce);
  const waterInLiters = 'metric' === measure ? water : Number((waterInOunce / ounce).toFixed(1));

  useEffect(() => {
    const waterToMilliliters = waterInLiters * 1000;
    const countFullCup = waterToMilliliters / 200;
    let initialWaterCupData = [];

    for (let i = 1; i < 15; i++) {
      initialWaterCupData.push({
        id: i,
        fill: '#D9EBFD',
        stroke: '#9EBBF1',
      });
    }

    initialWaterCupData = initialWaterCupData.map((el, index) => {
      if (countFullCup <= index) {
        return {
          id: el.id,
          fill: '#F0F7FE',
          stroke: '#D8E4F9',
        };
      } else {
        return el;
      }
    });

    const firstPart = initialWaterCupData.slice(0, 7);
    setFirstPart(firstPart);
    const secondPart = initialWaterCupData.slice(7, 14);
    setSecondPart(secondPart);
  }, [waterInLiters]);

  return (
    <WaterWrapper>
      <WaterHeader>
        <Title title="Water" styleProps={{ padding: 0, margin: 0, textAlign: 'left' }} />
        <WaterCountLitersWater>{waterInLiters} L</WaterCountLitersWater>
      </WaterHeader>
      <WaterSubtitle>Recommended water</WaterSubtitle>
      {getCupsContent()}
    </WaterWrapper>
  );
};
