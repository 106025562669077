import React, { useState, useEffect } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { WrapperCartButton, Logo } from '@PaymentMethodsContent/styled';
import { subscribeApplePay } from '@Utilities/api';
import { errorNotification } from '@Utilities/notification';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { googleAnaliticsStorage } from '@Analytics/ga';
import { setEmailData } from  '@Store/components/buyNow/actions'
import Cookies from 'js-cookie';
import config from '@/config';

export const ApplePay = ({ handleClick, isToggled }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { quiz, progress, appVersion: isV2 } = useSelector((store) => store);
  const { timer } = progress ?? '';
  const { paymentPlan, paidTrial } = quiz || {};
  const elements = useElements();
  const isDiscountTime = 'end' !== timer;
  const { id: paidTrialId, price: paidTrialPrice } = paidTrial || {};
  const paidTrialPlanId = isV2 ? config.prices.paidTrial.test[paidTrialId] : config.prices.paidTrial.master[paidTrialId];

  const prices = isDiscountTime ? paymentPlan.discount : paymentPlan.default;
  const { plan_id, slug } = prices;
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);

  const handlePaymentMethodReceived = async (event) => {
    const paymentDetails = {
      paymentMethodId: event.paymentMethod.id,
      planId: plan_id,
      email: event.payerEmail,
      paidTrialPlanId: paidTrialPlanId ?? null
    };
    dispatch(setEmailData({ email: event.payerEmail }));
    await googleAnaliticsStorage(event.payerEmail);
    const response = await subscribeApplePay(paymentDetails);

    if(200 !== response.status) {
      event.complete('fail');
      errorNotification(response.data.message);
      return;
    }

    const { authToken } = response.data.data;
    Cookies.set('token', authToken);

    event.complete('success');
    sendAmplitudeData('f_applePay_sub_started', {
      F_Price: paidTrialPrice
    });
    history.push('upsell/1');
  };

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: slug,
          amount: Number(paidTrialPrice)*100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      pr.canMakePayment().then((result) => {
        if (result && result.applePay) {
          pr.on('paymentmethod', handlePaymentMethodReceived);
          setPaymentRequest(pr);
        }
      }).catch(error => console.log(error));
    }
  }, [stripe, elements, isDiscountTime, paidTrial?.price]);

  const appleButtonClick = () => {
    handleClick();
    if (paymentRequest) {
      paymentRequest.show();
    }
  };

  return(
    <>
      {paymentRequest ? (
        <WrapperCartButton onClick={appleButtonClick} isToggled={isToggled}>
          <Logo src='/images/payment/feedbacks/apple-pay.svg'/>
        </WrapperCartButton>
        ) : null
      }
    </>
  );
};
