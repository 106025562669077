import {
  PersonalPlanResultContainer,
  PersonalPlanResultContent,
  PersonalPlanResultCount,
  PersonalPlanResultCountPercentage,
  PersonalPlanResultCountValue,
  PersonalPlanResultHeader,
  // PersonalPlanResultInfo,
  PersonalPlanResultText,
  PersonalPlanResultTextStrong,
  PersonalPlanResultWrapper,
} from './styled';
import { Title } from '@QuizComponents/Title';

export const PersonalPlanResult = () => {
  return (
    <PersonalPlanResultWrapper>
      <Title title="Long-lasting results with personal plan" styleProps={{ fontSize: '24px', lineHeight: '32px', marginBottom: '14px' }} />
      <PersonalPlanResultContainer>
        <PersonalPlanResultHeader
          src={`/images/results/fire.png`}
          srcSet={`/images/results/fire.png 320w, /images/results/fire@2x.png 480w`}
          alt="score"
        />
        {/*<PersonalPlanResultInfo>i</PersonalPlanResultInfo>*/}
        <PersonalPlanResultContent>
          <PersonalPlanResultCount>
            <PersonalPlanResultCountValue>90</PersonalPlanResultCountValue>
            <PersonalPlanResultCountPercentage>%</PersonalPlanResultCountPercentage>
          </PersonalPlanResultCount>
          <PersonalPlanResultText>
            of people with similar profiles <PersonalPlanResultTextStrong>lost weight</PersonalPlanResultTextStrong> using plans
          </PersonalPlanResultText>
        </PersonalPlanResultContent>
      </PersonalPlanResultContainer>
    </PersonalPlanResultWrapper>
  );
};
