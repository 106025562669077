export const radioButtonsData = [
  {
    text: 'I tend to eat sandwiches or wraps',
    value: 'sandwiches or wraps'
  },
  {
    text: 'I tend to eat soups or salads',
    value: 'soups or salads'
  },
  {
    text: 'I tend to eat fast food',
    value: 'fast food'
  }
];
