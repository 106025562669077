import styled from '@emotion/styled';

export const SecureWrapper = styled.section`
  position: relative;
`;

export const SecureIcon = styled.div`
  text-align: center;
  margin-top: 12px;
  font-size: 28px;
`;

export const FormWrapper = styled.section`
  margin: 30px 0 0;
`;

export const FormContentWrapper = styled.div`
  margin: 24px 0;
  text-align: center;
  section {
    margin-top: 6px;
    justify-content: center;
    align-items: center;
    p {
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

export const FormContainer = styled.div`
  position: relative;
`;

export const FormOverlay = styled.div`
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
`;

export const PaymentDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  margin: 24px 0;
  color: #ACACAC;

  a {
    text-decoration: none;
    color: #FF6A6B;
  }
`;

export const ImportantSalesTerms = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  margin: 24px 0;
  color: #878787;
  
  a {
    text-decoration: none;
    color: #FF6A6B;
  }
`;

export const GoalGraphTitle = styled.p`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
`;

export const GoalGraphSubTitle = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin: 2px 0 30px;
`;

export const GoalGraphSubText = styled.span`
  color: #92c73c;
  font-weight: 700;
`;
