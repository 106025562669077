import styled from '@emotion/styled';

export const NutritionWrapper = styled.section``;
export const NutritionHeader = styled.div`
  display: flex;
`;
export const NutritionPicture = styled.img`
  display: block;
  width: 100%;
  max-width: 40px;
  max-height: 40px;
  margin-right: 10px;
`;
export const NutritionRightSection = styled.section``;
export const NutritionName = styled.section`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;
export const NutritionPosition = styled.section`
  font-size: 14px;
  line-height: 20px;
  color: #acacac;
  margin-top: 2px;
`;
export const NutritionText = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin: 20px 0;
`;
export const NutritionSignUp = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #6ba50d;
  margin-top: 20px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;
