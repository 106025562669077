const UPDATE_QUIZ = 'UPDATE_STEP_1';

const updateQuiz = (payload) => ({
  type: UPDATE_QUIZ,
  payload,
});

const UPDATE_QUIZ_PROGRESS_BAR = 'UPDATE_QUIZ_PROGRESS_BAR';

const updateQuizProgressBar = (progressBar) => ({
  type: UPDATE_QUIZ_PROGRESS_BAR,
  payload: progressBar,
});

export { UPDATE_QUIZ, UPDATE_QUIZ_PROGRESS_BAR, updateQuiz, updateQuizProgressBar };
