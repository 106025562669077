import { combineReducers } from 'redux';

import { quizReducer } from './components/quiz/reducers/quizReducer';
import uiReducer from './ui/reducers';
import { progressReducer } from './components/buyNow/reducers';
import { goalGraphDataReducer } from './components/buyNow/reducers';
import appVersionReducer from './reducers/appVersion';

export default combineReducers({
  quiz: quizReducer,
  ui: uiReducer,
  progress: progressReducer,
  goalGraph: goalGraphDataReducer,
  appVersion: appVersionReducer,
});
