export const radioButtonsData = [
  {
    text: '1$',
    id: '0',
    value: '1',
  },
  {
    text: '5$',
    id: '1',
    value: '5',
  },
  {
    text: '10$',
    id: '2',
    value: '10',
  },
  {
    text: '16.41$',
    id: '3',
    value: '16.41',
  },
];
