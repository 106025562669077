import {
  ContentItem,
  ContentList,
  SubTitle,
  SubTitleHeader,
  SubTitleImg,
  SubTitleInner,
  SubTitleWrapper,
  Title,
  WhatYouGetSection,
  ContentImg,
  Content,
  ImageSection,
  DiscountBlock,
  DiscountRightWrapper,
  DiscountLeft,
  DiscountRightItem,
  DefaultPrice,
  Wrapper,
  ButtonWrapper,
  ButtonNext,
  ModalWrapper,
  SuccessTitle,
  ContentModalBlock,
  ContentModalText,
  ContentModalIcon,
  ButtonWrapperModal
} from './styled';
import { useEffect, useState } from 'react';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';
import { Button } from '@Components/Button';
import { useHistory } from 'react-router-dom';
import { Modal } from '@Components/Modal';
import { upSellPayment } from '@Utilities/api';
import { errorNotification } from '@Utilities/notification';
import { sendAmplitudeData } from '@Analytics/amplitude';
import config from '@/config';

export const UpSellComponent = ({ data, isV2 }) => {
  const history = useHistory();
  const [isShowModal, setIsShowModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const {
    title,
    subTitle,
    whatYouGetSection,
    whatYouGetSection2,
    checkIcon,
    upSellImg
  } = data;

  useEffect(() => {
    sendAmplitudeData('f_upsell_view');
    scrollPageToTop();
  }, []);

  const getContent = (value, icon) => {
    return (
      <>
        {value?.map(item => {
          return (
            <Content>
              <ContentImg src={icon} alt={'icon'} />
              <ContentItem>
                {item}
              </ContentItem>
            </Content>)
        })}
      </>
    );
  };

  const goNextPage = () => {
    sendAmplitudeData('f_upsell_show_next_click');
    if (data?.id < 4) {
      history.push(`${isV2}/quiz/buy_now/upsell/${data.id + 1}`);
    } else {
      history.push(`${isV2}/quiz/buy_now/thank_you_page`);
    }
  };

  const filterPriceUpSell = () => {
    const prices = config.prices.upSell;
    switch (data?.id) {
      case 1:
        return prices.easyHealthyMenu;
      case 2:
        return prices.walkingChallenge;
      case 3:
        return prices.veggieSnackGuide;
      case 4:
        return prices.flatBellyRules;
      default:
        return null;
    }
  };

  const handlePayment = async () => {
    setIsFetching(true);
    sendAmplitudeData('f_upsell_get_it_now_click');
    const prices = filterPriceUpSell();
    const result = await upSellPayment(prices);
    if (200 !== result?.status) {
      setIsFetching(false);
      setIsShowModal(false);
      errorNotification('Oops, something went wrong, try again later');
    } else {
      setIsShowModal(true);
      setIsFetching(false);
    }
  };

  const goToSuccessPage = () => {
    history.push(`${isV2}/quiz/buy_now/thank_you_page`);
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <SubTitleWrapper styleProps={{background: data.background, padding: data.padding}}>
        <SubTitleImg src={subTitle?.src} styleProps={4 === data.id ? {height: '82px'} : {}}/>
        <SubTitleInner>
          {subTitle?.title && <SubTitleHeader>{subTitle?.title}</SubTitleHeader>}
          <SubTitle>{subTitle?.text}</SubTitle>
        </SubTitleInner>
      </SubTitleWrapper>
      <WhatYouGetSection>
        <Title styleProps={{fontSize: '15px', lineHeight: '24px', color: '#090909'}}>{whatYouGetSection?.title}</Title>
        <ContentList>
          {getContent(whatYouGetSection?.content, checkIcon)}
        </ContentList>
      </WhatYouGetSection>
      <ImageSection src={upSellImg} alt={'upSellImg'}/>
      <WhatYouGetSection>
        <Title styleProps={{fontSize: '15px', lineHeight: '24px', color: '#090909'}}>{whatYouGetSection2?.title}</Title>
        <ContentList>
          {getContent(whatYouGetSection2?.content, checkIcon)}
        </ContentList>
      </WhatYouGetSection>
      <DiscountBlock>
        <DiscountLeft>
          -90%
        </DiscountLeft>
        <DiscountRightWrapper>
          <DiscountRightItem>Best Offer</DiscountRightItem>
          <DiscountRightItem><DefaultPrice>4.99$</DefaultPrice> <span>now only</span> 0.50$</DiscountRightItem>
        </DiscountRightWrapper>
      </DiscountBlock>
      <ButtonWrapper>
        <Button
          name={data.buttonText}
          minWidth='100%'
          margin='0 0 20px 0'
          type='submit'
          isDisabled={isFetching}
          isLoading={isFetching}
          handleClick={handlePayment}
        />
        <ButtonNext onClick={goNextPage}>
          Show next
        </ButtonNext>
      </ButtonWrapper>
      <Modal isShow={isShowModal}>
        <ModalWrapper>
          <SuccessTitle styleProps={{fontSize: '34px'}}>
            🎉
          </SuccessTitle>
          <SuccessTitle>
            All set!
          </SuccessTitle>
          <ContentModalBlock>
            <ContentModalIcon src='/images/upSell/hand.svg' />
            <ContentModalText>We'll send your challenge/guide to your mailbox</ContentModalText>
          </ContentModalBlock>
          <ButtonWrapperModal>
            <Button
              name={'Ok, got it'}
              minWidth='100%'
              margin='0 0 20px 0'
              handleClick={goToSuccessPage}
            />
            <ButtonNext onClick={goNextPage}>
              Show next
            </ButtonNext>
          </ButtonWrapperModal>
        </ModalWrapper>
      </Modal>
    </Wrapper>
  );
};