export const radioButtonsData = [
  {
    value: 'My diet isn\'t great and my activity levels need improving'
  },
  {
    value: 'My diet and activity goals could be improved'
  },
  {
    value: 'I try to practice healthy lifestyle habits'
  }
];
