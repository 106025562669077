import { FooterContent, FooterWrapper } from './styled';

const Footer = () => {
  const companiesLogo = [
    'american-express',
    'apple-pay',
    'discover',
    'google-pay',
    'https',
    'jcb',
    'maestro',
    'master-secure',
    'mastercard',
    'mc',
    'norton',
    'paypal',
    'ssl-img',
    'unionpay',
    'visa',
    'visa-secure',
  ];
  return (
    <FooterWrapper>
      <FooterContent>
        {companiesLogo.map((el, index) => (
          <img src={`/images/payment/${el}.svg`} key={index} />
        ))}
      </FooterContent>
    </FooterWrapper>
  );
};

export default Footer;
