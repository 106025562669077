import { Title } from '@QuizComponents/Title';
import { RadioButton } from '@Components/RadioButton';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useContext, useEffect } from 'react';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { radioButtonsData } from './radioButtonsData';

export const HowWellYouSleep = ({ subStepName, handleRadioButton }) => {
  const quiz = useSelector((store) => store.quiz);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step2',
        currentStep: 1,
        totalSteps: 5,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step2');
  }, []);

  return (
    <div className='how-well-you-sleep'>
      <Title title='How would you describe how well you sleep?' />
      {radioButtonsData.map(({ value }) => {
        return (
          <Fragment>
            <RadioButton
              id={value}
              text={value}
              onClick={handleRadioButton}
              name={subStepName}
              checked={quiz['how-well-you-sleep'] === value}
              value={value}
            />
          </Fragment>
        );
      })}
    </div>
  );
};
