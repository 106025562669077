const checkRules = (value, inputName) => {
  const errorMessageFirst = 'Your weight does not look real. If this is your real weight, contact support.';
  const errorMessageIm = 'Please enter a value between 4 and 7';
  const errorMessageInch = 'Please enter a value between 0 and 11';
  const errorHeight = 'Please enter a value between 120 and 250';
  switch (inputName) {
    case 'weight lbs':
      const weightValueToNumber = Number(value);
      if (weightValueToNumber < 90 || value > 400) {
        return errorMessageFirst;
      }
      break;
    case 'weight kg':
      const weightValue = Number(value);
      if (weightValue < 40 || value > 180) {
        return errorMessageFirst;
      }
      break;
    case 'height cm':
      const heightValueToNumber = Number(value);
      if (heightValueToNumber < 120 || heightValueToNumber > 250 || isNaN(heightValueToNumber)) {
        return errorHeight;
      }
      break;
    case 'height lb':
      const heightValue = value && Number(value);
      if (heightValue < 4 || heightValue > 7 || isNaN(heightValue)) {
        return errorMessageIm;
      }
      break;
    case 'height inch':
      const heightInch = value && Number(value);
      if (heightInch > 11 || isNaN(heightInch)) {
        return errorMessageInch;
      }
      break;
  }
};

export const getMessageError = (inputName) => {
  return {
    validate: (value) => {
      return checkRules(value, inputName);
    },
    required: 'This field is required.',
  };
};
