import styled from '@emotion/styled';

export const PaidTrialTitle = styled.h1`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  font-weight: 700;
  text-align: center;
`;

export const PaidTrialWrapper = styled.div`
  margin: 60px 0;
`;

export const PaidTrialText = styled.div`
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #191B1F;
  
  b {
    font-weight: bold;
  }
`;

export const PaidTrialItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media(max-width: 460px) {
    justify-content: center;
    gap: 12px;
    padding: 0 16px;
  }
  
  @media(max-width: 350px) {
    gap: 6px;
  }
  
  .radio-button {
    label {
      height: 64px;
      width: 100px;
      justify-content: center;
      
      div {
        margin: 0;
      }
      @media(max-width: 490px) {
        width: 88px;
        height: 48px;
      }
      @media(max-width: 425px) {
        width: 80px;
      }
      @media(max-width: 380px) {
        width: 70px;
      }
    }
    
    label::before {
        display: none !important;
    }
  }

  .radio-button:last-child {
    label::before {
      border: 1px solid #92C73C !important;
      border-radius: 16px !important;
      padding: 8px 13px;
      position: absolute;
      content:"Popular";
      display: block !important;
      color: #92C73C !important;
      width: 73.5px !important;
      font-size: 15px!important;
      background-color: transparent !important;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      top: -70%;
      height: 13px !important;
      left: 0;
      margin: 0 !important;
      
      @media (max-width: 460px) {
        width: 47px !important;
        height: 10px !important;
        font-size: 10px!important;
      }
      
    }
  }

  
  .radio-button input[type='radio']:checked + label {
    border: 1px solid #92C73C;
    background: #F4F4F4;
  }
`;
