import DatePicker from 'react-datepicker';
import { IconWrapper } from './styled';
import { Controller } from 'react-hook-form';
import React, { useState } from 'react';
import './react-datepicker.css';
import { Calendar } from '@Components/Icons';
import { Input, InputWrapper } from '@Components/InputComponent/styled';
import { dateDiff } from '@Utilities/momentFunction';

const StyledInput = ({ onChange, placeholder, value, onClick, icon , disabledState, disabledStateFalse, disabledFunc }) => {
  const dateNumber = dateDiff(value, 'years');
  if (disabledFunc) {
    (dateNumber < 18) || (dateNumber > 99) ? disabledState() : disabledStateFalse();
  }

  return (
    <InputWrapper onClick={onClick}>
      <Input onChange={onChange} placeholder={placeholder} value={value} readOnly />
      <IconWrapper>{icon}</IconWrapper>
    </InputWrapper>
  );
};

export const DatePick = ({ control, icon, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ICON = <Calendar />;

  return (
    <Controller
      control={control}
      {...props}
      render={({ field: { onChange, value } }) => (
        <DatePicker
          onChange={onChange}
          selected={value}
          dateFormat={'MMMM dd yyyy'}
          maxDate={props.rangeDateMin && props.rangeDateMin()}
          minDate={props.rangeDateMax && props.rangeDateMax()}
          placeholderText='Select a date'
          customInput={
            <StyledInput
              icon={ICON}
              disabledState={props.disabledState}
              disabledStateFalse={props.disabledStateFalse}
              disabledFunc={props.disabledFunc}
            />
          }
          showPopperArrow={false}
          closeOnScroll={false}
          shouldCloseOnSelect={false}
          adjustDateOnChange
          popperPlacement='bottom'
          onInputClick={() => setIsOpen(true)}
          onClickOutside={() => setIsOpen(true)}
          open={isOpen}
          readOnly={true}
          showYearDropdown
          yearDropdownItemNumber={100}
          scrollableYearDropdown
          dateFormatCalendar='MMMM'
        />
      )}
    />
  );
};
