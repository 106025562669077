import './styles.scss';
import { Controller } from 'react-hook-form';

const styleState = (multiRadio, advanced, controlStatus) => {
  if (multiRadio) {
   return  `radio-button-multiLabel ${advanced && 'is-advanced'}`
  } else if (controlStatus) {
    return `radio-control ${advanced && 'is-advanced'} `
  } else {
    return `radio-button ${advanced && 'is-advanced'}`
  }
}

export const RadioButton = ({
  id,
  text,
  name,
  checked,
  value,
  onClick,
  multiRadio,
  controlStatus,
  advanced = {
    isActive: false,
    topTitle: '',
    bottomTitle: '',
  },
  children,
  ...props
}) => {
  const divClassName = styleState(multiRadio, advanced.isActive, controlStatus)
  const CONTROL = props.control ? props.control : null;

  return (
    <div className={divClassName} {...props}>
      {CONTROL ? (
        <Controller
          control={CONTROL}
          name={name}
          render={({ field }) => <input type="radio"  id={id} defaultChecked={checked} onClick={onClick} {...field} value={value} />}
        />
      ) : (
        <input type="radio" id={id} name={name}  onClick={onClick} defaultChecked={checked} value={value} />
      )}
      <label htmlFor={id} className={ multiRadio ? `${checked ? 'radio-button__multi-label-active' : 'radio-button__multi-label'}` : controlStatus ? `${checked ? 'radio-button__control-active' : 'radio-button__control'}` : "radio-button-label" }>
        {advanced.isActive && <p className="top-title">{advanced.topTitle}</p>}
        {children ? children : <div>{text}</div>}
        {advanced.isActive && <p className="bottom-title">{advanced.bottomTitle}</p>}
      </label>
    </div>
  );
};
