export const radioButtonsData = [
  {
    text: 'Female',
    value: 'female'
  },
  {
    text: 'Male',
    value: 'male'
  },
  {
    text: 'Non-binary',
    value: 'non-binary'
  },
  {
    text: 'Skip',
    value: 'skip'
  }
];
