import { RadioButton } from '@Components/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { radioButtonsData } from './radioButtonData';
import { Button } from '@Components/Button';
import { PaidTrialItemWrapper, PaidTrialText, PaidTrialTitle, PaidTrialWrapper } from './styled';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { scrollTo } from '@Utilities/scrollTo';
import { sendAmplitudeData } from '@Analytics/amplitude';

export const PaidTrial = () => {
  const { quiz } = useSelector((store) => store);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    const { id, name, value } = event.target;
    sendAmplitudeData(`Fasting_Checkout_Paid_trial_${value}$_click`);
    dispatch(
      updateQuiz({
        name,
        value: {
          price: value,
          id: id
        },
      })
    );
  };

  const scrollToCheckout = () => {
    sendAmplitudeData('Fasting_Checkout_Paid_trial_Continue_click');
    scrollTo('payment');
  };

  return (
    <PaidTrialWrapper>
      <PaidTrialTitle>Try Fasting for a week</PaidTrialTitle>
      <PaidTrialText>We've helped millions achieve sustainable weight loss and we want to help you too.</PaidTrialText>
      <PaidTrialText>Money shouldn't stand in the way of finding a plan that finally works. So, <b>choose an amount</b> that you think is reasonable to try us out for two weeks.</PaidTrialText>
      <PaidTrialText style={{marginBottom: '70px'}}><span style={{color: '#EA5556', fontWeight: 'bold'}}>It costs us $16.41 to compensate our employees</span> for the trial, but please choose the amount you are comfortable with.</PaidTrialText>
      <PaidTrialItemWrapper>
        {radioButtonsData.map(({ id, text, value }) => {
          return (
            <RadioButton
              key={value}
              id={id}
              text={text}
              name={'paidTrial'}
              checked={quiz['paidTrial']?.price === value}
              onClick={handleClick}
              value={value}
            />
          );
        })}
      </PaidTrialItemWrapper>
      <Button name="Continue" minWidth="100%" margin="30px 0 0" onClick={scrollToCheckout} />
    </PaidTrialWrapper>
  );
};
