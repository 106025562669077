import {
  CurrentWeightActive,
  CurrentWeightInActive,
  CurrentWeightMeasure,
  CurrentWeightValue,
  CurrentWeightWrapper,
  KilogramsBlockText,
  KilogramsBlockWrapper,
} from './styled';
import { Title } from '@QuizComponents/Title';
import { useSelector } from 'react-redux';

export const KilogramsBlock = ({ date }) => {
  const quiz = useSelector((store) => store.quiz);
  const measure = 'imperial' === quiz['measure'] ? 'lbs' : 'kg'
  const desiredWeightMeasure = quiz['your-desired-weight'].weight

  const getCurrentWeight = () => {
    return (
      <CurrentWeightWrapper>
        <CurrentWeightInActive style={{ marginRight: '28px' }}>{desiredWeightMeasure - 2}</CurrentWeightInActive>
        <CurrentWeightInActive>{desiredWeightMeasure - 1}</CurrentWeightInActive>

        <CurrentWeightActive>
          <CurrentWeightValue>{desiredWeightMeasure}</CurrentWeightValue>
          <CurrentWeightMeasure>{measure}</CurrentWeightMeasure>
        </CurrentWeightActive>

        <CurrentWeightInActive>{desiredWeightMeasure + 1}</CurrentWeightInActive>
        <CurrentWeightInActive style={{ marginLeft: '28px' }}>{desiredWeightMeasure + 2}</CurrentWeightInActive>
      </CurrentWeightWrapper>
    );
  };

  const { month, year } = date;
  return (
    <>
      <KilogramsBlockWrapper>
        <Title title="Based on your answers you will be..." styleProps={{ fontSize: '24px', lineHeight: '32px' }} />
        {getCurrentWeight()}
      </KilogramsBlockWrapper>
      <KilogramsBlockText>
        By {month} {year}
      </KilogramsBlockText>
    </>
  );
};
