import {
  CaloriesCount,
  CaloriesHeader,
  CaloriesProgress,
  CaloriesProgressCircle,
  CaloriesProgressLine,
  CaloriesProgressValue,
  CaloriesProgressValuesWrapper,
  CaloriesSubtitle,
  CaloriesToolTip,
  CaloriesWrapper,
} from './styled';
import { Title } from '@QuizComponents/Title';
import { useSelector } from 'react-redux';

export const Calories = () => {
  const PERSONAL_SUMMARY = useSelector((store) => store.quiz['personalSummary']);
  const CURRENT_CALORIES = PERSONAL_SUMMARY?.daily_calorie_norm;

  const START_VALUE = Number(CURRENT_CALORIES) < 2000 ? Number(CURRENT_CALORIES) - 1000 : Number(CURRENT_CALORIES);
  const LINE_WIDTH = (START_VALUE / 5000) * 100;
  const getBMIProgress = () => {
    return (
      <>
        <CaloriesProgress>
          <CaloriesProgressCircle>
            <CaloriesProgressLine lineWidth={LINE_WIDTH} />
            <CaloriesToolTip lineWidth={LINE_WIDTH}>{CURRENT_CALORIES} Cal</CaloriesToolTip>
          </CaloriesProgressCircle>
        </CaloriesProgress>
        <CaloriesProgressValuesWrapper>
          <CaloriesProgressValue>1000 Cal</CaloriesProgressValue>
          <CaloriesProgressValue>5000 Cal</CaloriesProgressValue>
        </CaloriesProgressValuesWrapper>
      </>
    );
  };

  return (
    <CaloriesWrapper>
      <CaloriesHeader>
        <Title title="Calories" styleProps={{ padding: 0, margin: 0, textAlign: 'left' }} />
        <CaloriesCount>{CURRENT_CALORIES} Cal</CaloriesCount>
      </CaloriesHeader>
      <CaloriesSubtitle>Recommended daily</CaloriesSubtitle>
      {getBMIProgress()}
    </CaloriesWrapper>
  );
};
