import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

import rootReducer from './store/rootReducer';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import createSagaMiddleware from 'redux-saga';
import rootSaga from './middleware/rootSaga';
import { initAmplitude } from '@Analytics/amplitude';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ReactGA from 'react-ga';
import { loadState, saveState } from './localStorage';
import config from '@/config';
// import axios from 'axios';
const sagaMiddleware = createSagaMiddleware();

const persistedState = loadState();
const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(sagaMiddleware)));

store.subscribe(() => {
  saveState({
    quiz: store.getState().quiz,
    progress: store.getState().progress,
    goalGraph: store.getState().goalGraph,
  });
});

sagaMiddleware.run(rootSaga);

initAmplitude();

ReactGA.initialize(config.gaid);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <App />
      </Provider>
    </Elements>
  </React.StrictMode>,
  document.getElementById('root')
);
