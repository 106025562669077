const checkEmailRules = (value) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!emailRegex.test(value)) {
    return "Your email doesn't look right, please check it again";
  }
};

export const getEmailMessageError = () => {
  return {
    validate: (value) => {
      return checkEmailRules(value);
    },
    required: 'This field is required.',
  };
};
