import { useEffect, useState } from 'react';
import { gaEventAnalytics } from '@Utilities/gaEventAnalytics';
import {
  BottomText,
  DownloadAppFromAppStore,
  DownloadAppFromAppStoreContent,
  DownloadAppFromAppStoreIcon,
  PaymentSuccessFulContainer,
  PaymentSuccessfulDescription,
  PaymentSuccessFulSubTitle,
  PaymentSuccessFulTitle,
  SupportBlock,
  TopText, PaymentSuccessfulDescriptionText,
  LinkStyled,
  BlockOr,
  QrCodeWrapper
} from './styled';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { useDispatch, useSelector } from 'react-redux';
import { setPurchaseEventSent } from '@Store/components/buyNow/actions';
import { convertionToArray } from '@Utilities/conversion';
import Cookies from 'js-cookie';
import { FBEvent } from '@Analytics/facebookPixel';
import { saveOnboardData, getDeepLink } from '@Utilities/api';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';
import { QRCodeSVG } from 'qrcode.react';
import { pinterestEvent } from '@Analytics/pinterest';

export const ThankYouPage = () => {
  const { isPurchaseEventSent, email } = useSelector((store) => store.progress);
  const state = useSelector((store) => store.quiz);
  const timer = useSelector((store) => store.progress.timer);
  const paymentPlan = useSelector((store) => store.quiz?.paymentPlan);
  const prices = 'end' !== timer ? paymentPlan.discount : paymentPlan.default;
  const { price } = prices;
  const dispatch = useDispatch();
  const token = Cookies.get('token');
  const [deepLink, setDeepLink] = useState('https://yourmeal.onelink.me/aMaD/8c07121b');

  const motivationEvent = (value) => {
    let motivationEventValue, motivationEventNameCustom = '';
    if ('School reunion' === value) {
      motivationEventValue = 'school_reunion';
    } else if ('Vacation' === value) {
      motivationEventValue = 'vacation';
    } else if ('Birthday party' === value) {
      motivationEventValue = 'birthday_party';
    } else if ('No similar events' === value) {
      motivationEventValue = 'no_similar';
    } else if ('Wedding' === value) {
      motivationEventValue = 'wedding';
    } else {
      motivationEventValue = 'custom';
      motivationEventNameCustom = value;
    }
    return { motivationEventValue, motivationEventNameCustom };
  };

  const getStateData = () => {
    const dietaryRestrictions = convertionToArray(state['dietaryRestrictions']);
    const { height, heightInch } = state['your-height'];
    const { radioButtonValue } = state['important-event'];
    const { motivationEventValue, motivationEventNameCustom } = motivationEvent(radioButtonValue);

    return {
      name: state['name'].name,
      birthDate: state['birthDate'].birthDateString,
      height: height,
      heightInches: heightInch,
      currentWeight: state['your-weight'].weight,
      desiredWeight: state['your-desired-weight'].weight,
      gender: state['gender'].toLowerCase(),
      motivationEvent: motivationEventValue?.toLowerCase(),
      motivationEventName: 'custom' === motivationEventValue ? motivationEventNameCustom?.toLowerCase() : null ,
      motivationEventDate: state['motivationEventDate'] ? state['motivationEventDate'].toString() : null,
      mealsPerDay: state['how-many-meals-per-day'],
      drinkWater: state['drinkWater'],
      timeForCooking: state['timeForCooking'],
      dietaryRestrictions: dietaryRestrictions && ('reset' === dietaryRestrictions[0]) ? [] : dietaryRestrictions,
      caloriesPerDay: state['calories'],
      diet: state['diet'].toLowerCase(),
      workout: state['tailoring-plan'],
      measure: state['measure'],
      email: email? email : null
    };
  };

  const saveOnboard = () => {
    const token = Cookies.get('token');
    const result = getStateData(state);
    saveOnboardData(
      result,
      token
    ).catch(err => console.log(err.message));
  };

  useEffect(() => {
    if (!isPurchaseEventSent) {
      gaEventAnalytics('fast_purch_success');
      FBEvent('Purchase', price);
      dispatch(setPurchaseEventSent({ isPurchaseEventSent: true }));
    }
    sendAmplitudeData('f_thank_you_page_view');
    scrollPageToTop();
    sendAmplitudeData('f_sub_started', {
      F_Price: price
    });
    saveOnboard();
    getDeepLink(token).then((res) => {
      if (200 === res.status) {
        const { appleAuthLink } = res.data.data;
        setDeepLink(appleAuthLink);
      }
    });
    pinterestEvent('Checkout', price);
  }, []);

  const handleQrCodeClick = () => {
    sendAmplitudeData('y_qr_code_click');
  };

  return (
    <PaymentSuccessFulContainer>
      <PaymentSuccessFulTitle>🎉</PaymentSuccessFulTitle>
      <PaymentSuccessFulTitle>Download the Lasta app to start!</PaymentSuccessFulTitle>
      <PaymentSuccessFulSubTitle>Tap or scan the QR code below</PaymentSuccessFulSubTitle>
      <QrCodeWrapper>
        <a href={deepLink} target='_blank' onClick={handleQrCodeClick}>
          <QRCodeSVG
            value={deepLink}
          />
        </a>
      </QrCodeWrapper>
      <BlockOr>Or</BlockOr>
      <PaymentSuccessfulDescription>
        <PaymentSuccessfulDescriptionText>Download the app on App Store.</PaymentSuccessfulDescriptionText>
        <PaymentSuccessfulDescriptionText>You will be automatically <span>Signed in</span></PaymentSuccessfulDescriptionText>
      </PaymentSuccessfulDescription>
      <LinkStyled
        href={deepLink}
        styleProps={{ textDecoration: 'none' }}
        target="_blank"
        onClick={() => sendAmplitudeData('f_appstore_button_click')}
      >
        <DownloadAppFromAppStore>
          <DownloadAppFromAppStoreIcon src="/images/thankYouPage/apple-icon.svg"/>
          <DownloadAppFromAppStoreContent>
            <TopText>Download on the</TopText>
            <BottomText>App Store</BottomText>
          </DownloadAppFromAppStoreContent>
        </DownloadAppFromAppStore>
      </LinkStyled>
      <SupportBlock>If you have any billing related questions, please contact <a
        href={'mailto:billing@lasta.app'}>billing@lasta.app</a></SupportBlock>
    </PaymentSuccessFulContainer>
  );
};
