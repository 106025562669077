import { CLEAR_ERROR, SET_ERROR, START_FETCHING, STOP_FETCHING } from './types';

const initialState = {
  isFetching: false,
  error: '',
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCHING:
      return {
        ...state,
        isFetching: true,
      };
    case STOP_FETCHING:
      return {
        ...state,
        isFetching: false,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
};

export default uiReducer;
