import { put, select } from 'redux-saga/effects';
import axios from 'axios';
import { startFetching, stopFetching } from '@Store/ui/actions';
import { setError, clearError } from '@Store/ui/actions';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { googleAnaliticsStorage } from '@Analytics/ga';
import { convertionToArray } from '@Utilities/conversion';
import Cookies from 'js-cookie';
import config from '@/config';

const motivationEvent = (value) => {
  let motivationEventValue, motivationEventNameCustom = '';
  if ('School reunion' === value) {
    motivationEventValue = 'school_reunion';
  } else if ('Vacation' === value) {
    motivationEventValue = 'vacation';
  } else if ('Birthday party' === value) {
    motivationEventValue = 'birthday_party';
  } else if ('No similar events' === value) {
    motivationEventValue = 'no_similar';
  } else if ('Wedding' === value) {
    motivationEventValue = 'wedding';
  } else {
    motivationEventValue = 'custom';
    motivationEventNameCustom = value;
  }
  return { motivationEventValue, motivationEventNameCustom };
};

const getStateData = (state) => {
  const dietaryRestrictions = convertionToArray(state['dietaryRestrictions']);
  const { height, heightInch } = state['your-height'];
  const { radioButtonValue } = state['important-event'];
  const { motivationEventValue, motivationEventNameCustom } = motivationEvent(radioButtonValue);

  return {
    name: state['name'].name,
    birthDate: state['birthDate'].birthDateString,
    height: height,
    heightInches: heightInch,
    currentWeight: state['your-weight'].weight,
    desiredWeight: state['your-desired-weight'].weight,
    gender: state['gender'].toLowerCase(),
    motivationEvent: motivationEventValue?.toLowerCase(),
    motivationEventName: 'custom' === motivationEventValue ? motivationEventNameCustom?.toLowerCase() : null ,
    motivationEventDate: state['motivationEventDate'] ? state['motivationEventDate'].toString() : null,
    mealsPerDay: state['how-many-meals-per-day'],
    drinkWater: state['drinkWater'],
    timeForCooking: state['timeForCooking'],
    dietaryRestrictions: dietaryRestrictions && ('reset' === dietaryRestrictions[0]) ? [] : dietaryRestrictions,
    caloriesPerDay: state['calories'],
    diet: state['diet'].toLowerCase(),
    workout: state['tailoring-plan'],
    measure: state['measure']
  };
};

const callSurveyResult = (email, state, token) => {
  const result = getStateData(state);
  const rootUrl = process.env.REACT_APP_ROOT_HOST;
  const url = `${rootUrl}/api/v3/onboard`;
  const params = {
    email,
    ...result,
  };
  return axios.post(url, {
    ...params
  }, {
    headers: {
      'Platform': 'ios',
      'Authorization': token
    },
  });
};

export function* worker({ payload }) {
  const state = yield select();
  const email = state.quiz['your-email'].email;
  const isV2 = state.appVersion;
  const { cardNumber, expiresDate, securityCode, history } = payload;
  const rootUrl = process.env.REACT_APP_ROOT_HOST;
  const url = `${rootUrl}/api/v3/payment/subscription/layout/create`;
  const paymentPlan = state.quiz.paymentPlan;
  const paidTrialId = state.quiz.paidTrial.id;
  const paidTrial = isV2 ? config.prices.paidTrial.test[paidTrialId] : config.prices.paidTrial.master[paidTrialId];
  const timer = state.progress.timer;
  const plan = timer !== 'end' && paymentPlan?.discount ? paymentPlan.discount : paymentPlan?.default;
  const params = {
    cardNumber: cardNumber.replaceAll(' ', ''),
    expYear: `20${expiresDate.split('/')[1].trim()}`,
    expMonth: expiresDate.split('/')[0].trim(),
    cvv: securityCode,
    email,
    planId: plan.plan_id,
    paidTrialPlanId: paidTrial
  };
  let token = {};

  yield put(clearError());
  yield put(startFetching());

  try {
    googleAnaliticsStorage(email);
    yield axios.post(url, params).then(res => {
      token = res.data.data.authToken;
      Cookies.set('token', token);
    });
    yield callSurveyResult(email, state.quiz, token);
    sendAmplitudeData('Buy_trial_started', {
      F_Price: plan.price
    });
    history.push('upsell/1');
  } catch (error) {
    const errorMessageFromStripe = error.response?.data.message;
    if (errorMessageFromStripe) {
      yield put(setError(error.response.data.message));
    } else {
      yield put(setError('Error: Transaction has been declined.'));
    }
    sendAmplitudeData('f_buy_checkout_paymentError', {
      paymentError_message: error.response?.data?.message,
    });
  } finally {
    yield put(stopFetching());
  }
}
