import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const EmailPolicyWrapper = styled.section`
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  color: #878787;
  margin-top: 16px;
`;

export const TermsOrUseLink = styled(NavLink)`
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  color: #878787;
  text-decoration: none;
  span {
    color: #ea5556;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
  }
`;
