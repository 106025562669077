import styled from '@emotion/styled';

export const PaymentContainer = styled.section`
  margin: 60px 0;
`;
export const PaymentSectionTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  font-weight: 700;
  text-align: center;
`;

export const BenefitPlanWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PriceInfoBlock = styled.section`
  padding: 24px 12px;
  margin-top: 60px;
  position: relative;
  border-radius: 20px;
`;

export const PriceInfoBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const PriceInfoBlockDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #92c73c;
  margin: 12px 0;
`;

export const PriceInfoBlockGift = styled.section`
  display: flex;
  align-items: center;
`;

export const PriceInfoBlockGiftOldPrice = styled.p`
  font-size: 11px;
  line-height: 14px;
  color: #ff8298;
  font-weight: 500;
  text-decoration: line-through;
`;

export const PriceInfoBlockGiftDiscount = styled.p`
  font-size: 22px;
  line-height: 20px;
  color: #191b1f;
  margin: 5px 0;
`;
export const PriceInfoBlockGiftOffer = styled.span`
  font-size: 22px;
  line-height: 20px;
  color: #ea5556;
  margin: 5px 0;
  font-weight: 700;
`;

export const PriceInfoBlockGiftOfferText = styled.span`
  font-size: 16px;
  line-height: 16px;
  color: #3f5366;
  margin: 10px 0 5px 5px;
  font-weight: 400;
`;

export const PriceInfoBlockDescription = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: #3f5366;
`;

export const PriceInfoBlockSubDescription = styled.p`
  font-size: 11px;
  line-height: 14px;
  color: #3f5366;
`;

export const PriceInfoBlockContent = styled.section``;

export const CheckBoxContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-right: 8px;
  }
`;

export const PriceInfoBlockIcon = styled.div`
  font-size: 20px;
`;

export const PriceInfoBlockContainer = styled.section``;

export const PriceInfoBlockLeft = styled.section`
  display: flex;
  align-items: center;
`;

export const SectionLeft = styled.p`
  min-width: 100px;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
`;

export const SectionRight = styled.div`
  margin-left: 44px;
  font-size: 13px;
  line-height: 18px;
`;
