import styled from '@emotion/styled';

export const StripeContentWrapper = styled.section`
  display: flex;
`;

export const StripeContentText = styled.p`
  font-size: 11px;
  line-height: 14px;
  color: #2e3064;
  font-weight: 500;
  margin-right: 4px;
`;

export const StripeContentLogo = styled.div``;
