import CheckBoxContent from '@Components/CheckBoxContent';
import { TrustContentWrapper } from './styled';

const TrustContent = () => {
  const checkboxItems = [
    {
      title: '<strong style="font-weight: 700; font-size: 16px; line-height: 24px">Your information is safe</strong>',
      description: 'We will not sell or rent your personal contact information for any marketing purposes.',
    },
    {
      title: '<strong style="font-weight: 700; font-size: 16px; line-height: 24px">Secure checkout</strong>',
      description: 'All information is encrypted and transmitted using Secure Sockets Layer protocol.',
    },
    {
      title: '<strong style="font-weight: 700; font-size: 16px; line-height: 24px">Need help?</strong>',
      description:
        'Contact us <a target="_top" href="mailto:billing@lasta.app" style="color: #EA5556; text-decoration: none">here</a>.',
    },
  ];

  return (
    <TrustContentWrapper>
      <CheckBoxContent checkboxItems={checkboxItems} />
    </TrustContentWrapper>
  );
};

export default TrustContent;
