import './styles.scss';
import { Navigation } from './components/Navigation';
import { PreviousStepBtn } from '@Components/Icons';
import { NavLink, useHistory } from 'react-router-dom';
import { Logo } from '@Components/Icons';

export const Header = ({ isShowPreviousBtn = false }) => {
  const history = useHistory();

  const backToPreviousPage = () => {
    history.goBack();
  };

  return (
    <div className='header'>
      <div className='wrapper'>
        <div className='left-section'>
          {isShowPreviousBtn && (
            <div className='previous-btn mobile'>
              <PreviousStepBtn onClick={backToPreviousPage} />
            </div>
          )}
          <NavLink to='/' className='logo'>
            <Logo width={86} height={65} viewBox='0 0 50 35' />
          </NavLink>
        </div>

        <div className='right-section'>
          <Navigation />
        </div>
      </div>
      {isShowPreviousBtn && (
        <div className='previous-btn desktop'>
          <PreviousStepBtn onClick={backToPreviousPage} />
        </div>
      )}
    </div>
  );
};
