import styled from '@emotion/styled';

export const HeaderResultsWrapper = styled.section`
  margin: 0 -16px;
  overflow: hidden;
  min-height: 459px;
`;

export const HeaderResultsImage = styled.img`
  position: absolute;
  top: -26px;
  left: -7px;
  max-width: 468px;
`;

export const HeaderResultsContent = styled.div`
  position: relative;
  max-width: 220px;
`;

export const ButtonWrapper = styled.div`
  position: ${(props) => (props.isScrollToButton ? 'fixed' : 'relative')};
  bottom: ${(props) => (props.isScrollToButton ? 0 : '-257px')};
  z-index: 10;
  width: 100vw;
  margin: 0 -50vw;
  left: 50%;
  right: 50%;
  padding: ${(props) => (props.isScrollToButton ? '0' : '0 16px')};
`;
