import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const WrapperCartButton = styled.div`
  background-color: white;
  box-sizing: border-box;
  border: ${props => props.isToggled ? '1px solid #92c73c' : '1px solid #E8E8E8'};
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border-radius: 8px;
  min-width: 100px;
  width:100%;
  height: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 16px;
  margin-right: 7px;
  & div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media(max-width: 500px){
    height: 70px;
    & div{
      margin: 0;
    }
  }
  @media(min-height: 650px) {
    padding: 15px 32px;
    min-width: 140px;
    display: flex;
    min-height: 70px;
    flex-direction: column;

  }
  &:last-child {
    margin-right: 0;
  }
`;

export const WrapperCartButtonText = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  color: #17181B;
  @media(max-width: 500px){
    margin: 0;
  }
  text-align: center;
`;

export const Logo = styled.img`
  max-width: 60px;
  width: 100%;
  display: block;
`
