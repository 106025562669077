import {
  CardInputIcon,
  CardInputPowered,
  CardInputPoweredCompanyName,
  CardInputPoweredText,
  CardInputTitle,
  CardInputTitleWrapper,
  CardNumberWrapper,
  CardType,
  CardTypeWrapper,
  CvvInfo,
  FormWrapper,
  InputWrapper,
} from './styled';
import { InputComponent } from '@Components/InputComponent';
import { Button } from '@Components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { isCardNumberValueValidate } from './inputsValueUpdateValidation';
import { getMessageError } from './inputValidateErrors';
import { securityPayment } from '@Middleware/components/securityPayment/actions';
import { useEffect, useState } from 'react';
import { getCardType } from '@Utilities/getCreditCardType';
import { Lock, StripeLogo } from '@Components/Icons';
import Popover from '@Components/Popover';
import { ErrorMessage } from '@Components/ErrorMessage';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeData } from '@Analytics/amplitude';

const Form = ({ children }) => {
  const ui = useSelector((store) => store.ui);
  const { isFetching, error } = ui;

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      cardNumber: '',
      expiresDate: '',
    },
  });

  const isDisabled = !isValid || isFetching;

  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = (data) => {
    sendAmplitudeData('f_buy_checkout_continue_click');
    const payload = {
      ...data,
      history,
    };
    dispatch(securityPayment(payload));
  };

  const formatValue = (value, length, IS_AUTOCOMPLETE) => {
    if (IS_AUTOCOMPLETE) {
      const VALUE_FROM_AUTOCOMPLETE = `${value.split('/')[0]}${value.split('/')[1]?.replace('20', '')}`;
      return VALUE_FROM_AUTOCOMPLETE.substr(0, length);
    }
    return value.replace(/[^0-9]/g, '').substr(0, length);
  };

  const onCardNumberChange = (field, value) => {
    setValue('securityCode', '');
    const RESULT_VALUE = formatValue(value, 16).replace(/\d{4}(?=.)/g, '$& ');
    field.onChange(RESULT_VALUE);
  };

  const onExpiresDateChange = (field, value) => {
    const IS_AUTOCOMPLETE = value.split('/')[1]?.trim().length === 4;
    let resultValue = formatValue(value, 4, IS_AUTOCOMPLETE);
    const monthValue = resultValue.split('').slice(0, 2).join('');
    if (monthValue && Number(monthValue) <= 12) {
      resultValue = resultValue.replace(/\d{2}(?=.)/g, '$& / ');
    } else {
      resultValue = '';
    }
    field.onChange(resultValue);
  };

  const cardNumberValue = watch(['cardNumber']);

  const onCvvChange = (field, value) => {
    const CARD_NUMBER_VALUE = cardNumberValue.join('').replaceAll(' ', '');
    let cvvLength = 3;
    if (CARD_NUMBER_VALUE.length === 15) {
      cvvLength = 4;
    }

    if (CARD_NUMBER_VALUE.length === 16 || CARD_NUMBER_VALUE.length === 14) {
      cvvLength = 3;
    }
    const RESULT_VALUE = formatValue(value, cvvLength);
    field.onChange(RESULT_VALUE);
  };

  const [cardType, setCardType] = useState('');

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.cardNumber) {
        const cardNumberWithoutSpace = value.cardNumber.replace(' ', '');
        setCardType(getCardType(cardNumberWithoutSpace));
      } else {
        setCardType('');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const getCompanyLogo = () => {
    let companyLogo = cardType;

    if (companyLogo === 'amex') {
      companyLogo = 'american-express';
    }

    if (companyLogo === 'visa') {
      companyLogo = 'visa';
    }

    if (companyLogo === 'mastercard') {
      companyLogo = 'mastercard';
    }

    if (companyLogo === 'discover') {
      companyLogo = 'discover';
    }

    return (
      <CardTypeWrapper>
        <CardType src={`/images/payment/${companyLogo}.svg`} />
      </CardTypeWrapper>
    );
  };

  const getPoweredBlock = () => {
    return (
      <CardInputTitleWrapper>
        <CardInputTitle>Credit Card</CardInputTitle>
        <CardInputPowered>
          <CardInputPoweredText>Powered by</CardInputPoweredText>
          <CardInputPoweredCompanyName>
            <StripeLogo />
          </CardInputPoweredCompanyName>
        </CardInputPowered>
      </CardInputTitleWrapper>
    );
  };

  const helpText = `Your card's security code (CVV) is the 3 or 4 digit number located on the back of most cards.`;
  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardNumberWrapper>
          {/*<StripeContent />*/}
          <InputComponent
            name="cardNumber"
            control={control}
            errorMessage={errors['cardNumber']}
            label={getPoweredBlock()}
            type="text"
            placeholder="1234 1234 1234 1234"
            rules={getMessageError('cardNumber')}
            onChange={onCardNumberChange}
            customInputValueValidate={isCardNumberValueValidate}
          />
          <CardInputIcon style={{ top: '35px' }}>{cardType ? getCompanyLogo() : <Lock />}</CardInputIcon>
        </CardNumberWrapper>
        <InputWrapper>
          <InputComponent
            name="expiresDate"
            control={control}
            errorMessage={errors['expiresDate']}
            label="Expires date"
            type="text"
            placeholder="MM/YY"
            rules={getMessageError('expiresDate')}
            onChange={onExpiresDateChange}
            autoComplete="section-sender cc-exp"
          />
          <InputComponent
            name="securityCode"
            control={control}
            errorMessage={errors['securityCode']}
            label="Security code"
            type="password"
            placeholder="CVV"
            rules={getMessageError('securityCode')}
            onChange={onCvvChange}
          />
          <CardInputIcon style={{ top: '40px' }}>
            <Popover text={helpText}>
              <CvvInfo>?</CvvInfo>
            </Popover>
          </CardInputIcon>
        </InputWrapper>
        <ErrorMessage text={error} isShow={error} style={{ marginTop: '20px' }} isForm />
        {children}
        <Button name="Confirm" minWidth="100%" type="submit" isDisabled={isDisabled} isLoading={isFetching} margin="30px 0 0" />
      </form>
    </FormWrapper>
  );
};

export default Form;
