import alertify from 'alertifyjs'
import 'alertifyjs/build/css/alertify.css';

alertify.set('notifier', 'position', 'bottom-right');

export const successNotification = (message) => {
  alertify.success(message)
}

export const errorNotification = (message) => {
  alertify.error(message)
}