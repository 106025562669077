const SET_TIMER = 'SET_TIME';
const setTimer = (payload) => ({
  type: SET_TIMER,
  payload,
});

const SET_GOAL_GRAPH_DATA = 'SET_GOAL_GRAPH_DATA';
const setGoalGraphData = (payload) => ({
  type: SET_GOAL_GRAPH_DATA,
  payload,
});

const SET_PURCHASE_EVENT_SENT = 'SET_PURCHASE_EVENT_SENT';
const setPurchaseEventSent = (payload) => ({
  type: SET_PURCHASE_EVENT_SENT,
  payload,
});

const SET_PROFILE_EMAIL = 'SET_PROFILE_EMAIL';
const setEmailData = (payload) => ({
  type: SET_PROFILE_EMAIL,
  payload,
});

export { SET_TIMER, setTimer, SET_GOAL_GRAPH_DATA, setGoalGraphData, SET_PURCHASE_EVENT_SENT, setPurchaseEventSent, SET_PROFILE_EMAIL, setEmailData };
