const checkAgeRules = (value) => {
  const valueResult = Number(value);
  if (valueResult < 1200 || valueResult > 5000) {
    return 'Please enter a value between 1200 and 5000';
  }
};

export const getCaloriesMessageError = () => {
  return {
    validate: (value) => {
      return checkAgeRules(value);
    },
    required: 'This field is required.',
  };
};