// import { FeedBack } from './FeedBack';

export const Article = ({ data }) => {
  return (
    <>
      <div className="article" dangerouslySetInnerHTML={{ __html: data }} />
      {/*<FeedBack />*/}
    </>
  );
};
