import { SubTitle } from '@QuizComponents/SubTitle';
import { RadioButton } from '@Components/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { QuizContext } from '@Pages/Quiz/QuizV2';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { getAgeContent } from '../getAgeContent';
import { TypicalLunchWrapper } from './styled';
import { radioButtonsData } from './radioButtonsData';
import { getWordsContent } from '../getWordsContent';

export const TypicalLunchV2 = ({ handleRadioButton, subStepName }) => {
  const dispatch = useDispatch();
  const quiz = useSelector((store) => store.quiz);
  const age = quiz?.['birthDate'].birthDate;
  const subtitleText = `In your ${getAgeContent(
    age
  )}, the food you pair in your meals can have a significant impact on your body\'s ability to burn fat. Which one of the following best describes your typical lunch?`;

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 2,
        totalSteps: 11,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step3');
  }, []);

  return (
    <TypicalLunchWrapper>
      <SubTitle style={{ margin: '20px' }} text={subtitleText} />
      {radioButtonsData.map(({ text, value }) => {
        return (
          <RadioButton
            key={text}
            id={text}
            text={getWordsContent(text)}
            name={subStepName}
            checked={quiz['typical-lunch'] === value}
            onClick={handleRadioButton}
            value={value}
          />
        );
      })}
    </TypicalLunchWrapper>
  );
};
