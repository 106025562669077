import { Route, BrowserRouter as Router, Switch, useHistory } from 'react-router-dom';
import { routes } from './routes';
import Analytics from 'react-router-ga';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAppVersion } from '@Utilities/getAppVersion';
import { setAppVersion } from '@Store/actions/appVersion';
import config from '@/config';
import { storeUtmParams } from '@Utilities/storeUtmParams';

function HistoryAppVersionWrapper({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    storeUtmParams();
    const APP_VERSION = getAppVersion(history.location.pathname);
    dispatch(setAppVersion(APP_VERSION));
  }, []);

  return <>{children}</>;
}

function App() {
  return (
    <div className="App">
      <Router>
        <Analytics id={config.gaid}>
          <HistoryAppVersionWrapper>
            <Switch>
              {routes.map((route) => (
                <Route key={route.path} {...route} />
              ))}
            </Switch>
          </HistoryAppVersionWrapper>
        </Analytics>
      </Router>
    </div>
  );
}

export default App;
