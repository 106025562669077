import { Title } from '@QuizComponents/Title';
import { SubTitle } from '@QuizComponents/SubTitle';
import './styles.scss';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/QuizV2';
import { InputComponent } from '@Components/InputComponent';
import { Button } from '@Components/Button';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { useForm } from 'react-hook-form';
import { getNameMessageError } from './inputValidate';
import { useHistory } from 'react-router-dom';
import { pinterestEvent } from '@Analytics/pinterest';

export const YourNameV2 = ({ subStepName, handleInput }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;
  const quiz = useSelector((store) => store.quiz);

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step1',
        currentStep: 1,
        totalSteps: 8,
      })
    );

    dispatch(
      updateQuizProgressBar({
        stepName: 'step2',
        currentStep: 0,
        totalSteps: 5,
      })
    );

    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 0,
        totalSteps: 5,
      })
    );
    dispatch(
      updateQuizProgressBar({
        stepName: 'step4',
        currentStep: 0,
        totalSteps: 8,
      })
    );
  }, []);

  useEffect(() => {
    setCurrentStepName('step1');
    pinterestEvent('Pagevisit');
  },[]);

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: quiz['name'] ? quiz['name'].name : '',
    },
  });

  const onSubmit = (data) => {
    const { name } = data;
    const event = {
      name: subStepName,
      value: name
    };
    dispatch(
      updateQuiz({
        name: 'name',
        value: { name },
      })
    );
    handleInput(event);
    history.push('high_carb');
  };

  const onChange = (field, value) => {
    const resultValue = value.replace(/[^A-Z0-9]+/gi, '');
    field.onChange(resultValue);
  };

  return (
    <div className='diet-vs-fasting'>
      <Title title='What’s your name?' />
      <SubTitle text='We’re excited to get to know you.' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputComponent
          name='name'
          control={control}
          label='Your name'
          type='text'
          placeholder='Your name'
          padding='0 45px 0 16px'
          onChange={onChange}
          errorMessage={errors['name']}
          rules={getNameMessageError()}
        />
        <Button name='Continue' minWidth='100%' margin='30px 0 0' type='submit' isDisabled={!isValid} />
      </form>
    </div>
  );
};
