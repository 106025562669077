// Card number error message
const checkCardNumber = (value) => {
  if (value.replaceAll(' ', '').length < 14) {
    return 'Invalid credit card number';
  }
};

export const getMessageError = (name) => {
  return {
    validate: (value) => {
      if (name === 'cardNumber') {
        return checkCardNumber(value);
      }

      if (name === 'expiresDate') {
        return checkExpiresDate(value);
      }

      if (name === 'securityCode') {
        return checkCvv(value);
      }
    },
    // required: text?.errorMessages?.reqiured,
    required: 'This field is required.',
  };
};

// Expires Date error message

const checkExpiresDate = (value) => {
  const writeMonth = value.split('/')[0];
  const monthFromInput = writeMonth[0] === '0' ? Number(writeMonth.replace('0', '')) : Number(writeMonth);
  const writeYear = value.split('/')[1];
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear().toString().replace(20, '');

  let errorMessage = 'Invalid';

  if (value.length < 3) {
    errorMessage = `${errorMessage} format`;
    return errorMessage;
  }

  if (monthFromInput < currentMonth && Number(writeYear) < Number(currentYear)) {
    errorMessage = `${errorMessage} Month and Year`;
    return errorMessage;
  }

  if (monthFromInput < currentMonth && Number(writeYear) <= Number(currentYear)) {
    errorMessage = `${errorMessage} Month`;
    return errorMessage;
  }

  if (Number(writeYear) < Number(currentYear)) {
    errorMessage = `${errorMessage} Year`;
    return errorMessage;
  }
};

// Cvv

const checkCvv = (value) => {
  if (value.length < 3) {
    return 'Invalid CVV code';
  }
};
