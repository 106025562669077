import { IntermittentFasting } from './components/IntermittentFasting';
import { Description } from './components/Description';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const Intro = () => {

  const isV2 = useSelector((state) => state.appVersion);
  const link = isV2 ? '/v2' : '';

  return (
    <div className="intro">
      <Switch>
        <Route exact path={`${link}/quiz/intro/step`}>
          <IntermittentFasting />
        </Route>

        <Route exact path={`${link}/quiz/intro/description`}>
          <Description />
        </Route>
      </Switch>
    </div>
  );
};
