import { SubTitle } from '@QuizComponents/SubTitle';
import { Title } from '@QuizComponents/Title';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Button } from '@Components/Button';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { getMonthForProgram } from '@Utilities/getMonthForProgram';
import { AnaliticsGoalGraph } from '@QuizComponents/AnaliticsGoalGraph';
import { NavLink, useHistory } from 'react-router-dom';
import { ForecastWrapper } from './styled';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';
import { ReviewContent } from '@QuizComponents/ReviewContent/reviewContent';
import { setGoalGraph } from '@Middleware/components/securityPayment/actions';

export const ForecastV2 = () => {
  const quiz = useSelector((store) => store.quiz);
  const dispatch = useDispatch();
  const currentWeight =  quiz['your-weight']?.weight;
  const desiredWeight = quiz['your-desired-weight'].weight;
  const subtractWeight = desiredWeight ?  desiredWeight : 'some';
  const history = useHistory();
  const measure = 'imperial' === quiz['your-weight'].measure ? 'lbs' : 'kg';
  const id = history.location?.state?.id ? history.location.state.id : '1';

  const { month, year, day } = getMonthForProgram(currentWeight, desiredWeight, id);

  const getForecastContent = (id) => {
    const forecastContent = {};
    if ('1' === id) {
      forecastContent.link = '/v2/quiz/steps/question/1';
      forecastContent.subtitleDate = `${day} ${month} ${year}`;
      forecastContent.title = <Title title='The only weight loss program you’ll ever need' />;
      forecastContent.event_view = 'Step_3_forecast_view';
      forecastContent.event_click = 'Step_3_forecast_click';
    } else {
      forecastContent.link = '/v2/quiz/steps/medical_condition';
      forecastContent.subtitleDate = `${day} ${month} ${year}`;
      forecastContent.title = '';
      forecastContent.event_view = 'Step_4_forecast_view';
      forecastContent.event_click = 'Step_4_forecast_click';
    }
    return forecastContent;
  };

  const { link, subtitleDate, title, event_view, event_click } = getForecastContent(id);

  const date = {
    month,
    year,
  };

  const subtitleText = `${subtractWeight} ${measure} by ${subtitleDate}`;

  useEffect(() => {
    sendAmplitudeData(event_view);
    dispatch(setGoalGraph());
  }, []);

  useEffect(() => {
    scrollPageToTop();
  }, []);

  return (
    <ForecastWrapper>
      {title}
      <SubTitle style={{ margin: '20px' }} text='Based on your answers and personal goals, you can reach your goal weight of' />
      <SubTitle style={{ margin: '20px', lineHeight: '24px', fontWeight: 'bold', color: '#6BA50D' }} text={subtitleText} />
      <AnaliticsGoalGraph isForecast={true} date={date} isShowBottomValue={false} valueTop={'10px'} valueLeft={'0px'} />
      <SubTitle style={{ textAlign: 'start', margin: '50px auto 20px 0px', fontWeight: 'bold' }} text='Customer reviews' />
      <ReviewContent />
      <NavLink to={link}>
        <Button name='Ok, got it!' margin='30px 0px' handleClick={() => sendAmplitudeData(event_click)} />
      </NavLink>
    </ForecastWrapper>
  );
};
