import { Step4Wrapper } from './styled';
import { Route, Switch, useHistory } from 'react-router-dom';
import { QuestionV2 } from './components/Question';
import { MedicalConditionV2 } from './components/MedicalCondition';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { useDispatch } from 'react-redux';
import { MedicationV2 } from './components/Medication';
import { getEventProperty } from '@Utilities/getEventProperty';
import { sendAmplitudeData } from '@Analytics/amplitude';


export const Step4V2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleRadioButton = (event) => {
    const {name, value} = event.target;
    let eventProperty = {};
    dispatch(
      updateQuiz({
        name,
        value,
      })
    );

    switch (name) {
      case 'medical-condition':
        eventProperty = getEventProperty('Step_4_9_medical_conditions_click', 'MedConditions', value);
        setTimeout(() => {
          history.push('medication');
        }, 300);
        break;
      case 'medication':
        eventProperty = getEventProperty('Step_4_10_Medications_click', 'Medications', value);
        setTimeout(() => {
          history.push('/v2/quiz/good_hands');
        }, 300);
        break;
    }

    sendAmplitudeData(eventProperty.eventName, {
      [eventProperty.name]: eventProperty.value,
    });
  };

  return (
    <Step4Wrapper>
      <Switch>
        <Route exact path='/v2/quiz/steps/question/:id'>
          <QuestionV2 />
        </Route>
        <Route exact path='/v2/quiz/steps/medical_condition'>
          <MedicalConditionV2 subStepName='medical-condition' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/v2/quiz/steps/medication'>
          <MedicationV2 subStepName='medication' handleRadioButton={handleRadioButton} />
        </Route>
      </Switch>
    </Step4Wrapper>
  );
};
