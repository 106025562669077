import styled from '@emotion/styled';

export const LoadingContentWrapper = styled.section``;
export const FakeLoadingWrapper = styled.div`
  margin: 21px 0 10px;
`;

export const LoadingContentTitle = styled.p`
  text-align: center;
  margin-top: 45px;
  color: #787878;
  font-size: 13px;
  line-height: 14px;
  font-weight: 500;
`;

export const ContentContainer = styled.div`
  max-width: 900px;
  position: relative;
  display: flex;
  margin-top: 20px;
  max-height: 300px;
  min-height: 300px;
  overflow: hidden;
`;
export const ContentWrapper = styled.div`
  position: absolute;
  left: ${(props) => (props.isActive ? 0 : '1000px')};
  transition: all 0.6s ease 0s;
  width: 100%;
  margin: auto;
`;
export const ContentPicture = styled.img`
  width: 100%;
  max-width: 260px;
  min-width: 260px;
  max-height: 205px;
  display: block;
  margin: auto;
`;
export const ContentText = styled.p`
  font-size: 15px;
  line-height: 19px;
  color: #000;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
`;
