import styled from '@emotion/styled';

export const QuestionWrapper = styled.section``;
export const QuestionContentWrapper = styled.div``;
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Text = styled.p`
  font-size: 14px;
  line-height: 30px;
  color: #cecece;
`;
