import { SET_GOAL_GRAPH_DATA, SET_TIMER, SET_PURCHASE_EVENT_SENT, SET_PROFILE_EMAIL } from './actions';

const initialState = {
  timer: null,
  isPurchaseEventSent: false
};

export const progressReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIMER:
      return { ...state, timer: action.payload.timer };
      case SET_PROFILE_EMAIL:
      return { ...state, ...action.payload };
    case SET_PURCHASE_EVENT_SENT:
      return { ...state, isPurchaseEventSent: action.payload.isPurchaseEventSent };
    default:
      return state;
  }
};

const INITIAL_STATE_GOAL_GRAPH_DATA = [];

export const goalGraphDataReducer = (state = INITIAL_STATE_GOAL_GRAPH_DATA, action) => {
  switch (action.type) {
    case SET_GOAL_GRAPH_DATA:
      return (state = action.payload);
    default:
      return state;
  }
};
