import './styles.scss';
import { Header } from '../Main/components/Header';
import { useEffect, useState } from 'react';
import { Footer } from '../Main/components/Footer';
import { TermsOfUse } from './components/TermsOfUse';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import axios from 'axios';

export const TermsOrPrivacy = ({ isTerms }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [termsOfUse, setTermsOfUse] = useState(null);
  const [privacyPolicy, setPrivacyPolicy] = useState(null);

  const getAndSetTermsAndPrivacy = async () => {
    const url = '/data/termsOrPrivacy.json';

    const responseTermsAndPrivacy = await axios({
      baseURL: '/',
      url,
      method: 'get',
    });

    setTermsOfUse(responseTermsAndPrivacy.data.termsOrUse);
    setPrivacyPolicy(responseTermsAndPrivacy.data.privacyPolicy);
  };

  useEffect(() => {
    getAndSetTermsAndPrivacy();
  }, []);

  return (
    <div className="terms-or-privacy">
      {termsOfUse && privacyPolicy && (
        <>
          <div className="container">
            <Header setModalIsOpen={setModalIsOpen} isShowPreviousBtn />
            {isTerms ? <TermsOfUse termsOfUse={termsOfUse} /> : <PrivacyPolicy privacyPolicy={privacyPolicy} />}
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};
