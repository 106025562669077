import {
  BMIProgress,
  BMIProgressCircle,
  BMIProgressDot,
  BMIProgressValue,
  BMIProgressValuesWrapper,
  BMISubtitle,
  BMIToolTip,
  BMIWrapper,
} from './styled';
import { Title } from '@QuizComponents/Title';
import { useSelector } from 'react-redux';

export const BMI = () => {
  const PERSONAL_SUMMARY = useSelector((store) => store.quiz['personalSummary']);
  const BMI = PERSONAL_SUMMARY?.bmi;
  const CURRENT_BMI = BMI?.current_bmi;
  const PERCENT = BMI?.percent;
  const getBMIProgress = () => {
    return (
      <>
        <BMIProgress>
          <BMIProgressCircle dotWeight={PERCENT}>
            <BMIProgressDot />
            <BMIToolTip>{CURRENT_BMI}</BMIToolTip>
          </BMIProgressCircle>
        </BMIProgress>
        <BMIProgressValuesWrapper>
          <BMIProgressValue>10</BMIProgressValue>
          <BMIProgressValue>60</BMIProgressValue>
        </BMIProgressValuesWrapper>
      </>
    );
  };
  return (
    <BMIWrapper>
      <Title title="BMI" styleProps={{ padding: 0, margin: 0, textAlign: 'left' }} />
      <BMISubtitle>Your BMI</BMISubtitle>
      {getBMIProgress()}
    </BMIWrapper>
  );
};
