import { Title } from '@QuizComponents/Title';
import { SubTitle } from '@QuizComponents/SubTitle';
import './styles.scss';
import {useContext, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/QuizV2';
import { InputComponent } from '@Components/InputComponent';
import { Button } from '@Components/Button';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeData } from '@Analytics/amplitude';
import {getCaloriesMessageError} from "./inputValidate";

export const CaloriesV2 = ({ subStepName, handleRadioButton }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;
  const quiz = useSelector((store) => store.quiz);

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step1',
        currentStep: 7,
        totalSteps: 8,
      })
    );
  }, []);


  useEffect(() => {
    setCurrentStepName('step1');
  },[]);

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      calories: quiz['calories'] ? quiz['calories'] : null,
    },
  });

  const onSubmit = (data) => {
    const { calories } = data;
    dispatch(
      updateQuiz({
        name: 'calories',
        value: calories
      })
    );
    sendAmplitudeData('Step_1_7_Calories_per_day', {
      Calories: calories,
    });
    history.push('diet_vs_fasting');
  };

  const onChange = (field, value) => {
    const resultValue = value.replace(/[^0-9]/g, '').substr(0, 4);
    field.onChange(resultValue)
  };

  return (
    <div className="calories">
      <Title title="How many calories do you want to consume per day?" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputComponent
          name="calories"
          control={control}
          label="Amount of calories"
          type="text"
          errorMessage={errors['calories']}
          placeholder="Amount of calories"
          padding="0 45px 0 16px"
          rules={getCaloriesMessageError()}
          onChange={onChange}
        />
        <Button name="Continue" minWidth="100%" margin="30px 0 0" type="submit" isDisabled={!isValid} />
      </form>
    </div>
  );
};
