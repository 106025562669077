import { ProgressBar } from '@Components/ProgressBar';
import { Header } from './components/Header';
import './styles.scss';
import { Step1V2 } from './steps/Step1/index';
import { Intro } from './components/Intro';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Step2V2 } from './steps/Step2';
import { useSelector } from 'react-redux';
import { createContext, useEffect, useState } from 'react';
import { Step3V2 } from './steps/Step3';
import { TailoringPlanV2 } from './steps/Step3/components/TailoringPlan';
import { BuyNowV2 } from './steps/BuyNow';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { Step4V2 } from './steps/Step4';
import { GoodHands } from  '../Quiz/steps/BuyNow/components/GoodHands';

export const QuizContext = createContext({});

export const QuizV2 = () => {
  const quiz = useSelector((store) => store.quiz);

  const [currentStepName, setCurrentStepName] = useState('');

  let currentStep = 0;
  let totalSteps = 0;

  switch (currentStepName) {
    case 'step1':
      currentStep = quiz.step1.currentStep;
      totalSteps = quiz.step1.totalSteps;
      break;
    case 'step2':
      currentStep = quiz.step2.currentStep;
      totalSteps = quiz.step2.totalSteps;
      break;
    case 'step3':
      currentStep = quiz.step3.currentStep;
      totalSteps = quiz.step3.totalSteps;
      break;
    case 'step4':
      currentStep = quiz.step4.currentStep;
      totalSteps = quiz.step4.totalSteps;
      break;
    default:
      currentStep = 0;
      totalSteps = 0;
  }

  const location = useLocation();
  const [quizPages] = useState([
    {
      path: '/v2/quiz/intro/description',
      event: 'Intro_2',
    },
    {
      path: '/v2/quiz/steps/diet_vs_fasting',
      event: 'Step_1_8_restrict_diet',
    },
    {
      path: '/v2/quiz/steps/adapting_fasting_program',
      event: 'Step_2_4_loading',
    },
    {
      path: '/v2/quiz/buy_now/restricted_diet_fail',
      event: 'Buy_yo_yo',
    },
    {
      path: '/v2/quiz/buy_now/health_advice',
      event: 'Buy_advice',
    }
  ]);

  useEffect(() => {
    const pathName = location.pathname;
    quizPages.forEach(({ path, event }) => {
      if (path === pathName) {
        sendAmplitudeData(event);
      }
    });
  }, [location.pathname]);

  return (
    <div className='quiz'>
      <Switch>
        <Route path='/v2/quiz/intro'>
          <Intro />
        </Route>

        <Route path='/v2/quiz/steps'>
          <Header currentStep={currentStep} totalSteps={totalSteps} />
          <div className='quiz-progress-wrapper'>
            <ProgressBar occupancyWidth={(100 / quiz.step1?.totalSteps) * quiz.step1?.currentStep} />
            <ProgressBar occupancyWidth={(100 / quiz.step2?.totalSteps) * quiz.step2?.currentStep} />
            <ProgressBar occupancyWidth={(100 / quiz.step3?.totalSteps) * quiz.step3?.currentStep} />
            <ProgressBar occupancyWidth={(100 / quiz.step4?.totalSteps) * quiz.step4?.currentStep} />
          </div>
          <QuizContext.Provider
            value={{
              setCurrentStepName,
            }}
          >
            <Step1V2 />
            <Step2V2 />
            <Step3V2 />
            <Step4V2 />
          </QuizContext.Provider>
        </Route>
        <Route exact path='/v2/quiz/tailoring_plan'>
          <TailoringPlanV2 />
        </Route>
        <Route exact path='/v2/quiz/good_hands'>
          <GoodHands subStepName='good-hands' />
        </Route>
        <Route path='/v2/quiz/buy_now'>
          <BuyNowV2 />
        </Route>
      </Switch>
    </div>
  );
};
