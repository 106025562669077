import { Title } from '@QuizComponents/Title';
import './styles.scss';
import { Button } from '@Components/Button';
import { useForm } from 'react-hook-form';
import { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/QuizV2';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { InputComponent } from '@Components/InputComponent';
import { getMessageError } from './inputValidate';
import { transformHeight } from '@Utilities/conversion';
import {
  InputErrorMessageHeight,
  InputErrorMessageWrapper
} from '@Components/InputComponent/styled';

export const HeightV2 = ({ handleInput }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const quiz = useSelector((store) => store.quiz);
  const measure = useSelector((store) => store.quiz['measure']);
  const [formMeasure, setFormMeasure] = useState(measure || 'imperial');
  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 6,
        totalSteps: 11,
      })
    );

    setCurrentStepName('step3');
  }, []);

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
    setValue,
    getValues
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      'height lb': quiz['your-height'] ? quiz['your-height'].height : null,
      'height inch': quiz['your-height'] ? quiz['your-height'].heightInch : null,
      'height cm': quiz['your-height'] ? quiz['your-height'].height : null,
    },
  });

  const onSubmit = (event) => {
    const result = 'metric' === formMeasure ? event['height cm'] : event['height lb'];
    const { height, heightInches } = transformHeight(measure, formMeasure, result, event['height inch']);

    const data = {
      name: 'your-height',
      value: {
        measure: measure,
        height: height,
        heightInch: heightInches
      },
    };

    sendAmplitudeData('Step_3_6_height', {
      height: Number(height).toFixed(0),
      heightInch: Number(heightInches).toFixed(0)
    });
    handleInput(data);

    if (isValid) {
      setTimeout(() => {
        history.push('lifestyle');
      }, 300);
    }
  };

  const onChangeHeight = (field, value) => {
    const result = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0').substr(0, 4);
    field.onChange(result);
  };

  const resetValue = (measure) => {
    setFormMeasure(measure);
    setValue('height cm', '');
    setValue('height lb', '');
    setValue('height inch', '');
  }

  const errorMesage = errors['height lb'] && errors['height lb'].message ? errors['height lb'].message : null;
  const errorMesageInch = errors['height inch'] && errors['height inch'].message ? errors['height inch'].message : null;
  const errorMesageCm = errors['height cm'] && errors['height cm'].message ? errors['height cm'].message : null;
  const disabledValueImperial = getValues('height lb') <= 0;
  const disabledValue = 'imperial' === formMeasure && disabledValueImperial;

  return (
    <div className='your-weight-and-height'>
      <Title title={'What\'s your height?'} />
      <div className='your-weight'>
        <div
          className={'imperial' === formMeasure ? 'your-weight__item-active' : 'your-weight__item'}
          onClick={() => resetValue('imperial')}>
            in
        </div>
        <div
          className={'metric' === formMeasure ? 'your-weight__item-active' : 'your-weight__item'}
          onClick={() => resetValue('metric')}
        >
          cm
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '20px' }}>
        <div className={'form'}>
          <InputComponent
            name={'metric' === formMeasure ? 'height cm' : 'height lb'}
            control={control}
            label={'metric' === formMeasure ? 'Height (cm)' : 'Height (ft)'}
            type='text'
            step='any'
            placeholder='00 (lb)'
            rules={getMessageError('metric' === formMeasure ? 'height cm' : 'height lb')}
            padding='0 45px 0 16px'
            onChange={onChangeHeight}
            labelStyles={{
              marginTop: '12px',
            }}
            inputHeightStyles={{
              maxWidth: '140px',
              width: '100%',
              marginRight: '5px',
              textAlign: 'center'
            }}
          />
          {'imperial' === formMeasure ?
            <InputComponent
              name='height inch'
              control={control}
              label='Height (inch)'
              type='text'
              step='any'
              placeholder='00 (inch)'
              rules={getMessageError('height inch')}
              padding='0 45px 0 16px'
              onChange={onChangeHeight}
              labelStyles={{
                marginTop: '5px',
              }}
              inputHeightStyles={{
                maxWidth: '140px',
                width: '100%',
                marginLeft: '5px',
                textAlign: 'center'
              }}
            /> : null}
        </div>
        <InputErrorMessageWrapper>
          { 'imperial' === formMeasure ?
            <>
              <InputErrorMessageHeight>{errorMesage}</InputErrorMessageHeight>
              <InputErrorMessageHeight>{errorMesageInch}</InputErrorMessageHeight>
            </> :
            <InputErrorMessageHeight>{errorMesageCm}</InputErrorMessageHeight>
          }
        </InputErrorMessageWrapper>
        <Button name='Continue' minWidth='100%' margin='30px 0 0' type='submit' isDisabled={!isValid || disabledValue} />
      </form>
    </div>
  );
};
