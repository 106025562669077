import './styles.scss';
import { ButtonLoader } from '../Icons';

export const Button = ({
  name = 'title',
  type = 'text',
  minWidth = '264px',
  maxHeight = '52px',
  margin = '0',
  handleClick,
  isSecondaryButton = false,
  isDisabled = false,
  isLoading = false,
  children,
  ...props
}) => {
  return (
    <button
      type={type}
      className={`${isSecondaryButton && 'is-secondary-button'} button`}
      style={{ minWidth, maxHeight, margin }}
      onClick={handleClick}
      disabled={isDisabled}
      {...props}
    >
      {children ? children : ''}
      {isLoading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ButtonLoader className={`${isLoading && 'is-active-fake-loading'} `} />{' '}
        </div>
      ) : (
        <span dangerouslySetInnerHTML={{ __html: name }} />
      )}
    </button>
  );
};
