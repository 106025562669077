import styled from '@emotion/styled';

export const CaloriesWrapper = styled.section`
  margin-top: 14px;
  border: 1px solid #b6e47b;
  border-radius: 20px;
  padding: 18px;
  background-color: #fff;
  margin-bottom: 10px;
  margin-bottom: 14px;
`;

export const CaloriesSubtitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #999eaf;
  text-align: left;
`;

export const CaloriesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const CaloriesCount = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #000;
  font-weight: 500;
`;
// progress
export const CaloriesProgress = styled.div`
  width: 100%;
  height: 6px;
  background-color: #eaf4e4;
  border-radius: 3px;
  margin-top: 54px;
  position: relative;
`;

export const CaloriesProgressCircle = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
`;

export const CaloriesProgressLine = styled.div`
  background-color: #6ba50d;
  width: ${(props) => props.lineWidth}%;
  height: 100%;
  position: relative;
  border-radius: 5px;
`;

export const CaloriesToolTip = styled.div`
  padding: 6px 10px;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  min-width: 56px;
  min-height: 26px;
  position: absolute;
  bottom: 6px;
  left: ${(props) => props.lineWidth + 2}%;
  transform: translate(-20%, 0);
  max-width: 100px;
`;

export const CaloriesProgressValuesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const CaloriesProgressValue = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #999eaf;
`;
