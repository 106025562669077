import './styles.scss';
import React, { useEffect } from 'react';
import { Header } from './components/Header';
import { Content } from './components/Content';
import { Footer } from './components/Footer';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { useGetGaExperimentId } from '@CustomHooks/useGetGaExperimentId';
import { setAmplitudeUserProperties } from '@Analytics/amplitude';
import { FBEvent } from '@Analytics/facebookPixel';

export const Main = () => {
  const experimentIdData = useGetGaExperimentId('');

  useEffect(() => {
    setAmplitudeUserProperties({ ...experimentIdData });
    sendAmplitudeData('Fast_lp');
    FBEvent('Lead');
  }, []);

  return (
    <div className='main'>
      <div className='container'>
        <Header />
        <Content />
      </div>
      <Footer />
    </div>
  );
};
