import Timer from '@Components/Timer';
import { TimerBlockItem } from './styled';

export const TimerBlock = ({ expiryTimestamp, ...props }) => {
  const description = props.description;
  return (
    <TimerBlockItem {...props}>
      {description} <Timer expiryTimestamp={expiryTimestamp} />
    </TimerBlockItem>
  );
};
