export const radioButtonsData = [
  {
    text: 'Gluten',
    value: 'gluten',
  },
  {
    text: 'Lactose',
    value: 'lactose',
  },
  {
    text: 'Peanut',
    value: 'peanut',
  },
  {
    text: 'Shellfish',
    value: 'shellfish',
  },
  {
    text: 'Egg',
    value: 'egg',
  },
  {
    text: 'Fish',
    value: 'fish',
  },
  {
    text: 'Soy',
    value: 'soy',
  },
  {
    text: 'Sugar',
    value: 'sugar',
  },
  {
    text: 'Red meat',
    value: 'red_meat',
  },
  {
    text: 'Tree nuts',
    value: 'tree_nuts',
  },
  {
    text: 'None of above',
    value: 'reset',
  },
];
