const checkNameRules = (value) => {
  if (value.length < 2) {
    return 'Please enter a valid option';
  } else if (value && value.length > 12) {
    return 'The maximum number of characters has been exceeded'
  }
};

export const getNameMessageError = () => {
  return {
    validate: (value) => {
      return checkNameRules(value);
    },
    required: 'This field is required.',
  };
};
