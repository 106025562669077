import './styles.scss';
import { Button } from '@Components/Button';

export const Navigation = ({ handleClick }) => {
  return (
    <div className="navigation">
      {/*<p className="navigation__item">About Fasting</p>*/}
      {/*<p className="navigation__item">Blog</p>*/}
      {/*<Button minWidth="80px" name="Log In" maxHeight="41px" handleClick={handleClick} isSecondaryButton />*/}
      {/*<Button minWidth="115px" name="Sign Up" maxHeight="41px" handleClick={handleClick} />*/}
    </div>
  );
};
