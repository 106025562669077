import styled from '@emotion/styled';

export const AnalyticsOnMyAnswersWrapper = styled.section`
  margin: 70px 0;
`;

export const GraphText = styled.p`
  font-size: 11px;
  line-height: 14px;
  color: #999eaf;
  margin-top: 20px;
  text-align: center;
`;
