export const radioButtonsData = [
  {
    value: 'Only coffee or tea'
  },
  {
    value: 'Less than 2 glasses'
  },
  {
    value: '2-6 glasses'
  },
  {
    value: '7-10 glasses'
  },
  {
    value: 'More than 10 glasses'
  }
];
