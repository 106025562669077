import { Title } from '@QuizComponents/Title';
import { WaterCup } from '@Components/Icons';
import { useContext, useEffect, useState } from 'react';

import './styles.scss';
import { Button } from '@Components/Button';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { sendAmplitudeData } from '@Analytics/amplitude';

let initialWaterCupData = [];

for (let i = 1; i < 11; i++) {
  initialWaterCupData.push({
    id: i,
    fill: '#F4F4F4',
  });
}

export const HowManyDrinkPerDay = ({ subStepName, handleRadioButton, ...props }) => {
  const quiz = useSelector((store) => store.quiz);

  const [waterCupData, setWaterCupData] = useState(initialWaterCupData);

  const handleWaterCup = (id) => {
    if (handleRadioButton) {
      handleRadioButton({ name: subStepName ? subStepName : '', value: id });
    }
  };

  useEffect(() => {
    const id = quiz['how-many-drink-per-day'];

    setWaterCupData(waterCupData.map((el) => (el.id <= id ? { ...el, fill: '#D9EBFD' } : { ...el, fill: '#F4F4F4' })));
  }, [quiz]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step2',
        currentStep: 3,
        totalSteps: 5,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    if (setCurrentStepName) {
      setCurrentStepName('step2');
    }
  }, []);

  const onButtonClick = () => {
    const TOTAL_WATER_CUP = quiz['how-many-drink-per-day'];
    sendAmplitudeData('Step_2_3_water', {
      howMuch_water: TOTAL_WATER_CUP,
    });
  };

  return (
    <div className="how-many-drink-per-day">
      <Title title="How many glasses of water do you drink per day?" />
      <div className="water-cup-wrapper" {...props}>
        {waterCupData.map((cup) => (
          <WaterCup data-fill-cup={cup.fill} onClick={() => handleWaterCup(cup.id)} key={cup.id} />
        ))}
      </div>

      {quiz['how-many-drink-per-day'] && (
        <NavLink to="/quiz/steps/your_gender">
          <Button name="Continue" margin="178px 0 0" handleClick={onButtonClick} />
        </NavLink>
      )}
    </div>
  );
};
