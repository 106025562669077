import React from 'react';

import {
  BenefitPlanWrapper,
  PriceInfoBlock,
  PriceInfoBlockDescription,
  PriceInfoBlockSubDescription,
  PriceInfoBlockGift,
  PriceInfoBlockGiftOldPrice,
  PriceInfoBlockGiftDiscount,
  PriceInfoBlockDivider,
  PriceInfoBlockContent,
  CheckBoxContentWrapper,
  PaymentSectionTitle,
  PaymentContainer,
  PriceInfoBlockIcon,
  PriceInfoBlockContainer,
  PriceInfoBlockLeft,
  SectionLeft,
  SectionRight
} from './styled';
import CheckBoxContent from '@Components/CheckBoxContent';
import { useSelector } from 'react-redux';
import { PaidTrial } from '../PaidTrial';

const BenefitsPlan = ({ getDiffWeight }) => {
  const { quiz, progress } = useSelector((store) => store);
  const { paymentPlan, paidTrial } = quiz || {};
  const { price: paidTrialPrice } = paidTrial || {};
  const { timer } = progress || {};
  const month = paymentPlan.discount.duration;
  const monthToNumber = Number(month.substring(0, 1));
  const discountPlan = paymentPlan?.discount ? (paymentPlan?.discount.price / monthToNumber / 30).toFixed(2) : '';
  const defaultPlan = paymentPlan?.default ? (paymentPlan?.default.price / monthToNumber / 30).toFixed(2) : '';

  const getPriceInfoBlock = () => {
    const priceInfoBlock = {
      firstSection: {
        title: 'Price today:',
        subtitle: `$${paidTrialPrice}`,
        description: '7-Days trial',
      },
      secondSection: {
        title: 'Price after trial:',
        subtitle: 'Price per day',
        description: '',
      },
    };
    const { firstSection, secondSection } = priceInfoBlock;
    const isDiscountTime = 'end' !== timer && paymentPlan?.discount;

    return (
      <PriceInfoBlock>
        <>
          <PriceInfoBlockDivider />
          <PriceInfoBlockContainer>
            <PriceInfoBlockLeft>
              <SectionLeft>{firstSection.title}</SectionLeft>
              <SectionRight>
                <PriceInfoBlockDescription>
                  <PriceInfoBlockGiftDiscount>{firstSection.subtitle}</PriceInfoBlockGiftDiscount>
                  <PriceInfoBlockSubDescription>{firstSection.description}</PriceInfoBlockSubDescription>
                </PriceInfoBlockDescription>
              </SectionRight>
            </PriceInfoBlockLeft>
          </PriceInfoBlockContainer>
        </>
        <PriceInfoBlockDivider />
        <PriceInfoBlockContainer>
          <PriceInfoBlockLeft>
            <SectionLeft>{secondSection.title}</SectionLeft>
            <SectionRight>
              {isDiscountTime && <PriceInfoBlockGiftOldPrice>${defaultPlan ? defaultPlan : ''}</PriceInfoBlockGiftOldPrice>}
              <PriceInfoBlockContent>
                <PriceInfoBlockGift>
                  <PriceInfoBlockGiftDiscount>${isDiscountTime ? discountPlan : defaultPlan}</PriceInfoBlockGiftDiscount>
                  {isDiscountTime && <PriceInfoBlockIcon>🎁</PriceInfoBlockIcon>}
                </PriceInfoBlockGift>
                <PriceInfoBlockDescription>
                  <p style={{ fontSize: '11px', lineHeight: '12px', color: '#3F5366' }}>{secondSection.subtitle}</p>
                  <PriceInfoBlockSubDescription>{secondSection.description}</PriceInfoBlockSubDescription>
                </PriceInfoBlockDescription>
              </PriceInfoBlockContent>
            </SectionRight>
          </PriceInfoBlockLeft>
        </PriceInfoBlockContainer>
        <PriceInfoBlockDivider style={{ marginBottom: 0 }} />
      </PriceInfoBlock>
    );
  };

  const yourDesiredWeight = quiz?.['your-desired-weight'].weight;
  const howActiveYourJob = quiz?.['how-active-your-job'];
  const yourBodyType = quiz?.['your-body-type'];
  const yourBodyShape = quiz?.['your-body-shape'];
  const { gender } = quiz || {};
  const checkboxItems = {
    left: [
      {
        title: `Reach your target weight of ${yourDesiredWeight ? yourDesiredWeight : ''} lbs`,
        identificator: 'reach',
      },
      {
        title: 'Support to sustain your success',
        identificator: 'support',
      },
      {
        title: `For a ${howActiveYourJob ? howActiveYourJob : ''} lifestyle`,
        identificator: 'lifestyle',
      },
    ],
    right: [
      {
        title: 'male' === gender ? `${yourBodyType} body type` : `${yourBodyShape} body shape`,
        identificator: 'bodyType',
      },
      {
        title: 'Works with any type of diet',
        identificator: 'diet',
      },
      {
        title: 'Motivating personal health insights',
        identificator: 'healthInsights',
      },
    ],
  };

  return (
    <PaymentContainer>
      <PaymentSectionTitle>Fasting Benefits</PaymentSectionTitle>

      <BenefitPlanWrapper>
        <CheckBoxContentWrapper>
          <CheckBoxContent checkboxItems={checkboxItems.left} getDiffWeight={getDiffWeight} />
        </CheckBoxContentWrapper>

        <CheckBoxContentWrapper>
          <CheckBoxContent checkboxItems={checkboxItems.right} getDiffWeight={getDiffWeight} />
        </CheckBoxContentWrapper>
      </BenefitPlanWrapper>
      <PaidTrial/>
      {getPriceInfoBlock()}
    </PaymentContainer>
  );
};

export default BenefitsPlan;
