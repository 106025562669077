import { Title } from '@QuizComponents/Title';
import { RadioButton } from '@Components/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { SubTitle } from '@QuizComponents/SubTitle';
import { radioButtonsData } from './radioButtonsData';
import { sendAmplitudeData } from '@Analytics/amplitude';

export const MedicalCondition = ({ subStepName, handleRadioButton }) => {
  const quiz = useSelector((store) => store.quiz);

  const dispatch = useDispatch();
  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step4',
        currentStep: 7,
        totalSteps: 8,
      })
    );
    sendAmplitudeData('Step_4_9_medical_conditions_view');
  }, []);

  useEffect(() => {
    setCurrentStepName('step4');
  }, []);

  return (
    <>
      <Title title='Any medical conditions?' />
      <SubTitle style={{ margin: '20px' }} text={'Please let us know if you have any of the following conditions'} />
      {radioButtonsData.map(({ value }) => {
        return (
          <RadioButton
            key={value}
            id={value}
            text={value}
            name={subStepName}
            checked={quiz['medical-conditions'] === value}
            onClick={handleRadioButton}
            value={value}
          />
        );
      })}
    </>
  );
};
