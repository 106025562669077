import './styles.scss';
import { Emoji, ThunderLightning } from '@Components/Icons';

export const ErrorMessage = ({ text, isShow, isThunderLightning = false, isForm, ...props }) => {
  return (
    <div className={`error-message ${isShow && 'is-show'}`} {...props}>
      {isForm ? (
        <div className="error-message-icon">😔</div>
      ) : (
        <div className="error-message-icon">{isThunderLightning ? <ThunderLightning /> : <Emoji />}</div>
      )}
      <p className="error-message-text">{text}</p>
    </div>
  );
};
