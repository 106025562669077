import './styles.scss';
import { ReviewersCardV2 } from './ReviewersCard2';

import female from './famele.png';
import femaleX2 from './female@2x.png';
import male from './male.png';
import maleX2 from './male@2x.png';
import { useContext, useEffect, useState } from 'react';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import {useDispatch, useSelector} from 'react-redux';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { FakeLoading } from '@Components/FakeLoading';
import { useHistory } from 'react-router-dom';

export const AdaptingFastingProgram = () => {
  const dispatch = useDispatch();
  const text = 'I tried to lose weight a lot of different ways, but they usually didn\'t work. So, I put together my own way. Fasting was actually the most important for me. I need control over what and when I eat and this is what worked for me.'
  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step2',
        currentStep: 5,
        totalSteps: 5,
      })
    );

    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 0,
        totalSteps: 10,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step2');
  }, []);

  const history = useHistory();
  const quiz = useSelector((store) => store.quiz);
  const [isActiveFakeLoading, setIsActiveFakeLoading] = useState(true);

  useEffect(() => {
    let timerId = setTimeout(() => {
      setIsActiveFakeLoading(false);

      history.push('birthDate');
    }, 15000);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <div className='adapting-fasting-program'>
      <FakeLoading isActiveFakeLoading={isActiveFakeLoading} />
      <p className='adapting-fasting-program__title'>Adapting your fasting program to fit your answers</p>
      <ReviewersCardV2
        text={text}
        src={'female' === quiz['gender'] ? female : male}
        medium={'female' === quiz['gender'] ? femaleX2 : maleX2}
      />
    </div>
  );
};
