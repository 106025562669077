import styled from '@emotion/styled';

export const LogoBlockWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.justify ? 'flex-start' : 'center')};
  margin: ${(props) => (props.justify ?  '0 -10px' : 0)};
`;
export const LogoBlockIcon = styled.section``;
export const LogoBlockTitle = styled.section`
  font-size: 15px;
  line-height: 21px;
  font-weight: 700;
  margin-left: 10px;
`;
