import { InputBlock, Input, InputErrorMessage, InputWrapper, InputLabel } from './styled';
import { Controller } from 'react-hook-form';

export const InputComponent = ({ control, children, ...props }) => {
  const isCustomInputValueValidation = (value) => {
    if (props.customInputValueValidate) {
      return props.customInputValueValidate(value);
    }

    return false;
  };

  const customOnChange = (field, value) => {
    if (props.onChange) {
      props.onChange(field, value);
    } else {
      field.onChange(value);
    }
  };

  const onHandleChange = (event, field) => {
    const { value } = event.target;
    if (isCustomInputValueValidation(value)) {
      event.preventDefault();
    } else {
      customOnChange(field, value);
    }
  };
  const inputHeight = props.inputHeight;
  const inputStyles = props.inputStyles;
  const labelStyles = props.labelStyles;
  const heightStyles = props.inputHeightStyles
  const icon = props.icon;
  return (
    <InputBlock style={heightStyles}>
      <Controller
        control={control}
        {...props}
        render={({ field }) => (
          <InputWrapper>
            <InputLabel style={labelStyles}>{props?.label}</InputLabel>
            {props.controlInputValue ?
              <Input
              errorMessage={props.errorMessage}
              {...field}
              style={{ padding: props.padding ? props.padding : '0 16', ...inputStyles }}
              type={props.type}
              placeholder={props.placeholder}
              onChange={(event) => onHandleChange(event, field)}
              inputHeight={inputHeight}
              value={props.value}
            /> : <Input
              errorMessage={props.errorMessage}
            {...field}
              style={{ padding: props.padding ? props.padding : '0 16', ...inputStyles }}
              type={props.type}
              placeholder={props.placeholder}
              onChange={(event) => onHandleChange(event, field)}
              inputHeight={inputHeight}
              /> }
            {props.errorMessage && <InputErrorMessage>{props.errorMessage.message}</InputErrorMessage>}
            {icon && <>{icon}</>}
          </InputWrapper>
        )}
      />
    </InputBlock>
  );
};
