import { AnaliticsGoalGraphBottomValue, AnaliticsGoalGraphPicture, AnaliticsGoalGraphTopValue, AnaliticsGoalGraphWrapper } from './styled';
import { useSelector } from 'react-redux';
import { graphImageData } from './graphImageData';

export const AnaliticsGoalGraph = ({ date, isShowBottomValue = true, valueTop, valueLeft, isForecast= true }) => {
  const quiz = useSelector((store) => store.quiz);
  const currentWeight = Number(quiz['your-weight']?.weight);
  const measure = 'imperial' === quiz['your-weight'].measure ? 'lbs' : 'kg';
  const images = isForecast ? graphImageData.forecast : graphImageData.checkout_results;
  const { image, image_2x } = images;
  const { month, year } = date ?? '';
  return (
    <AnaliticsGoalGraphWrapper>
      <AnaliticsGoalGraphTopValue top={valueTop} left={valueLeft}>
        {currentWeight} {measure}
      </AnaliticsGoalGraphTopValue>
      <AnaliticsGoalGraphPicture src={image} srcSet={`${image} 320w, ${image_2x} 480w`} alt="score" />
      {isShowBottomValue && (
        <AnaliticsGoalGraphBottomValue>
          by {month} {year}
        </AnaliticsGoalGraphBottomValue>
      )}
    </AnaliticsGoalGraphWrapper>
  );
};
