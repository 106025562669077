import { LogoBlock } from '@QuizComponents/LogoBlock';
import { HeaderResults } from './components/HeaderResults';
import { AnalyticsOnMyAnswers } from './components/AnalyticsOnMyAnswers';
import { PersonalPlanResult } from './components/PersonalPlanResult';
import { ResultsContainer, ResultsWrapper } from './styled';
import { PersonalSummary } from './components/PersonalSummary';
import { useEffect } from 'react';
import { getPersonalSummary } from '@Middleware/components/quizResults/actions';
import { useDispatch } from 'react-redux';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { FBEvent } from '@Analytics/facebookPixel';

export const Results = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPersonalSummary());
    sendAmplitudeData('f_quiz_results_view');
    FBEvent('CompleteRegistration');
  }, []);

  return (
    <ResultsWrapper>
      <ResultsContainer>
        <LogoBlock justify />
        <HeaderResults />
        <AnalyticsOnMyAnswers />
        <PersonalPlanResult />
        <PersonalSummary />
      </ResultsContainer>
    </ResultsWrapper>
  );
};
