import { Title } from '@QuizComponents/Title';
import { RadioButton } from '@Components/RadioButton';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/QuizV2';

export const HowActiveYourJobV2 = ({ subStepName, handleRadioButton }) => {
  const quiz = useSelector((store) => store.quiz);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step2',
        currentStep: 2,
        totalSteps: 5,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step2');
  }, []);

  return (
    <div className="how-active-your-job">
      <Title title="How active is your job?" />

      <RadioButton
        id="Mostly sedentary"
        text="Mostly sedentary"
        onClick={handleRadioButton}
        name={subStepName}
        checked={quiz['how-active-your-job'] === 'Mostly sedentary'}
        value="Mostly sedentary"
      />
      <RadioButton
        id="Moderately active"
        text="Moderately active"
        onClick={handleRadioButton}
        name={subStepName}
        checked={quiz['how-active-your-job'] === 'Moderately active'}
        value="Moderately active"
      />
      <RadioButton
        id="Very active"
        text="Very active"
        onClick={handleRadioButton}
        name={subStepName}
        checked={quiz['how-active-your-job'] === 'Very active'}
        value="Very active"
      />
    </div>
  );
};
