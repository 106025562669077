import styled from '@emotion/styled';

export const AnaliticsGoalGraphWrapper = styled.section`
  position: relative;
  max-width: 293px;
  margin: auto;
`;
export const AnaliticsGoalGraphTopValue = styled.section`
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #d75b4c;
  padding: 9px 7px;
  max-width: 56px;
  box-shadow: 0 8px 11px rgba(15, 15, 15, 0.09);
  border-radius: 8px;
  position: absolute;
  top: ${(props) => (props.top ? props.top : '25px')};
  left: ${(props) => (props.left ? props.left : '17px')};
`;

export const AnaliticsGoalGraphBottomValue = styled.div`
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  background-color: #fff;
  padding: 6px 12px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 1px solid #f4f4f4;
  right: 6px;
  bottom: 17px;
  min-width: 118px;
  max-width: 135px;
  text-align: center;
  word-break: break-word;
`;

export const AnaliticsGoalGraphPicture = styled.img`
  max-width: 293px;
  display: block;
  margin: auto;
`;
