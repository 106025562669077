import styled from '@emotion/styled';

export const DiscountBlockWrapper = styled.section`
  padding: ${(props) => (props.isScrollToDiscountSection ? '24px 16px' : '16px')};
  background: linear-gradient(270deg, rgba(250, 92, 120, 0.12) 0%, rgba(255, 108, 134, 0.12) 49.48%, rgba(255, 171, 186, 0.12) 100%);
  border: 1px solid #fb5d79;
  margin: ${(props) => (props.isScrollToDiscountSection ? '60px 0 60px -16px' : '60px auto')};
  background: #ffecef;
  z-index: 20;
  position: sticky;
  top: ${(props) => (props.isExpiredDiscount || props.isScrollToDiscountSection ? '-52px' : '-58px')};
  border-radius: ${(props) => (props.isScrollToDiscountSection ? '0px 0px 20px 20px' : '12px')};
  width: ${(props) => (props.isScrollToDiscountSection ? '100vw' : '100%')};
  max-width: ${(props) => (props.isScrollToDiscountSection ? '428px' : '288px')};
  min-height: 155px;
  @media (min-width: 428px) {
    margin: 60px auto;
  }
`;

export const DiscountGiftWrapper = styled.section`
  position: ${(props) => (props.isScrollToDiscountSection ? 'relative' : 'static')};
  bottom: -44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:  ${(props) => (props.isScrollToDiscountSection ? '0' : '12px 0')};
  gap: 12px;
`;

export const DiscountGiftIcon = styled.div`
  font-size: 24px;
  margin-right: 12px;
  padding-top: 5px;
`;

export const DiscountBlockTitle = styled.p`
  text-align: start;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
`;

export const DiscountBlockText = styled.p`
  font-size: 10px;
  line-height: 10px;
  color: #6f879d;
  text-align: center;
`;

export const DiscountBlockContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
`;

export const DiscountBlockIcon = styled.div`
  font-size: 26px;
  margin-right: 10px;
`;

export const StarWrapper = styled.div`
  position: relative;
`;

export const StarText = styled.span`
  font-weight: 700;
  font-size: 13px;
  line-height: 110%;
  color: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
`;

export const DiscountDescription = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #17181B;
`;

export const TimerBlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  background: #FFFFFF;
  border-radius: 100px;
  width: 86px;
  height: 33px;
`;
