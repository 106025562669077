import { AnalyticsOnMyAnswersWrapper, GraphText } from './styled';
import { KilogramsBlock } from './components/KilogramsBlock';
import { AnaliticsGoalGraph } from '@QuizComponents/AnaliticsGoalGraph';
import { useSelector } from 'react-redux';
import { getMonthForProgram } from '@Utilities/getMonthForProgram';

export const AnalyticsOnMyAnswers = () => {
  const quiz = useSelector((store) => store.quiz);
  const currentWeight = quiz['your-weight'].weight;
  const desiredWeightMeasure = quiz['your-desired-weight'].weight

  const { month, year } = getMonthForProgram(currentWeight, desiredWeightMeasure);

  const date = {
    month,
    year,
  };

  return (
    <AnalyticsOnMyAnswersWrapper>
      <KilogramsBlock date={date} />
      <AnaliticsGoalGraph date={date} isForecast={false} />
      <GraphText>
        *Based on the data of users who log their progress in the app. Consult your physician first. The chart is a non-customized illustration and
        results may vary
      </GraphText>
    </AnalyticsOnMyAnswersWrapper>
  );
};
