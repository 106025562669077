import { Route, Switch, useHistory } from 'react-router-dom';
import { HowWellYouSleep } from './components/HowWellYouSleep';
import { HowActiveYourJob } from './components/HowActiveYourJob';
import { HowMatchWaterDrink } from './components/HowManyDrinkPerDay';
import { YourGender } from './components/YourGender';
import { AdaptingFastingProgram } from './components/AdaptingFastingProgram';
import { useDispatch } from 'react-redux';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { getEventProperty } from '@Utilities/getEventProperty';

export const Step2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const checkAndReturnNextPath = (name) => {
    let path = '';
    let eventUrl = '';
    if ('how-well-you-sleep' === name) {
      eventUrl = 'how_well_you_sleep';
      path = 'how_active_your_job';
    } else if ('how-active-your-job' === name) {
      eventUrl = 'how_active_your_job';
      path = 'how_many_drink_per_day';
    } else if ('drinkWater' === name) {
      eventUrl = 'how_many_drink_per_day';
      path = 'your_gender';
    } else if ('gender' === name) {
      eventUrl = 'your_gender';
      path = 'adapting_fasting_program';
    }

    return { path, eventUrl };
  };

  const sendEventWithProperty = (path, value) => {
    let eventProperty = {};
    if ('how_well_you_sleep' === path) {
      eventProperty = getEventProperty('Step_2_1_sleep', 'how_well', value);
    } else if ('how_active_your_job' === path) {
      eventProperty = getEventProperty('Step_2_2_job', 'how_active', value);
    } else if ('how_many_drink_per_day' === path) {
      eventProperty = getEventProperty('Step_2_3_water', 'howMuch_water', value);
    } else if ('your_gender' === path) {
      eventProperty = getEventProperty('Step_2_4_gender', 'gender', value);
    } else if ('adapting_fasting_program' === path) {
      eventProperty = getEventProperty('Step_2_4_loading');
    }

    if (eventProperty.eventName) {
      sendAmplitudeData(eventProperty.eventName, {
        [eventProperty.name]: eventProperty.value,
      });
    }
  };

  const handleRadioButton = (event) => {
    const name = event && event.target ? event.target.name : event.name;
    const value = event && event.target ? event.target.value : event.value;
    dispatch(
      updateQuiz({
        name,
        value,
      })
    );
    const path = checkAndReturnNextPath(name);
    sendEventWithProperty(path.eventUrl, value);

    if (path) {
      setTimeout(() => {
        history.push(path.path);
      }, 300);
    }
  };

  return (
    <div className='quiz-step2'>
      <Switch>
        <Route exact path='/quiz/steps/how_well_you_sleep'>
          <HowWellYouSleep subStepName='how-well-you-sleep' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/quiz/steps/how_active_your_job'>
          <HowActiveYourJob subStepName='how-active-your-job' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/quiz/steps/how_many_drink_per_day'>
          <HowMatchWaterDrink subStepName='drinkWater' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/quiz/steps/your_gender'>
          <YourGender subStepName='gender' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/quiz/steps/adapting_fasting_program'>
          <AdaptingFastingProgram />
        </Route>
      </Switch>
    </div>
  );
};
