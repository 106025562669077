import styled from '@emotion/styled';

export const GetExcitedWrapper = styled.section`
  border: 1px solid #949cf0;
  border-radius: 20px;
  padding: 18px;
  background-color: #fff;
  margin-bottom: 14px;
`;

export const GetExcitedSubTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #999eaf;
  text-align: left;
`;

export const GetExcitedContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 21px;
`;

export const GetExcitedLeftBlock = styled.div``;

export const GetExcitedRightBlock = styled.div``;

export const GetExcitedPhoto = styled.img`
  max-width: 140px;
  max-height: 250px;
`;

export const GetExcitedPhotoDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #ff6a6b;
  font-weight: 700;
`;
