import { useEffect, useState } from 'react';
import { ApplePay } from './components/ApplePay';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { PaymentCompanies } from './components/IconBlock';
import { Wrapper, WrapperCartButton, WrapperCartButtonText } from './styled';

const PaymentMethodsContent = () => {
  const [isToggled, setIsToggled] = useState({
    first : false,
    second : true
  });

  const handleClick = (id) => {
    if ('applePay' === id  && !isToggled.first || 'creditCard' === id && !isToggled.second) {
      setIsToggled({first: !isToggled.first, second: !isToggled.second});
    }
  }

  useEffect(() => {
    if (isToggled.first) {
      sendAmplitudeData('y_pre-checkout_applePay_button_click');
    }
  }, [isToggled.first]);

  return (
    <Wrapper>
      <ApplePay isToggled={isToggled.first} handleClick={() => handleClick('applePay')} />
      <WrapperCartButton isToggled={isToggled.second} onClick={() => handleClick('creditCard')}>
        <WrapperCartButtonText>Credit card</WrapperCartButtonText>
        <PaymentCompanies />
      </WrapperCartButton>
    </Wrapper>
  );
};

export default PaymentMethodsContent;
