export const PrivacyPolicy = ({ privacyPolicy }) => {
  return (
    <>
      <h2 className="title">{privacyPolicy.title}</h2>

      <ul className="rules-list">
        {privacyPolicy.rulesList.map((rule, index) =>
          rule.isSubtitle ? (
            <li className="rules-list__item-subtitle" key={index}>
              {rule.value}
            </li>
          ) : (
            <li className="rules-list__item-text" dangerouslySetInnerHTML={{ __html: rule.value }} key={index} />
          )
        )}
      </ul>
    </>
  );
};
