import styled from '@emotion/styled';

export const KilogramsBlockWrapper = styled.section`
  margin: 10px 0;
`;

export const CurrentWeightWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const CurrentWeightInActive = styled.p`
  font-size: 24px;
  line-height: 48px;
  color: rgba(255, 106, 107, 0.4);
`;

export const CurrentWeightActive = styled.div`
  margin: 0 28px;
`;

export const CurrentWeightValue = styled.p`
  font-size: 48px;
  line-height: 48px;
  color: #ff6a6b;
`;

export const CurrentWeightMeasure = styled.p`
  font-size: 24px;
  line-height: 32px;
  color: #ff6a6b;
  text-align: center;
`;

export const KilogramsBlockText = styled.p`
  font-size: 20px;
  line-height: 28px;
  color: #656c7b;
  font-weight: 700;
  text-align: center;
  margin-bottom: 70px;
`;
