import { SubTitle } from '@QuizComponents/SubTitle';
import { Title } from '@QuizComponents/Title';
import { RadioButton } from '@Components/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { ImportantEventWrapper } from './styled';
import { InputComponent } from '@Components/InputComponent';
import { Button } from '@Components/Button';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { radioButtonsData } from './radioButtonsData';
import { getInputMessageError } from './inputValidate';
import { trimSentence } from '@Utilities/trim';

export const ImportantEvent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const radioButtonValue = useSelector((store) => store.quiz?.['important-event']?.radioButtonValue);
  const inputValue = useSelector((store) => store.quiz?.['important-event']);
  const inputText = useSelector((store) => store.quiz?.['important-event']?.radioButtonText);

  const [state, setState] = useState({
    valueInput: inputText ? inputText : null,
    radioButtonValue: radioButtonValue && !inputText ? radioButtonValue : null,
    radioButtonActive: true,
    radioButtonId: null,
  });

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 8,
        totalSteps: 11,
      })
    );
    sendAmplitudeData('Step_3_8_important_event_view');
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step3');
  }, []);

  const {
    control,
    formState: { isDirty, isValid, errors },
    clearErrors,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      ['radioButtonValue']: radioButtonValue ? radioButtonValue : null,
    },
  });

  const onSubmit = (data) => {
    const inputValue = trimSentence(data.radioButtonValue);
    const result = {
      radioButtonValue: inputValue,
      radioButtonText: state.valueInput
    };
    dispatch(
      updateQuiz({
        name: 'important-event',
        value: result,
      })
    );
    sendAmplitudeData('Step_3_8_important_event_click', {
      ImportantEvent: result.radioButtonValue
    });
    history.push('event_day');
  };

  const onChange = (field, value) => {
    const resultValue = value.replace(/[^A-Z0-9 ]+/gi, '');
    setState((prevState) => ({
      ...prevState,
      valueInput: resultValue
    }));
    if (resultValue.length > 0) {
      setState((prevState) => ({
        ...prevState,
        radioButtonActive: false
      }));
      field.onChange(resultValue);
    }
  };

  const getCheckedData = () => {
    return !((isDirty || !!(inputValue || radioButtonValue)) && isValid);
  };

  const isDisabled = getCheckedData();

  const handleClick = (e) => {
    clearErrors('radioButtonValue');
      setState((prevState) => ({
        ...prevState,
        radioButtonValue: e.target.value,
        radioButtonId: e.target.id,
        radioButtonActive: true,
        valueInput: ''
      }));
  };

  return (
    <ImportantEventWrapper>
      <Title title='What upcoming event motivates you to lose weight?' />
      <SubTitle style={{ margin: '20px' }} text='You are more likely to succeed if you strive for something important.' />
      <form onSubmit={handleSubmit(onSubmit)}>
        {radioButtonsData.map(({ value }) => {
          return (
            <RadioButton
              key={value}
              id={value}
              text={value}
              name={'radioButtonValue'}
              checked={state?.radioButtonActive ? (state?.radioButtonValue === value) : null}
              value={value}
              control={control}
              controlStatus
              onClick={(e) => {
                handleClick(e, state?.radioButtonValue ? state?.radioButtonValue : null)
              }}
            />
          );
        })}
        <InputComponent
          name='radioButtonValue'
          control={control}
          type='text'
          placeholder='Other occasion'
          padding='0 45px 0 16px'
          onChange={onChange}
          value={state?.valueInput}
          errorMessage={errors['radioButtonValue']}
          rules={getInputMessageError()}
          controlInputValue
        />
        <Button name='Continue' minWidth='100%' margin='30px 0px' type='submit' isDisabled={isDisabled} />
      </form>
    </ImportantEventWrapper>
  );
};
