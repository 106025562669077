export const graphImageData = {
  forecast: {
    image: `/images/forecast-graph.png`,
    image_2x: `/images/forecast-graph@2x.png`,
  },
  checkout_results: {
    image: `/images/results/goal-graph.png`,
    image_2x: `/images/results/goal-graph@2x.png`,
  },
};
