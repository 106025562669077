import styled from '@emotion/styled';

export const InfoSafetyBlockStyle = styled.div`
  display: flex;
  margin-bottom: 30px;
  ${(props) => ({...props.styleProps})};
`;

export const InfoSafetyBlockWrapper = styled.div`
  flex-basis: calc(100% / 3);
`;

export const InfoSafetyBlockWrappeItem = styled.div`
  text-align: center;
  width: 24px;
  height: 24px;
  display: flex;
  margin: auto;
`;
export const InfoSafetyBlockWrappeItemText = styled.div`
  margin-top: 8px;
  font-size: 10px;
  line-height: 13px;
  color: #878787;
  text-align: center;
`;