import { Title } from '@QuizComponents/Title';
import { SubTitle } from '@QuizComponents/SubTitle';
import { useHistory } from 'react-router-dom';
import { Button } from '@Components/Button';
import { Leaf } from '@Components/Icons';
import { Header } from '@QuizComponents/Header';
import { useEffect } from 'react';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';

export const HealthAdvice = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('your_email');
  };

  useEffect(() => {
    scrollPageToTop();
  }, []);

  return (
    <div className="health-advice">
      <Header isShowPreviousBtn={false} isShowNumberQuestion={false} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Leaf />
      </div>
      <Title title="Health Advice" styleProps={{ margin: '24px 0 6px', minWidth: '100%' }} />
      <SubTitle text="Our goal is to empover you" />

      <p style={{ margin: '24px 0 20px', fontSize: '14px', lineHeight: '20px' }}>
        When practiced correctly and consistently, intermittent fasting can be an incredible way to improve your physical and mental health. Creating
        habits that support sustainable fat loss, boost your well-being, and inspire a sense of fulfilment.
      </p>
      <p style={{ marginBottom: '20px', fontSize: '14px', lineHeight: '20px' }}>
        When it comes to our users, one of our main concerns is the safe practice of intermittent fasting. When carried out properly, fasting can lead
        to some unbelievable results. But when it isn’t – it can turn out to be dangerous. We guide you as much as we can, but what you do should
        always feel right.
      </p>
      <p style={{ marginBottom: '20px', fontSize: '14px', lineHeight: '20px' }}>
        If it doesn’t, always make sure to seek professional medical advice, especially if you have a pre-existing condition. And if it means you need
        to slow down, that’s absolutely fine. Just be patient and gentle with yourself. Take it step by step and tweak any challenge to suit what you
        can handle for now.
      </p>
      <p style={{ fontSize: '14px', lineHeight: '20px' }}>
        Our goal is to empower you to take your health back in your own hands, change your life for the better and create a new, healthier you!
      </p>

      <Button name="Continue" margin="24px 0" minWidth="100%" handleClick={handleClick} />
    </div>
  );
};
