import {
  ContentWrapper,
  NutritionBlockContent,
  NutritionBlockContentIcon,
  NutritionBlockContentSubTitle,
  NutritionBlockContentTitle,
  NutritionBlockContentWrapper,
  NutritionBlockTitle,
  NutritionBlockWrapper,
} from './styled';

export const NutritionBlock = () => {
  const getContent = (title, subTitle, pictureName, margin) => {
    return (
      <NutritionBlockContent style={{ margin }}>
        <NutritionBlockContentIcon
          src={`/images/payment/${pictureName}.png`}
          srcSet={`/images/payment/${pictureName}.png 320w, /images/payment/${pictureName}@2x.png 480w`}
        />
        <NutritionBlockContentWrapper>
          <NutritionBlockContentTitle>{title}</NutritionBlockContentTitle>
          <NutritionBlockContentSubTitle>{subTitle}</NutritionBlockContentSubTitle>
        </NutritionBlockContentWrapper>
      </NutritionBlockContent>
    );
  };
  return (
    <NutritionBlockWrapper>
      <NutritionBlockTitle>Our programs are developed by world-class nutrition experts</NutritionBlockTitle>

      <ContentWrapper>
        {getContent('Will Jenkins', 'MS, RD, LD / Certified Physiologist', 'boy')}
        {getContent('Nelly Jefferson', 'PhD, RD / Licensed Nutritionist', 'girl', '14px 0 0')}
      </ContentWrapper>
    </NutritionBlockWrapper>
  );
};
