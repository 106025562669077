import {
  ButtonsWrapper,
  QuestionContentWrapper,
  QuestionWrapper,
  Text,
  TextWrapper
} from './styled';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { quizProgressBarData } from './quizProgressBarData';
import { Title } from '@QuizComponents/Title';
import { SubTitle } from '@QuizComponents/SubTitle';
import { Button } from '@Components/Button';
import { Route, Switch, useHistory } from 'react-router-dom';
import { quizContentData } from './quizContentData';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';

export const Question = () => {
  const dispatch = useDispatch();
  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;
  useEffect(() => {
    quizProgressBarData.forEach(({ stepName, currentStep, totalSteps }) => {
      dispatch(
        updateQuizProgressBar({
          stepName,
          currentStep,
          totalSteps,
        })
      );
    });
    setCurrentStepName('step4');
  }, []);

  const history = useHistory();

  const quiz = useSelector((store) => store.quiz);
  useEffect(() => {
    scrollPageToTop();
  }, []);
  useEffect(() => {
    const questionId = Number(history.location.pathname.split('/')[4]);
    dispatch(
      updateQuizProgressBar({
        stepName: 'step4',
        currentStep: questionId,
        totalSteps: 8,
      })
    );
    sendEventOnMount(questionId);
  }, [history.location.pathname]);

  const sendEventOnMount = (id) => {
    let eventName = `Step_4_${id}_statement${id}_view`;
    sendAmplitudeData(eventName);
  };

  const getBackgroundColor = (value, id) => {
    const buttonValue = quiz[`question${id}`] ? quiz[`question${id}`].questionValue : 0;
    const color = Number(buttonValue) === value ? '#EDF4E4' : '#F4F4F4';
    return {
      backgroundColor: color,
    };
  };

  const sendEventOnClick = (id, value) => {
    const eventData = {
      eventName: '',
      eventProperty: '',
      value,
    };
    eventData.eventName = `Step_4_${id}_statement${id}_click`;
    eventData.value = value;

    const eventKey = 1 === id ? 'do_you_agree' : 'do_you_agree' + id;
    sendAmplitudeData(eventData.eventName, {
      [eventKey]: eventData.value,
    });
  };
  const onButtonClick = (event, id) => {
    const value = event.currentTarget.getAttribute('value');
    dispatch(
      updateQuiz({
        name: `question${id}`,
        value: {
          questionValue: value,
          id,
        },
      })
    );

    const ID = id - 1;
    sendEventOnClick(ID, value);
    const currentSteps = id;

    if (id < 7) {
      dispatch(
        updateQuizProgressBar({
          stepName: 'step4',
          currentStep: currentSteps,
          totalSteps: 8,
        })
      );
    }

    if (7 === id) {
      setTimeout(() => {
        history.push('/quiz/tailoring_plan', { prev: 'Question' });
      }, 300);
    } else {
      setTimeout(() => {
        history.push(`/quiz/steps/question/${id}`);
      }, 300);
    }
  };

  const getButton = (valueOrName, id) => {
    const buttonStyles = {
      maxWidth: '52px',
      color: '#4F4F4F',
    };
    return (
      <Button
        name={valueOrName}
        value={valueOrName}
        style={{ ...buttonStyles, ...getBackgroundColor(valueOrName, id) }}
        onClick={(event) => onButtonClick(event, id)}
      />
    );
  };
  const getQuestionContent = (title, subtitle, styles, id) => {
    return (
      <QuestionContentWrapper>
        <Title title={title} styleProps={{ fontSize: '15px', lineHeight: '23px', margin: '20px auto 6px' }} />
        <SubTitle text={subtitle} style={{ marginBottom: '20px' }} />
        <ButtonsWrapper>
          {getButton(1, id)}
          {getButton(2, id)}
          {getButton(3, id)}
          {getButton(4, id)}
          {getButton(5, id)}
        </ButtonsWrapper>
        <TextWrapper>
          <Text>Not at all</Text>
          <Text>Totally</Text>
        </TextWrapper>
      </QuestionContentWrapper>
    );
  };

  return (
    <QuestionWrapper>
      <Switch>
        {quizContentData.map(({ path, subtitle, styles, title, id, value }, index) => (
          <Route path={`/quiz/steps/question/${value}`} key={index}>
            {getQuestionContent(title, subtitle, styles, id, value)}
          </Route>
        ))}
      </Switch>
    </QuestionWrapper>
  );
};
