import { put, select } from 'redux-saga/effects';
import axios from 'axios';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { transformHeight } from "@Utilities//conversion";

export function* worker() {
  const rootUrl = process.env.REACT_APP_ROOT_HOST;
  const resultsUrl = `${rootUrl}/api/onboard/result`;
  const state = yield select();
  const quiz = state.quiz;
  const gender = state.quiz['gender'];
  const currentWeight = quiz['your-weight'];
  const currentHeight = quiz['your-height'].height;
  const currentHeightInch = quiz['your-height'].heightInch;
  const currentHeightMeasure = quiz['your-height'].measure;
  const desiredWeight = quiz['your-desired-weight'];
  const age = 80 < quiz['birthDate']?.birthDate ? 80 : quiz['birthDate']?.birthDate;
  const workout = quiz['tailoring-plan'];
  const genderLowerCase = 'male' === gender ? 'male' : 'female';

  const { height, heightInches } = transformHeight(quiz['measure'], currentHeightMeasure, currentHeight, currentHeightInch);
  try {
    const response = yield axios.post(resultsUrl, {
      gender: genderLowerCase,
      desired_weight: Math.floor(Number(desiredWeight.weight)),
      desired_weight_measure: desiredWeight.measure,
      year: age,
      current_weight: Math.floor(Number(currentWeight.weight)),
      current_weight_measure: currentWeight.measure,
      height:  Math.floor(Number(height)),
      height_inch: Math.floor(Number(heightInches)),
      workout: workout,
    });
    yield put(
      updateQuiz({
        name: 'personalSummary',
        value: response.data.data,
      })
    );
  } catch (error) {
    yield put(
      updateQuiz({
        name: 'personalSummary',
        value: null,
      })
    );
  }
}
