import { useEffect, useState } from 'react';

export const useIsScrolledToElement = (fromElementPositionValue) => {
  const [isScrollToElement, setIsScrollToElement] = useState(false);
  useEffect(() => {
    const onScroll = (e) => {
      if (e.target.documentElement.scrollTop >= fromElementPositionValue) {
        setIsScrollToElement(true);
      } else {
        setIsScrollToElement(false);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return isScrollToElement;
};
