import { useForm } from 'react-hook-form';
import { Button } from '@Components/Button';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { checkOnExistingEmail } from '@Middleware/components/whatsYourEmail/actions';
import { InputComponent } from '@Components/InputComponent';
import { getEmailMessageError } from './inputValidate';
import { useEffect, useState } from 'react';
import { EmailPolicyWrapper, TermsOrUseLink } from './styled';
import { CardInputIcon } from '../Form/styled';
import { Lock } from '@Components/Icons';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { useHistory } from 'react-router-dom';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';
import config from '@/config';
import { setEmailData } from  '@Store/components/buyNow/actions';

export const WhatsYourEmail = ({ setPaymentPlanInfo, setEmail }) => {
  const { appVersion: isV2, quiz } = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const [responseParams, setResponseParams] = useState({
    isFetching: false,
    error: '',
  });

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: {
      email: quiz['your-email']?.email,
    },
    mode: 'onChange',
  });

  const isDisabled = !isValid || responseParams.isFetching;
  const prices = isV2 ? config.prices.test : config.prices.master;
  const emailRules = getEmailMessageError();
  const onSubmit = (data) => {
    const payload = {
      data,
      setPaymentPlanInfo,
      setEmail,
      setResponseParams,
      history,
      prices,
    };
    dispatch(checkOnExistingEmail(payload));
    dispatch(setEmailData(data.email));
  };

  const getEmailPolicyWrapperContent = () => {
    return (
      <EmailPolicyWrapper>
        By confirming the email address you agree with out{' '}
        <TermsOrUseLink to="/terms-or-use">
          <span>Terms & Conditions</span>
        </TermsOrUseLink>{' '}
        and{' '}
        <TermsOrUseLink to="/privacy-policy">
          <span>Privacy Policy</span>
        </TermsOrUseLink>{' '}
        <span>from FastDzen</span>
      </EmailPolicyWrapper>
    );
  };

  useEffect(() => {
    sendAmplitudeData('F_email_view');
    scrollPageToTop();
  }, []);

  useEffect(() => {
    if (responseParams.error) {
      setError('email', { type: 'manual', message: responseParams.error }, { shouldFocus: true });
    }
  }, [responseParams]);

  const onChange = (field, value) => {
    field.onChange(value);
  };

  return (
    <div className="whats-your-email">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputComponent
          name="email"
          control={control}
          errorMessage={errors['email']}
          label="Your email"
          type="text"
          placeholder="example@gmail.com"
          rules={emailRules}
          padding="0 45px 0 16px"
          onChange={onChange}
        />
        <CardInputIcon>
          <Lock />
        </CardInputIcon>
        <Button name="See my plan" minWidth="100%" margin="30px 0 0" type="submit" isDisabled={isDisabled} isLoading={responseParams.isFetching} />
        {getEmailPolicyWrapperContent()}
      </form>
    </div>
  );
};
