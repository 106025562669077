import { BMI } from './components/BMI';
import { Title } from '@QuizComponents/Title';
import { Water } from './components/Water';
import { Calories } from './components/Calories';
import { GetExcited } from './components/GetExcited';

export const PersonalSummary = () => {
  return (
    <div style={{ marginBottom: '14px' }}>
      <Title title="Personal summary" styleProps={{ margin: 0, padding: 0, textAlign: 'left' }} />
      <BMI />
      <Water />
      <Calories />
      <GetExcited />
    </div>
  );
};
