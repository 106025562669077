import styled from '@emotion/styled';

export const CheckBoxContentCheck = styled.div`
  display: flex;
  align-self: flex-start;
  flex: 1 1 40%;
  max-width: 340px;
  margin-bottom: 8px;
  align-items: center;
`;

export const CheckBoxContentCheckIcon = styled.div`
  margin-right: 4px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  padding: 6px;
  background-color: #edf4e4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckBoxContentCheckName = styled.span`
  font-size: 13px;
  line-height: 18px;
`;

export const CheckBoxContentDescription = styled.p`
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 18px;
  color: #3f5366;
  &:last-child {
    margin-bottom: 0;
  }
`;
