export const upSellData = [
  {
    title: 'Easy & healthy guide',
    subTitle: {
      src: '/images/upSell/subTitle1.png',
      text: 'Make your fasting easier with Lasta\'s 7-day guide'
    },
    whatYouGetSection: {
      title: 'What you get:',
      content: ['Only 15-min prep', 'Meal calorie breakdown', 'Easy-to-find ingredients']
    },
    whatYouGetSection2: {
      title: 'What it will do to your body:',
      content: ['Reduce body fat to normal levels', 'Avoid gastritis and peptic ulcers', 'Normalize synthesis of hormones']
    },
    id: 1,
    checkIcon: '/images/upSell/checkSmall.svg',
    upSellImg: '/images/upSell/upSell1.png',
    buttonText: 'Get it right away',
    background: '#F8F4E2',
    padding: '12px 16px 12px 12px'
  },
  {
    title: 'Walking challenge',
    subTitle: {
      src: '/images/upSell/subTitle2.png',
      text: '89% of users reached their goals 3 times faster with this challenge'
    },
    whatYouGetSection: {
      title: 'What you get:',
      content: ['Task quests for more fun', 'Dynamic step tracking tool', 'Routing guide']
    },
    whatYouGetSection2: {
      title: 'What it will do to your body:',
      content: ['Stress and tension reduction', 'Stabilized cholesterol levels', 'Balanced blood pressure']
    },
    id: 2,
    checkIcon: '/images/upSell/checkSmall.svg',
    upSellImg: '/images/upSell/upSell2.png',
    buttonText: 'Join challenge now',
    background: '#EDF4E4',
    padding: '12px 16px 12px 12px'
  },
  {
    title: 'Veggie snack guide',
    subTitle: {
      src: '/images/upSell/subTitle3.png',
      text: 'Replace just one meal with veggie snack and see result in a week.'
    },
    whatYouGetSection: {
      title: 'What you get:',
      content: ['2-3 Ingredients recipe', 'Max of 10 min time spend', 'No sugar added']
    },
    whatYouGetSection2: {
      title: 'What it will do to your body:',
      content: ['Blood pressure reduction', 'Reduce the risk of heart disease', 'Improve your skin\'s quality']
    },
    id: 3,
    checkIcon: '/images/upSell/checkSmall.svg',
    upSellImg: '/images/upSell/upSell3.png',
    buttonText: 'Get limited offer',
    background: '#FFF2EF',
    padding: '12px 16px 12px 12px'
  },
  {
    title: 'Flat belly rules',
    subTitle: {
      src: '/images/upSell/subTitle4.png',
      title: 'Dreaming of a flat belly?',
      text: 'Start your flat-belly transformation right now!'
    },
    whatYouGetSection: {
      title: 'What you get:',
      content: ['Easy activity tips', 'Stress-free household exercises', 'Tricky eating techniques']
    },
    whatYouGetSection2: {
      title: 'It will let you:',
      content: ['Love yourself in the mirror', 'Boost your libido', 'Increase energy by 78%']
    },
    id: 4,
    checkIcon: '/images/upSell/checkSmall.svg',
    upSellImg: '/images/upSell/upSell4.png',
    buttonText: 'Get useful tips',
    background: '#F8F4E2',
    padding: '0'
  }
];
