import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const EventDayWrapper = styled.div``;

export const IconWrapper = styled.p`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  line-height: 22px;
  color: #878787;
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  text-decoration: none;
  z-index: 9999;
`;
