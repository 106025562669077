import { Title } from '@QuizComponents/Title';

import './styles.scss';
import { Graph } from '@Components/Icons';
import { Button } from '@Components/Button';
import { NavLink } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/QuizV2';

export const DietVsFastingV2 = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step1',
        currentStep: 8,
        totalSteps: 8,
      })
    );

    dispatch(
      updateQuizProgressBar({
        stepName: 'step2',
        currentStep: 0,
        totalSteps: 5,
      })
    );
  }, []);

  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    setCurrentStepName('step1');
  }, []);

  return (
    <div className="diet-vs-fasting">
      <Title title="Any diet vs Fasting" />
      <p className="diet-vs-fasting__subtitle">
        Our solution promotes better health and long-term fat loss through a simple lifestyle personalised just for you.
      </p>
      <Graph style={{ margin: '20px auto 50px', display: 'block' }} />
      <div className="diet-vs-fasting-wrapper">
        <NavLink to="/v2/quiz/steps/how_well_you_sleep">
          <Button name="Ok, got it!" margin="auto" />
        </NavLink>
      </div>
    </div>
  );
};
