import { Route, useLocation } from 'react-router-dom';
import './styles.scss';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Article } from './conponents/Article';

export const Post = () => {
  const location = useLocation();

  const [post, setPost] = useState(null);
  const getPost = async (postId) => {
    const url = '/data/articles.json';
    try {
      const response = await axios.get(url);
      const { data } = response;
      data.posts.forEach(({ id, text }) => {
        if (postId === id) {
          setPost(text);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const postId = Number(location.pathname.split('/')[2]);
    getPost(postId);
  }, [location.pathname]);

  return (
    <div className="post">
      <Route exact path={`/post/:id`}>
        <div className="post-container">
          <Article data={post} />
        </div>
      </Route>
    </div>
  );
};
