import { TitleBlock } from './styled';

export const Title = ({ title, ...props }) => {
  return (
    <>
      <TitleBlock {...props}>
        {title}
      </TitleBlock>
    </>
  );
};
