import axios from 'axios';
import Cookies from 'js-cookie';

export const facebookApi = (
  eventName,
  sourceUrl,
  timestamp,
  price,
  id
) => {
  const rootUrl = process.env.REACT_APP_ROOT_HOST;
  return axios.post(`${rootUrl}/api/v3/conversion/layout/event-push`, {
    service: '1',
    data: {
      eventName: eventName,
      sourceUrl: sourceUrl,
      funnelType: 'fasting',
      timestamp: timestamp,
      currency: 'USD',
      price: price,
      fbp: id
    }
  }).then((res) => {
    return res;
  }).catch(error => {
    return error.response;
  });
};

const getFbClientId = () => {
  try {
    const facebookId = Cookies.get('_fbp');
    if (!facebookId) {
      return null;
    }
    return facebookId;
  } catch (err) {
    return null;
  }
};

export const FBEvent = (event, price = '') => {
  const timestamp = Date.now();
  const sourceUrl = document.location.toString();
  if ('Purchase' === event) {
    window.fbq('track', event, { currency: 'USD', value: price });
  } else {
    window.fbq('track', event);
  }
  const id = getFbClientId();
  facebookApi(
    event,
    sourceUrl,
    timestamp,
    price,
    id
  ).then((res) => {
    return res;
  });
};