import { RadioButton } from '@Components/RadioButton';
import { Title } from '@QuizComponents/Title';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { radioButtonsData } from './radioButtonsData';

export const HowMatchWaterDrink = ({ subStepName, handleRadioButton }) => {
    const quiz = useSelector((store) => store.quiz);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            updateQuizProgressBar({
                stepName: 'step2',
                currentStep: 3,
                totalSteps: 5,
            })
        );
    }, []);

    const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

    useEffect(() => {
        setCurrentStepName('step2');
    }, []);

    return (
      <>
        <Title title='How much water do you consume daily?' />
          {radioButtonsData.map(({ value }) => {
              return (
                <RadioButton
                  key={value}
                  id={value}
                  text={value}
                  name={subStepName}
                  checked={quiz['drinkWater'] === value}
                  onClick={handleRadioButton}
                  value={value}
                />
              );
          })}
      </>
    );
};
