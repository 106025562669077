 const conversionWeight = (weight , measure) => {
  let weightResult = Number(weight.weight);
  if ('metric' === measure && ('metric' !== weight.measure)) {
    weightResult = Number((weight.weight / 2.25).toFixed(0));
  } else if ('imperial' === measure && ('imperial' !== weight.measure)) {
    weightResult = Number((weight.weight * 2.25).toFixed(0));
  }
  return weightResult;
};

const transformHeight = (currentMeasure, heightMeasure, height, heightInches) => {
   if (currentMeasure === heightMeasure) {
     return { height, heightInches };
   } else if ('metric' === currentMeasure) {
     const cm = Math.round(((Number(height) * 12) + Number(heightInches)) * 2.54);
     return { height: cm, heightInches: '' };
   } else {
     const heightNumber = height < 122  ? 122 : height > 240  ? 240 : height;
     const totalInches = Math.round(heightNumber / 2.54);
     const inches = totalInches % 12;
     const feet = (totalInches-inches) / 12;

     return { height: feet, heightInches: inches };
   }
 };

 const convertionToArray = (values) => {
   return Object.entries(values).filter(([key, value]) => value === key).map(([key, value]) => key.toLowerCase());
 };

export { conversionWeight, transformHeight, convertionToArray };