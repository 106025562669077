import styled from '@emotion/styled';

export const TitleBlock = styled.h2`
  font-family: "DM Sans", sans-serif;
  line-height: 28px;
  font-size: 20px;
  font-weight: 700;
  max-width: 288px;
  width: 100%;
  margin: 16px auto 20px;
  padding: 0 7px;
  text-align: center;
  ${(props) => ({...props.styleProps})};
`;