import styled from '@emotion/styled';

export const BMIWrapper = styled.section`
  border: 1px solid #f7ec7f;
  border-radius: 20px;
  padding: 18px;
  background-color: #fff;
  margin-top: 14px;
`;

export const BMISubtitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #999eaf;
  text-align: left;
`;

export const BMIProgress = styled.div`
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, #f7ec7f 0%, #ff727a 100%);
  border-radius: 3px;
  margin-top: 54px;
  position: relative;
`;

export const BMIProgressCircle = styled.div`
  width: 100%;
  height: 100%;
  max-width: 22px;
  min-height: 24px;
  background-color: #fff;
  position: absolute;
  bottom: -8px;
  left: ${(props) => props.dotWeight}%;
  transform: translate(-${(props) => props.dotWeight}%);
  border-radius: 50%;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
`;

export const BMIProgressDot = styled.div`
  background-color: #f9dc7f;
  box-shadow: 0 8px 11px rgba(15, 15, 15, 0.09);
  width: 100%;
  height: 100%;
  max-width: 11px;
  max-height: 11px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const BMIToolTip = styled.div`
  padding: 6px 10px;
  background-color: #fff;
  border-radius: 4px;
  min-width: 56px;
  text-align: center;
  min-height: 26px;
  position: absolute;
  bottom: 30px;
  right: -20px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -5px;
    right: 26px;
    border-top: solid 5px #fff;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }
`;

export const BMIProgressValuesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const BMIProgressValue = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #999eaf;
`;
