import { Title } from '@QuizComponents/Title';
import './styles.scss';
import { Button } from '@Components/Button';
import { useForm } from 'react-hook-form';
import { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';
import { QuizContext } from '@Pages/Quiz/Quiz';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { InputComponent } from '@Components/InputComponent';
import { getMessageError } from './inputValidate';
import { MeasureWrapper } from '../../styled';
import { conversionWeight } from '@Utilities/conversion';

export const YourDesiredWeight = ({ handleInput }) => {
  const history = useHistory();
  const currentWeight = useSelector((store) => store.quiz['your-weight']);
  const dispatch = useDispatch();
  const quiz = useSelector((store) => store.quiz);
  const kg = quiz['your-desired-weight']  && quiz['your-desired-weight'].measure;
  const [formMeasure, setFormMeasure] = useState(kg || 'imperial');
  const setCurrentStepName = useContext(QuizContext).setCurrentStepName;

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step3',
        currentStep: 5,
        totalSteps: 11,
      })
    );

    setCurrentStepName('step3');
  }, []);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({
    defaultValues: {
      weight: quiz['your-desired-weight'] && quiz['your-desired-weight'].weight ? quiz['your-desired-weight'].weight : null,

    },
    mode: 'onChange',
  });

  const onSubmit = (event) => {
    const { weight } = event;
    let result;
    if (weight) {
      result = '0' === weight?.toString()?.split('')[0] ? weight?.split('').slice(1).join('') : weight;
    }
    const weightObject = {
      weight: result,
      measure: formMeasure
    };

    const weightResultDesired = conversionWeight(weightObject, currentWeight.measure);
    const data = {
      name: 'your-desired-weight',
      value: {
        weight: weightResultDesired,
        measure: currentWeight.measure
      },
    };

    sendAmplitudeData('Step_3_5_weight.target', {
      target_weight: weightResultDesired,
    });
    handleInput(data);

    if (isValid) {
      setTimeout(() => {
        history.push('your_height');
      }, 300);
    }
  };

  const onChange = (field, value) => {
    const resultValue = value.replace(/[^0-9]/g, '').substr(0, 3);
    field.onChange(resultValue);
  };
  const getMeasureMarkup = (measure) => {
    return <MeasureWrapper>{measure}</MeasureWrapper>;
  };

  return (
    <div className='your-desired-weight'>
      <Title title={'What\'s your target weight?'} />
      <div className='your-weight'>
        <div
          className={'imperial' === formMeasure ? 'your-weight__item-active' : 'your-weight__item'}
          onClick={() => setFormMeasure('imperial')}
        >
          lbs
        </div>
        <div
          className={'metric' === formMeasure ? 'your-weight__item-active' : 'your-weight__item'}
          onClick={() => setFormMeasure('metric')}
        >
          kg
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '20px' }}>
        <InputComponent
          name='weight'
          control={control}
          errorMessage={errors['weight']}
          label='Desired weight'
          type='text'
          placeholder='Enter your desired weight'
          rules={getMessageError('metric' === formMeasure ? 'weight kg' : 'weight lbs', currentWeight, formMeasure)}
          padding='0 45px 0 16px'
          onChange={onChange}
          icon={getMeasureMarkup('metric' === formMeasure ? 'kg' : 'lbs')}
        />
        <Button name='Continue' minWidth='100%' margin='30px 0 0' type='submit' isDisabled={!isValid} />
      </form>
    </div>
  );
};
