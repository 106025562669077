export const radioButtonsData = [
  {
    id: '1',
    value: 'No cravings',
  },
  {
    id: '2',
    value: 'Sweets and chocolate',
  },
  {
    id: '3',
    value: 'Carbs, bread, pasta',
  },
  {
    id: '4',
    value: 'Fried food',
  }
];
