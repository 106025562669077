import React, { useEffect, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { PopoverArrow, PopoverChildren, PopoverContent, PopoverText } from './styled';
const Popover = ({ children, text }) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [tooltipPlacement, setTooltipPlacement] = useState(undefined);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement: 'bottom',
    offset: [isDesktop ? 0 : -120, 10],
  });

  useEffect(() => {
    if (window.innerWidth >= 960) {
      setIsDesktop(true);
      return;
    }
    setIsDesktop(false);
  }, [window.innerWidth]);

  useEffect(() => {
    const dataPopperPlacement = getTooltipProps()['data-popper-placement'];
    setTooltipPlacement(dataPopperPlacement);
  }, [getTooltipProps()]);

  const initArrowPosition = () => {
    let arrowPosition = {};

    if (tooltipPlacement && tooltipPlacement === 'top') {
      arrowPosition = { bottom: '-7px', transform: 'rotate(180deg)', right: isDesktop ? 'calc(50% - 10px)' : '4px' };
      return arrowPosition;
    }

    arrowPosition = { top: '-7px', right: isDesktop ? 'calc(50% - 10px)' : '4px' };
    return arrowPosition;
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <PopoverChildren ref={setTriggerRef}>{children}</PopoverChildren>
        {visible && (
          <PopoverContent ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
            <PopoverArrow>
              <div style={{ ...initArrowPosition() }} className="arrow" />
            </PopoverArrow>
            <PopoverText>{text}</PopoverText>
          </PopoverContent>
        )}
      </div>
    </>
  );
};

export default Popover;
