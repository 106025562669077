import { Title } from '@QuizComponents/Title';
import './styles.scss';
import Map from '@Assets/maps.png'
import { Button } from '@Components/Button';
import { useHistory } from 'react-router-dom';
import { Header } from '@QuizComponents/Header';
import { useEffect } from 'react';
import { scrollPageToTop } from '@Utilities/scrollPageToTop';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { useSelector } from 'react-redux';

export const GoodHands = () => {
  const history = useHistory();
  const isV2 = useSelector((state) => state.appVersion);

  useEffect(() => {
    scrollPageToTop();
    sendAmplitudeData('Step_4_goodHands_view');
  }, []);

  const handleClick = () => {
    history.push(`${isV2}/quiz/buy_now/restricted_diet_fail`);
  };
  return (
    <div className='good-hands'>
      <Header isShowPreviousBtn={false} isShowNumberQuestion={false} />
      <Title title='You’re in good hands 🙌' style={{ marginBottom: '6px' }} />
      <div className='map'>
        <img src={Map} className='map' alt={'map'} />
      </div>
      <div className={'text'}>
        90,347 women in their 20’s have made strides toward their goals using
        Fasting & Your Meal Plan
      </div>
      <Button name='Continue' margin='24px 0' minWidth='100%' handleClick={handleClick} />
    </div>
  );
};
