import styled from '@emotion/styled';

export const ReviewContentWrapper = styled.div`
  border: 0.3px solid #e8e8e8;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  background-color: #fff;
  padding: 20px 14px;
`;
export const ReviewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ReviewContentLeftSection = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #000;
  display: flex;
  align-items: flex-start;
`;
export const ReviewContentRightSection = styled.div``;
export const ReviewContentRightSectionText = styled.p`
  font-size: 11px;
  line-height: 14px;
  color: #acacac;
  font-weight: 500;
`;
export const ReviewContentTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #000;
  margin-top: -7px;
`;
export const ReviewContentSubTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #444;
  margin-top: 10px;
`;
