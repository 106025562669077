export const REVIEW_CONTENT_DATA = [
  {
    title: 'Very Motivating!',
    date: '21 Sep',
    name: 'Amanda Clark',
    text: 'I decided to lose a few inches off my waist after I could not fit into my old pair of jeans. Fasting has worked wonders for me, and I am rocking those jeans again.',
  },
  {
    title: 'Easy and effective',
    date: '3 July',
    name: 'Sophie Barnes',
    text: 'I never thought I would be able to follow a weight loss plan with ease, but here we are. Fasting has made the process simple to fit my lifestyle.',
  },
  {
    title: "I'm in love with it!",
    date: '29 Sep',
    name: 'Emily Russell',
    text: 'After falling in love with Fasting Plan, I introduced it to my friends, and they cannot stop gushing about it either. We have all experienced noticeable weight loss.',
  },
];
