import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { YourWeightAndHeightV2 } from './components/YourWeightAndHeight';
import { YourDesiredWeightV2 } from './components/YourDesiredWeight';
import './styles.scss';
import { CravingCertainFoodsV2 } from './components/CravingCertainFoods';
import { YourBodyTypeV2 } from './components/YourBodyType';
import { getEventProperty } from '@Utilities/getEventProperty';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { TypicalLunchV2 } from './components/TypicalLunch';
import { LifeStyleV2 } from './components/LifeStyle';
import { MetabolismV2 } from './components/Metabolism';
import { ImportantEventV2 } from './components/ImportantEvent';
import { YourBodyShapeV2 } from './components/YourBodyShape';
import { BirthDateV2 } from './components/BirthDate';
import { HeightV2 } from './components/Height';
import { EventDayV2 } from './components/EventDay';

export const Step3V2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { gender } = useSelector(state => state.quiz) || {};

  const checkAndReturnNextPath = (name) => {
    let path = '';
    let eventUrl = '';

    switch (name) {
      case 'typical-lunch' :
        path = 'metabolism';
        eventUrl = 'typical_lunch';
        break;
      case 'metabolism' :
        eventUrl = 'metabolism';
        path = 'your_weight';
        break;
      case 'lifestyle' :
        eventUrl = 'lifestyle';
        path = 'important_event';
        break;
      case 'craving-certain-foods' :
        eventUrl = 'craving_certain_foods';
        path = 'male' === gender ? 'your_body_type' : 'your_body_shape';
        break;
      case 'your-body-shape' :
        eventUrl = 'your_body_shape';
        path = '/v2/quiz/buy_now/forecast';
        break;
    }
    return { path, eventUrl };
  };

  const sendEventWithProperty = (path, value) => {
    let eventProperty = {};
    switch (path) {
      case 'typical_lunch':
        eventProperty = getEventProperty('Step_3_2_typical_lunch', 'lunch_type', value);
        break;
      case 'metabolism':
        eventProperty = getEventProperty('Step_3_3_Metabolism','How_many_meals', value);
        break;
      case 'lifestyle':
        eventProperty = getEventProperty('Step_3_7_Lifestyle_click', 'LifeStyle', value);
        break;
      case 'craving_certain_foods':
        eventProperty = getEventProperty('Step_3_10_crave', 'food_type', value);
        break;
      case 'your_body_shape':
        eventProperty = getEventProperty('Step_3_11_bType_click', 'Body_shape', value);
        break;
    }

    sendAmplitudeData(eventProperty.eventName, {
      [eventProperty.name]: eventProperty.value,
    });
  };

  const handleRadioButton = (event) => {
    const { name, value } = event.target;

    dispatch(
      updateQuiz({
        name,
        value,
      })
    );

    const path = checkAndReturnNextPath(name);
    sendEventWithProperty(path.eventUrl, value);

    if (path) {
      setTimeout(() => {
        history.push(path.path);
      }, 300);
    }
  };

  const handleInput = (data) => {
    const { name, value } = data;

    dispatch(
      updateQuiz({
        name,
        value,
      })
    );
  };

  return (
    <div className='quiz-step3'>
      <Switch>
        <Route exact path='/v2/quiz/steps/birthDate'>
          <BirthDateV2 subStepName='birthDate' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/v2/quiz/steps/typical_lunch'>
          <TypicalLunchV2 subStepName='typical-lunch' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/v2/quiz/steps/metabolism'>
          <MetabolismV2 subStepName='metabolism' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/v2/quiz/steps/your_weight'>
          <YourWeightAndHeightV2 subStepName='your-weight' handleInput={handleInput} />
        </Route>
        <Route exact path='/v2/quiz/steps/your_desired_weight'>
          <YourDesiredWeightV2 subStepName='your-desired-weight' handleInput={handleInput} />
        </Route>
        <Route exact path='/v2/quiz/steps/your_height'>
          <HeightV2 subStepName='your-height' handleInput={handleInput} />
        </Route>
        <Route exact path='/v2/quiz/steps/lifestyle'>
          <LifeStyleV2 subStepName='lifestyle' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/v2/quiz/steps/important_event'>
          <ImportantEventV2 subStepName='important-event' handleInput={handleInput} />
        </Route>
        <Route exact path='/v2/quiz/steps/event_day'>
          <EventDayV2 subStepName='event-day' handleInput={handleInput} />
        </Route>
        <Route exact path='/v2/quiz/steps/craving_certain_foods'>
          <CravingCertainFoodsV2 subStepName='craving-certain-foods' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/v2/quiz/steps/your_body_type'>
          <YourBodyTypeV2 subStepName='your-body-type' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/v2/quiz/steps/your_body_shape'>
          <YourBodyShapeV2 subStepName='your-body-shape' handleRadioButton={handleRadioButton} />
        </Route>
      </Switch>
    </div>
  );
};
