import { Title } from '@QuizComponents/Title';
import { Header } from '@QuizComponents/Header';
import './styles.scss';
import { BenefitsCheck } from '@Components/Icons';
import { Button } from '@Components/Button';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { updateQuizProgressBar } from '@Store/components/quiz/actions/quizAction';

export const Description = () => {
  const dispatch = useDispatch();
  const isV2 = useSelector((state) => state.appVersion);
  const link = isV2 ? '/v2' : '';

  useEffect(() => {
    dispatch(
      updateQuizProgressBar({
        stepName: 'step2',
        currentStep: 0,
        totalSteps: 5,
      })
    );
  }, []);

  return (
    <div className='description'>
      <Header />
      <Title title='What is intermittent fasting, anyway?' />
      <p className='description-subtitle'>
        Intermittent fasting is a revolutionary approach to weight loss and nutrition. It helps your digestive tract rest, effectively making you feel
        better and preventing health issues down the road.
      </p>

      <p className='description-title'>So how does it work?</p>
      <p className='description-subtitle'>
        The idea is that you have a so-called eating window during which you do not limit your food intake, and periods of fasting when you refrain
        from eating. Time frames vary, the most popular one being eating for 8 hours and fasting for 16.
      </p>

      <p className='description-title'>Fasting also may provide many benefits:</p>

      <ul className='benefits'>
        <li className='benefits-item'>
          <div>
            <BenefitsCheck />
          </div>
          <p className='benefits-item__text'>Fat burning</p>
        </li>
        <li className='benefits-item'>
          <div>
            <BenefitsCheck />
          </div>
          <p className='benefits-item__text'>Lower blood sugar and reduced insulin resistance</p>
        </li>
        <li className='benefits-item'>
          <div>
            <BenefitsCheck />
          </div>
          <p className='benefits-item__text'>Lower levels of cholesterol</p>
        </li>
        <li className='benefits-item'>
          <div>
            <BenefitsCheck />
          </div>
          <p className='benefits-item__text'>And longer life</p>
        </li>
      </ul>

      <NavLink to={`${link}/quiz/steps/your_name`}>
        <Button name='Ok, got it!' margin='24px 0' />
      </NavLink>
    </div>
  );
};
