import { Route, Switch, useHistory } from 'react-router-dom';
import { HowManyMealsPerDayV2 } from './components/HowManyMealsPerDay';
import { YourEatingStyleV2 } from './components/YourEatingStyle';
import { DietVsFastingV2 } from './components/DietVsFasting';
import { HighCarbV2 } from './components/HighCarb';
import { useDispatch } from 'react-redux';
import { updateQuiz } from '@Store/components/quiz/actions/quizAction';
import { sendAmplitudeData } from '@Analytics/amplitude';
import { getEventProperty } from '@Utilities/getEventProperty';
import { YourNameV2 } from './components/YourName';
import { AllergiesV2} from './components/Allergies';
import { CookingV2 } from './components/SpendCooking';
import { CaloriesV2 } from './components/Calories';

export const Step1V2 = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const sendEventWithProperty = (path, value) => {
    let eventProperty = {};

    if('your_name' === path) {
      eventProperty = getEventProperty('Step_1_1_Name', 'User_name', value);
    } else if ('high_carb' === path) {
      eventProperty = getEventProperty('Step_1_2_high_carb', 'high_carb', value);
    } else if ('your_eating_style' === path) {
      eventProperty = getEventProperty('Step_1_3_diet', 'Food.Diets', value);
    } else if ('how_many_meals_per_day' === path) {
      eventProperty = getEventProperty('Step_1_5_Meals_per_day', 'Meals_per_day', value)
    } else if ('diet_vs_fasting' === path) {
      eventProperty = getEventProperty('Step_1_8_restrict_diet', 'diet_type');
    } else if ('spend_cooking' === path) {
      eventProperty = getEventProperty('Step_1_6_Cooking_time', 'Cooking_time', value);
    } else if ('calories' === path) {
      eventProperty = getEventProperty('Step_1_7_Calories_per_day', 'Calories', value);
    }

    sendAmplitudeData(eventProperty.eventName, {
      [eventProperty.name]: eventProperty.value,
    });
  };

  const checkAndReturnNextPath = (name) => {
    let path = '';
    let eventUrl = '';
    if ('your-name' === name) {
      eventUrl = 'your_name';
    } else if ('high-carb' === name) {
      eventUrl = 'high_carb';
      path = 'your_eating_style';
    } else if ('diet' === name) {
      eventUrl = 'your_eating_style';
      path = 'allergies';
    } else if ('allergies' === name) {
      eventUrl = 'allergies';
    } else if ('how-many-meals-per-day' === name) {
      eventUrl = 'how_many_meals_per_day';
      path = 'spend_cooking';
    } else if ('timeForCooking' === name) {
      eventUrl = 'spend_cooking';
      path = 'calories';
    }
    return { path, eventUrl };
  };
  const handleRadioButton = (event) => {
    const { name, value } = event.target;

    dispatch(
      updateQuiz({
        name,
        value,
      })
    );

    const path = checkAndReturnNextPath(name);
    sendEventWithProperty(path.eventUrl, value);

    if (path) {
      setTimeout(() => {
        history.push(path.path);
      }, 300);
    }
  };

  const handleInput = (data) => {
    const { name, value } = data;
    const path = checkAndReturnNextPath(name);
    sendEventWithProperty(path.eventUrl, value);
  };

  return (
    <div className='quiz-step1'>
      <Switch>
        <Route exact path='/v2/quiz/steps/your_name'>
          <YourNameV2 subStepName='your-name' handleInput={handleInput} />
        </Route>
        <Route exact path='/v2/quiz/steps/high_carb'>
          <HighCarbV2 subStepName='high-carb' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/v2/quiz/steps/your_eating_style'>
          <YourEatingStyleV2 subStepName='diet' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/v2/quiz/steps/allergies'>
          <AllergiesV2 subStepName='allergies' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/v2/quiz/steps/how_many_meals_per_day'>
          <HowManyMealsPerDayV2 subStepName='how-many-meals-per-day' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/v2/quiz/steps/spend_cooking'>
          <CookingV2 subStepName='timeForCooking' handleRadioButton={handleRadioButton} />
        </Route>
        <Route exact path='/v2/quiz/steps/calories'>
          <CaloriesV2 subStepName='calories' handleInput={handleInput} />
        </Route>
        <Route exact path='/v2/quiz/steps/diet_vs_fasting'>
          <DietVsFastingV2 subStepName='diet-vs-fasting' />
        </Route>
      </Switch>
    </div>
  );
};
